import { jsx as _jsx } from "react/jsx-runtime";
import { get, includes, isEmpty, map } from 'lodash';
import { WORK_ORDER_STATUS } from './part_inventory_utils';
import { PH_ROLE_ID } from './job_utils';
// WORK_ORDER_LINE_ITEM_TYPES is a sharable constant in both react project as well as napp
// please sync the value at both places if you are changing it at one place
export const WORK_ORDER_LINE_ITEM_TYPES = {
    GENERAL_MAINTENANCE_TASK: 'GENERAL_MAINTENANCE_TASK',
    PREVENTIVE_MAINTENANCE_TASK: 'PREVENTIVE_MAINTENANCE_TASK',
    // SERVICE_TASK: 'SERVICE_TASK',
    SERVICE_REMINDER: 'SERVICE_REMINDER',
    INSPECTION_CHECKLIST: 'INSPECTION_CHECKLIST',
    DRIVER_COMPLAINT: 'DRIVER_COMPLAINT',
    // ISSUE: 'ISSUE',
};
export const WORK_ORDER_LINE_ITEM_TYPE_LABELS = {
    GENERAL_MAINTENANCE_TASK: 'General Maintenance Tasks',
    PREVENTIVE_MAINTENANCE_TASK: 'Preventive Maintenance Tasks',
    SERVICE_TASK: 'Service Task',
    SERVICE_REMINDER: 'Service Reminder',
    INSPECTION_CHECKLIST: 'Inspection Checklist',
    DRIVER_COMPLAINT: 'Driver Complaint',
    ISSUE: 'Issue',
};
export const VEHICLE_DOMAINS = [
    {
        value: 'Accessories',
        label: 'Accessories',
    },
    {
        value: 'Body & Chassis',
        label: 'Body & Chassis',
    },
    {
        value: 'Brake',
        label: 'Brake',
    },
    {
        value: 'Clutch',
        label: 'Clutch',
    },
    {
        value: 'Cooling System',
        label: 'Cooling System',
    },
    {
        value: 'Crown',
        label: 'Crown',
    },
    {
        value: 'Electrical',
        label: 'Electrical',
    },
    {
        value: 'Engine',
        label: 'Engine',
    },
    {
        value: 'Gear',
        label: 'Gear',
    },
    {
        value: 'General',
        label: 'General',
    },
    {
        value: 'Hub',
        label: 'Hub',
    },
    {
        value: 'Hydraulic',
        label: 'Hydraulic',
    },
    {
        value: 'Lubricants',
        label: 'Lubricants',
    },
    {
        value: 'Propeller Shaft',
        label: 'Propeller Shaft',
    },
    {
        value: 'Pump',
        label: 'Pump',
    },
    {
        value: 'Steering',
        label: 'Steering',
    },
    {
        value: 'Suspension',
        label: 'Suspension',
    },
    {
        value: 'Tools',
        label: 'Tools',
    },
    {
        value: 'Tyre & Wheel',
        label: 'Tyre & Wheel',
    },
    {
        value: 'Others',
        label: 'Others',
    },
    {
        value: 'Engineering & Welding works',
        label: 'Engineering & Welding works',
    },
];
export function mappedWorkOrderLineItems() {
    return map(WORK_ORDER_LINE_ITEM_TYPES, (v, k) => {
        return {
            value: v,
            label: get(WORK_ORDER_LINE_ITEM_TYPE_LABELS, v, v),
            isDisabled: !includes([
                WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK,
                WORK_ORDER_LINE_ITEM_TYPES.GENERAL_MAINTENANCE_TASK,
            ], v),
        };
    });
}
export function getWorkOrderLineItemFrom(data, itemType) {
    if (data.itemType === 'SERVICE_TASK') {
        // for backward compatability
        const item = {
            vehicleDomain: '',
            ...data,
            id2: data.id,
            itemType: WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK,
        };
        delete item.id;
        return item;
    }
    switch (itemType) {
        case WORK_ORDER_LINE_ITEM_TYPES.INSPECTION_CHECKLIST:
            return {
                vehicleDomain: '',
                itemType,
                itemId: data.id,
                description: data.description,
            };
        case WORK_ORDER_LINE_ITEM_TYPES.SERVICE_REMINDER:
            return {
                vehicleDomain: '',
                itemType,
                itemId: data.id,
                description: ``,
            };
        case WORK_ORDER_LINE_ITEM_TYPES.DRIVER_COMPLAINT:
            return {
                vehicleDomain: '',
                itemType,
                description: data.description,
                driverComplaintLineItem: true,
            };
        default:
            const item = { vehicleDomain: '', itemType, ...data, id2: data.id };
            delete item.id; // while submitting selected lineItem with id having issues
            return item;
    }
}
export const WORK_ORDER_LINE_ITEM_STATUS = {
    OPEN: 'OPEN',
    RESOLVED: 'RESOLVED',
};
export const WORK_ORDER_LINE_ITEM_STATUS_LABEL = {
    OPEN: 'Open',
    RESOLVED: 'Resolved',
};
export function shouldResolveFlowShown(workOrderStatus) {
    return includes([WORK_ORDER_STATUS.OPEN, WORK_ORDER_STATUS.IN_PROGRESS], workOrderStatus);
}
export function isWorkOrderLineItemResolved(lineItemStatus) {
    return lineItemStatus === WORK_ORDER_LINE_ITEM_STATUS.RESOLVED;
}
export function isLineItemServiceTask(itemType) {
    return includes([WORK_ORDER_LINE_ITEM_TYPES.PREVENTIVE_MAINTENANCE_TASK, WORK_ORDER_LINE_ITEM_TYPES.GENERAL_MAINTENANCE_TASK], itemType);
}
export function noItemFoundRenderer(itemType, foundLineItems, vehicleNumber, isLoading) {
    let text = '';
    if (!vehicleNumber) {
        text = 'Please select a vehicle first.';
    }
    else if (isLoading) {
        text = 'Loading...';
    }
    else if (!isEmpty(foundLineItems)) {
        return null;
    }
    else {
        switch (itemType) {
            case WORK_ORDER_LINE_ITEM_TYPES.SERVICE_REMINDER:
                text = 'No Service Reminder in Overdue or DueSoon.';
                break;
            case WORK_ORDER_LINE_ITEM_TYPES.INSPECTION_CHECKLIST:
                text = 'No Inspection List for selected vehicle.';
                break;
            default:
                text = 'No backlogs for Selected Vehicle';
        }
    }
    return _jsx("div", { className: "text-center text-muted font-italic", children: text });
}
export const WORK_ORDER_TABS = {
    OVERVIEW: 'WORK_ORDER',
    WORK_ORDER_CUSTOM_FIELD: 'WORK_ORDER_CUSTOM_FIELD',
};
export function workOrderFormCustomFieldConfig(values, isOnCustomFieldsWorkOrderDashboard = false) {
    const roleId = get(window.FLEETX_LOGGED_IN_USER, 'role.id', 0);
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    const baseKeyForWorkOrderData = isOnCustomFieldsWorkOrderDashboard ? 'workOrder.' : '';
    const getWorkOrderData = (key) => {
        return get(values, `${baseKeyForWorkOrderData}${key}`);
    };
    const createdBy = getWorkOrderData('createdBy'); // if null then Work Order are made from SAP
    switch (accountId) {
        case 14008:
            const isUserPH = PH_ROLE_ID === roleId;
            return {
                // 3637: {
                //     name: 'IsSubmitted By PH',
                //     disabled: !isUserPH,
                //     required: false,
                //     id: 3637,
                //     inputByRoleId: PH_ROLE_ID,
                // },
                3638: {
                    name: 'PH Submit ID',
                    disabled: true,
                    required: false,
                    id: 3638,
                },
                3639: {
                    name: 'PH Submit DateTime',
                    disabled: true,
                    required: false,
                    id: 3639,
                },
                3672: {
                    disabled: true,
                },
                3636: {
                    disabled: true,
                },
                3645: {
                    disabled: true,
                },
                3647: {
                    disabled: true,
                },
                3629: {
                    disabled: true,
                },
                3628: {
                    disabled: true,
                },
            };
        default:
            return {};
    }
}
export const priorityColumns = {
    3628: 3,
    3629: 4,
    3630: 5,
    3672: 6,
    3645: 7,
    3646: 8,
    3647: 9,
    3358: 10,
    3631: 11,
    3636: 12,
    3638: 13,
    3639: 14,
};
