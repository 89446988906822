import {
    ACCOUNT_CONFIG,
    ALLOWED_REFERRAL_DATA,
    CONTROLROOM_VIEW_TYPE,
    FLEET_TYPE,
    HIERARCHY_ARRAY_IN_ORDER,
    JOB_TAGS,
    REAL_TIME_TIMER_TRIGGER_SECONDS_MULTI_VEHICLES,
    REPORT_ON_UI_NAMES,
    REPORT_ON_UI_NAMES_BHARATHI,
    REPORT_ON_UI_NAMES_CASTROL,
    REPORT_ON_UI_NAMES_NSL,
    ROLE_MAP,
    SIM_PROVIDER,
    TABLE_CONFIG,
    TEMPLATE_TYPE,
    TIMELINE_TIMER_TRIGGER_SECONDS,
    TRIP_VIEW_TYPE,
    VEHICLE_DOCUMENT_TYPES_OPTIONS,
    VEHICLE_DOCUMENT_TYPES__ADDITIONAL_OPTIONS,
    WIDGET_CONFIG,
} from '../constant';
import {
    forEach,
    get,
    includes,
    intersection,
    isArray,
    isEmpty,
    map,
    round,
    set,
    split,
    toLower,
    toSafeInteger,
} from 'lodash';
import {
    isDeleteTransporterAllowedForUser,
    isManageSimDevices,
    isRoleDataMasterEdit,
    isRoleMaster,
    isRoleOwner,
    isSimTrackingModuleEnabled,
    isUpdateAccountsStatusAllowedForUser,
    isUpdateSimTrackingAllowedForUser,
    isViewConsignmentsAllowedForUser,
    isViewERPConfigurationAllowedForUser,
    isViewIssuesAllowedForUser,
    isViewVideoTelematicsAllowedForUser,
} from '../components/dashboard/roles/permission_utils';
import { isPointInsidePolygon } from './map/geocode_utils';

import { DATE_FORMAT_REPORT, defaultTimeZone, getHumanizeTime, getMomentTime } from './date_utils';
import { generateSuffix } from './string_utils';
import { ALERT_SETTINGS_FIELDS } from 'utils/alarm_utils';
import { VEHICLE_JOB_STATE } from './job_route_utils';
import { getInitialWidgetConfig, settings_metadata } from './initial_account_config';
import { NSL_CONFIG, VIJAY_ROADLINES_CONFIG } from '../components/dashboard/route_dispatch/jobs/manual_job_configs';
import {
    defaultIncludes,
    defaultIncludesMangalamCement,
    mangalamColVehicleSummaryMapping,
    smartGreenColVehicleSummaryMapping,
} from '../components/dashboard/reports/vehicle_report_config';
import { kmColAlarmReportMapping } from './reports/alarm_report_utils';
import { smartGreenColHistoryReportMapping } from './reports/trip_report_utils';
import { STATUS, VENDOR_MAERSK_STATUS_LABEL } from './vendor_utils';
import { COUNTRIES, countryToDetailsMap } from './internationalization_utils';
import { ALL_WHITE_LABEL_URLS, isShellWhiteLabelUrl } from '../../white_label_constants';
import { omJayFacilityRemoveColumnsMapping } from './reports/job_report_utils';
import {
    isJindalCustomJobReportEnabledFlag,
    isOldTripUpdateFalseByDefaultInEditJobFlag,
} from 'utils/accountUtils/jobs';
import {
    mappedDocumentType,
    mappedAdditionalVehicleDocumentTypes,
    mappedVehicleDocumentTypes,
} from 'components/utils/mapping_utils';
import { showOBDLinkedModuleDataForAccountEnabled } from 'utils/accountUtils/devices';
import { showIot } from 'components/dashboard/settings/alerts/alert_utils';
import { isOrientCementAccount } from './accountUtils/common';

export const ACCOUNT_SUPERVISOR_TYPES = {
    ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
    DATA_MANAGER: 'DATA_MANAGER',
    REPAIR_MANAGER: 'REPAIR_MANAGER',
    CUSTOMER_EXCELLENCE: 'CUSTOMER_EXCELLENCE',
    FINANCE: 'FINANCE',
    SUPERVISOR: 'SUPERVISOR',
    IMPLEMENTATION: 'IMPLEMENTATION',
};

export const ACCOUNT_SUPERVISOR_TYPES_LABEL = {
    ACCOUNT_MANAGER: 'Account Manager',
    DATA_MANAGER: 'Data Manager',
    REPAIR_MANAGER: 'Repair Manager',
    CUSTOMER_EXCELLENCE: 'Customer Excellence',
    FINANCE: 'Finance',
    SUPERVISOR: 'Supervisor',
    IMPLEMENTATION: 'Implementation',
};

export const PO_AMOUNT_TYPES = {
    OBD: 'OBD',
    NON_OBD: 'NON_OBD',
    API: 'API',
    PORT: 'PORT',
    AIS: 'AIS',
    TEMP_DEVICE: 'TEMP_DEVICE',
    SIM_TRACKING: 'SIM_TRACKING',
    APP_TRACKING: 'APP_TRACKING',
    PORTABLE_DEVICE: 'PORTABLE_DEVICE',
    FUEL_SENSOR: 'FUEL_SENSOR',
    PORTABLE_LOCK: 'PORTABLE_LOCK',
    GPS_WELDED_LOCK: 'GPS_WELDED_LOCK',
    DOOR_SENSOR: 'DOOR_SENSOR',
    TEMP_SENSOR: 'TEMP_SENSOR',
    ANGLE_SENSOR: 'ANGLE_SENSOR',
    ERP_LICENSE_FEES: 'ERP_LICENSE_FEES',
    DASH_CAM: 'DASH_CAM',
    FXAPI: 'FXAPI',
};
export const PO_AMOUNT_TYPES_LABEL = {
    OBD: 'OBD',
    NON_OBD: 'NON OBD',
    API: 'API',
    PORT: 'PORT',
    AIS: 'AIS',
    TEMP_DEVICE: 'TEMPERATURE DEVICE',
    SIM_TRACKING: 'SIM TRACKING',
    APP_TRACKING: 'APP TRACKING',
    PORTABLE_DEVICE: 'PORTABLE DEVICE',
    FUEL_SENSOR: 'FUEL SENSOR',
    PORTABLE_LOCK: 'PORTABLE LOCK',
    GPS_WELDED_LOCK: 'GPS WELDED LOCK',
    DOOR_SENSOR: 'DOOR SENSOR',
    TEMP_SENSOR: 'TEMP SENSOR',
    ANGLE_SENSOR: 'ANGLE SENSOR',
    ERP_LICENSE_FEES: 'ERP LICENSE FEES',
    DASH_CAM: 'DASH CAM',
    FXAPI: 'FXAPI',
};

export const PO_AMOUNT_FIELDS = {
    oneTimeAmount: 'oneTimeAmount',
    dailyAmount: 'dailyAmount',
    firstYearAmount: 'firstYearAmount',
    secondYearAmount: 'secondYearAmount',
    perPingAmount: 'perPingAmount',
};
export const PO_AMOUNT_FIELDS_LABEL = {
    oneTimeAmount: 'One Time Amount',
    dailyAmount: 'Daily Amount',
    firstYearAmount: 'First Year Amount',
    secondYearAmount: 'Second Year Amount',
    perPingAmount: 'Per Ping Amount',
};

export const AMOUNT_ATTRIBUTED_TO = [
    {
        value: 'DIRECT_SALES',
        label: 'Direct Sales',
    },
    {
        value: 'INSIDE_SALES',
        label: 'Inside Sales',
    },
    {
        value: 'CUSTOMER_SUCCESS',
        label: 'Customer Success',
    },
    {
        value: 'ENTERPRISE_SALES',
        label: 'Enterprise Sales',
    },
    {
        value: 'CHANNEL_SALES',
        label: 'Channel Sales',
    },
    {
        value: 'INTERNATIONAL SALES',
        label: 'International Sales',
    },
];

export const BILLING_TYPES = [
    {
        value: 'Vehicle',
        label: 'Vehicle',
    },
    {
        value: 'Trip',
        label: 'Trip',
    },
    {
        value: 'Sim',
        label: 'Sim',
    },
    {
        value: 'Mixed',
        label: 'Mixed',
    },
];
export const TRAINING_CHANNEL = [
    {
        value: 'online',
        label: 'Online',
    },
    {
        value: 'offline',
        label: 'Offline',
    },
];

export const ACCOUNT_HANDOVER_FIELDS = [
    'ownedFleet',
    'marketFleet',
    'implementationFleet',
    'implementationManager',
    'accountManager',
    'trainingChannel',
    'firstInstallationTimeline',
    'dataUpdateTimeline',
    'customisationRequired',
    'apiIntegration',
    'slaInstallation',
    'slaRepair',
    'slaCustomisation',
    'slaReport',
    'ast',
    'ast1',
    'ast2',
    'anyOtherRequirement',
];

export const BRAHAMAPUTRA_NORTH_REGION_COORDINATES = [
    {
        lat: 33.04219420839417,
        lon: 74.98991584208989,
    },
    {
        lat: 33.2261914739227,
        lon: 77.84636115458989,
    },
    {
        lat: 32.15366585316899,
        lon: 78.11003302958989,
    },
    {
        lat: 30.917685141416666,
        lon: 78.94499396708989,
    },
    {
        lat: 30.464206898876157,
        lon: 79.91179084208989,
    },
    {
        lat: 29.589127987840207,
        lon: 79.86784552958989,
    },
    {
        lat: 28.821962131933184,
        lon: 79.82390021708989,
    },
    {
        lat: 28.20412500407447,
        lon: 80.74675177958989,
    },
    {
        lat: 27.582694207963474,
        lon: 82.19694709208989,
    },
    {
        lat: 26.99688582246358,
        lon: 83.86686896708989,
    },
    {
        lat: 26.604639990436354,
        lon: 84.92155646708989,
    },
    {
        lat: 26.132164751123366,
        lon: 85.88835334208989,
    },
    {
        lat: 25.101917778345637,
        lon: 84.78972052958989,
    },
    {
        lat: 23.862130309852557,
        lon: 83.82292365458989,
    },
    {
        lat: 23.096308117872127,
        lon: 81.58171271708989,
    },
    {
        lat: 23.29827051840925,
        lon: 76.48405646708989,
    },
    {
        lat: 23.29827051840925,
        lon: 72.83659552958989,
    },
    {
        lat: 24.42354614064219,
        lon: 71.60612677958989,
    },
    {
        lat: 25.618151620561054,
        lon: 70.99089240458989,
    },
    {
        lat: 26.761701048326742,
        lon: 70.28776740458989,
    },
    {
        lat: 27.348741843504463,
        lon: 70.28776740458989,
    },
    {
        lat: 27.699483824140927,
        lon: 71.91374396708989,
    },
    {
        lat: 28.552103869083155,
        lon: 73.14421271708989,
    },
    {
        lat: 29.474419879334995,
        lon: 73.75944709208989,
    },
    {
        lat: 30.388420476645383,
        lon: 74.50651740458989,
    },
    {
        lat: 31.518991739110227,
        lon: 75.12175177958989,
    },
    {
        lat: 32.302364852240984,
        lon: 75.64909552958989,
    },
];

export const rmcTripStatusNames = { 1528: 'To Job', 1529: 'On Job', 1530: 'To Plant', 1531: 'In Yard' };

export const getWidgetConfig = (accountId, showPlantWidgets) => {
    return getWidgetList(accountId, showPlantWidgets);
};

export const EXPRESS_ROADWAYS_SYSTEM_TAGS = {
    MAINTENANCE: 791,
    WITHOUT_DRIVER: 799,
    ACCIDENT: 800,
    EXPRESS_HUB: 4359,
    ON_ROUTE_MAINTENANCE: 4360,
    ASSIGNED: 17404,
    SOLD: 20263,
    HOLD_FOR_SALE: 20181,
    ATN_HUB: 20871,
    MEDCHAL_HUB: 20872,
    BILASPUR_HUB: 20874,
    TALOJA_HUB: 20873,
    Dhulagarh_HUB: 21685,
};

export const FLEETX_ACCOUNTS = {
    FLEETX: 113,
    FLEETX_OPERATIONS: 1180,
    HARYANA: 114,
    BAJAJ: 129,
    DELHI_CABZ: 132,
    DENEB: 134,
    GANGA_TOURIST: 140,
    ORANGE_CAB: 141,
    IP_TRAVEL: 143,
    RAO_TOURIST: 144,
    MEHTA_TRAVELS: 154,
    AVINASH: 172,
    VICEROY: 187,
    OBEROI_SUKHVILLAS: 196,
    AJAY_CAR_RENTAL: 195,
    NSL: 204,
    BFC: 208,
    HMC: 211,
    PCL: 15150,
    SOMDUTT: 213,
    NARAYANA: 215,
    DHILLON: 223,
    DBSCHENKER: 6938,
    RK_TRAVEL: 230,
    SHAHI: 231,
    RCM: 242,
    FAST_SOLUTION: 244,
    SHIVANI: 257,
    PRANNA: 8435,
    RAI_TRAINS: 269,
    OM_LOGISTICS: 274,
    SPEEDWING: 276,
    ICM_LOGISTICS: 277,
    RSAI: 280,
    RCI: 287,
    DTDC: 295,
    SAINT_GOBAIN: 300,
    BAFNA_TRANSLINES: 314,
    OM_LOGISTICS_TELECOM: 315,
    JUPITER: 326,
    DEV_TRANSPORT: 335,
    VISHWAA: 339,
    NRV: 343,
    CAREWAY_CARGO: 345,
    SPEED_CARGO: 346,
    GIRISH_TRANSPORT: 361,
    FREIGHT_CO: 385,
    CARAVAN: 389,
    RUDRA: 394,
    NIPUN_TEMPO: 402,
    IPL: 406,
    SAINT_GOBAIN_2: 407,
    TIGER: 429,
    BEST_ROADWAYS: 430,
    SK_LOGISTICS: 432,
    OM_TRANSPORT_SERVICE: 434,
    MRSHAH: 439,
    SMART_GREEN: 494,
    OM_LOGISTICS_2: 458,
    ADANI: 514,
    TRANS_CORP: 532,
    BRAHAMPUTRA: 564,
    SRI_VINDHWASNI: 599,
    ASHOK_ROADLINES: 641,
    HARGUN: 648,
    MARUTI_TRAINS: 667,
    ADITYA_CEMENT: 681,
    RAVRAY: 713,
    KPTRANSPORT: 727,
    BVC: 771,
    GUJARAT_LOGISTICS: 784,
    MP_BIRLA: 788,
    QUICK_DIGITAL: 789,
    EXPRESS_ROADWAYS: 813,
    SAJAL_MAJUMDAR: 866,
    SHREE_SHYAM: 870,
    CLASSIC_TRANS: 872,
    KVTC: 877,
    SHIV_KRIPA: 898,
    EVEREST: 909,
    BIRLA_DURGAPUR: 914,
    BLR_LOGISTICS: 936,
    SUMIT_ROADLINES: 955,
    HARGUN_SWIFT: 977,
    NECC: 983,
    VARUN_BEVERAGES: 995,
    AGARWAL_BUILDERS: 1010,
    ENERSHELL: 1026,
    EAST_INDIA_TRANSPORT: 1038,
    IRFAN_ENTPR: 1076,
    GOLDEN_ROADWAYS: 1081,
    TIRANGA: 1092,
    RISHABH_ENTERPRISE: 1094,
    POOJA_TOUR: 1097,
    SUZUZI_MOTORS_GUJARAT: 1109,
    RAISHA_TRANSPORT: 1115,
    SUPER_SPEED: 1125,
    ARYAN_CABS: 1142,
    TVS_SUPPLY_CHAIN_SOLUTIONS: 1144,
    HAULWAYS: 1147,
    NUTAN_RAJUMANI: 1149,
    CASBY_LOGISTIC: 1150,
    TRANSIN_AMBUJA: 1156,
    TECHNICIAN: 1180,
    MFS_BHORER: 1187,
    GODARA: 1195,
    RUSHABH: 1197,
    SRV_LOGISTICS: 1200,
    ULTRATECH_RAJASHREE: 1209,
    SAI_GANESH_TRAVELS: 1245,
    VARAHI: 1272,
    ACTIVE_CARGO: 1282,
    SAHARA_LORRY: 1287,
    DATAR_TRAILER: 1295,
    ANS_INFRACON: 1301,
    JYOTI_STRIPS: 1306,
    BNR_ROADLINES: 1312,
    LOAD_SHARE: 1346,
    NSB_TRANSPORT: 1371,
    GUJARAT_CEMENT_WORKS: 1375,
    ABR_ROADLINES: 1393,
    ABR_LOGISTICS: 1083,
    DEMO_ACCOUNT: 1400,
    SINGAL: 1421,
    VIKRAM_CEMENT: 1423,
    CHANDERIA: 1505,
    RMC: 1570,
    DMK_ASSOCIATE: 1571,
    BHARATHI_CEMENT: 1573,
    RP_SINGH_LOGISTICS: 1590,
    AGP: 1601,
    YOLO: 1603,
    MADDI_SRINIVAS: 1608,
    SHREE_CEMENT: 1624,
    DAINIK_BHASKAR: 1628,
    PROWAY: 1632,
    SHUBHAM_CARGO: 1640,
    IRC_1: 1644,
    MANCHUKONDA_PRAKASHAM_INDUSTRIES: 1675,
    BLT_LOGISTICS: 1682,
    CHAKRAVARTHY: 1683,
    SRINIVASA: 1691,
    JK_CEMENT: 1704,
    ASHOK_TRANSPORT: 1723,
    MAMTA_ROADLINES: 1725,
    DHARIWAL: 1769,
    RRC: 1771,
    PDH_LOGISTICS: 1737,
    NAMRADA: 1754,
    PARTHASARATHY: 1802,
    MS_LPG_GROUP: 1832,
    RAMDEO_REFINERY: 1859,
    FLIPKART: 1924,
    AERIS: 1933,
    DELTAWAY: 1947,
    KOTPUTLI: 1954,
    ULTRATECH_ANDHRA: 1957,
    BALA_JI_CEMENT: 1971,
    RAJNEESH_AWASHTI: 1982,
    MS_FREIGHT: 2015,
    BHARTI_CEMENT_BULKER: 2034,
    ADITYA_BIRLA: 2037,
    MAIHAR_CEMENT: 2048,
    BELA_CEMENT: 2049,
    SIDHI_CEMENT: 2050,
    DALLA_CEMENT: 2051,
    AERIS2: 2078,
    MAHALAXMI: 2106,
    START_CEMENT: 2129,
    HGR: 2138,
    INIFARMS: 2163,
    OM_LOGISTICS_PORTABLE: 2164,
    JFK: 2169,
    FENESTA: 2171,
    MADHAV_REDDY: 2184,
    MARUTI_AIS_TEST: 2190,
    VINSUM_1: 2208,
    AB_LOGISTIC: 2216,
    SHAINA: 2226,
    VINSUM_AXPRESS: 2701,
    KM_TRANS: 2219,
    VENDANTA_BALCO: 2228,
    AERIS3: 2248,
    SHIV_SHAKTI: 2249,
    CEAT: 2258,
    AERIS4: 2284,
    CANOPUS_NATURAL_GAS: 2300,
    TAZ_TRANSPORT: 2316,
    BIGBASKET: 2336,
    CTA_LOGISTICS: 2338,
    PRISM_JOHNSON: 2339,
    BOM_GIM_LOGISTIC: 2363,
    SHREE_BAJRANG: 2376,
    AERIS5: 2350,
    SNN: 2392,
    SHAH_COAL: 2398,
    ARYAN_TRANS: 2399,
    CHAKRA_JAWARA: 2433,
    RASHMI: 2443,
    CHANDERIA_MINES: 2448,
    DHAR: 2468,
    KALBURGI: 2483,
    JAY_AMBE_ROADLINES: 2481,
    GODREJ_CHEMICALS: 2526,
    RCCPL: 2608,
    DALMIA: 2624,
    AG_CONSTRUCTION: 2635,
    PT_USI: 2640,
    CAVIN_CARE: 2647,
    LNTECC: 2671,
    VINSUM: 2701,
    SUNTEK_AXPRESS: 2706,
    ALGOR: 2770,
    ADITYA_BIRLA_BAGA: 2818,
    HUL: 2857,
    KAPOOR_DIESELS: 2908,
    SOTC: 2922,
    APS_DIGITAL: 2935,
    MAYURI_BROILER: 2954,
    KALS: 2987,
    PEARL_TRANSPORT: 2987,
    JKLC: 3017,
    WELSPUN: 3037,
    JSW: 4283,
    JSW_1: 4270,
    JSW_2: 8793,
    KPML: 3035,
    BELA_TO_SIDHI: 3047,
    MPATEL: 3070,
    CHANNEL_SALES_PROD: 3167,
    IRC: 3194,
    RAJ_CORPORATION: 3196,
    PCL_TRANSPORT: 3330,
    KUSH: 3498,
    SULT: 3504,
    SUNFLAG: 3528,
    MAERSK: 3535,
    FABHOUSE_BOTSWANA_AFRICA: 3566,
    VIJAY_ROADLINES: 3567,
    SATVA_HITECH: 3586,
    MAHADEV_CARRIER: 3652,
    SHREE_MANOHAR_TRANSPORT: 3222,
    HINO: 3834,
    SINGH_TRANSPORT_3843: 3843,
    KMR_ROADLINES: 3971,
    INFRA_MARKET: 3947,
    SHRI_RAMJANYA: 4046,
    SYNERGY_CROP: 4098,
    FLEETX_CHANNEL_SALES: 4241,
    TECHNOPATH: 4250,
    WARESIX: 4323,
    MANGALAM_CEMENT: 4343,
    TATA_STEEL_BSL: 4736,
    ICICI_LOMBARD: 4578,
    SCOOTSY_LOGISTICS: 4821,
    SAP_INFRALOGISTICS: 4846,
    GRASIM: 4895,
    HELLO_TRACTOR_KENYA: 4936,
    PARTHA_TRANS: 4944,
    GROFERS: 5056,
    SIDDHI: 5067,
    ANAND_ROADLINES: 5094,
    SYNERGY: 5149,
    JYOTI_INDUSTRIES: 5151,
    GRASIM_INBOUND: 5248,
    CAP_FRESH: 5263,
    IGT: 5310,
    RNG: 5352,
    LALITHA_ENTERPISE: 5314,
    GRASIM_INDUSTRY: 5380,
    GRASIM_INDUSTRY_2: 5388,
    SHARJAH_TRANPSORT: 5470,
    ATS_LOGISTICS: 5524,
    PROTOCOL_SYSTEM: 5527,
    RIGHT_CHOICE: 5530,
    MOUNT_MERU_TANZANIA: 5602,
    FABIO_BIKES: 5636,
    RD_ROADLINES: 5661,
    EURIST: 5668,
    TIGER_AUTO: 5670,
    SHREE_RAM_LOGISTICS: 5691,
    DAULAT: 5709,
    E2E_SUPPLY_CHAIN: 5771,
    LOGMAN: 5844,
    VARUNA: 5851,
    CEEKAY: 5905,
    SPEEDFOX: 5952,
    JAI_AMBAY: 5971,
    RAKESH_SHANKAR_TRIPATHI: 5972,
    CHOUBEY_TRANSPORT_SERVICE: 6010,
    XIAOMI: 6034,
    PHONEX: 6066,
    MANGALAM: 6072,
    KALBURGI_CEMENT: 6089,
    ANDHRA_GUJARAT: 6171,
    HOLICIM: 6185,
    TATA_STEEL_LIMITED: 6201,
    ENTERPRISE_DEMO_ACCOUNT: 6215,
    TTC_LOGISTICS: 6249,
    POONA_ICICI: 6334,
    VINAYAK_ICICI: 6333,
    PARIVAHAN_ICICI: 6332,
    LTC_LOGISTICS: 6359,
    JAYASHREE_ICICI: 6365,
    RAJU_ICICI: 6371,
    RAJENDRA_ICICI: 6389,
    ARPL: 6416,
    VIVALA_BEVERAGES: 6497,
    GIA_TANZANIA: 6518,
    VENTURE_SUPPLY: 6525,
    KEDIA: 6536,
    MANVILLE: 6626,
    TOLINS_PURE_GOLD: 6568,
    ERP_TEST_ACCOUNT: 6570,
    ZAMM_IMPORTS: 6656,
    AMRIT_CEMENTS: 6701,
    HLS_ASIA: 6708,
    NAYARA: 6748,
    BD_TRANSPORT: 6860,
    DTC: 6867,
    DCM_SHRIRAM_JHAGADIA: 6866,
    GOOD_WILL_TRANS: 6892,
    SAFERON_KENYA: 6923,
    CREATIVE_PUBLIC: 6926,
    HERO_MOTOCORP: 6973,
    PLENTEOUS_PHARMA: 7168,
    GANPATI_ROAD_CARRIERS: 7271,
    JK_CEMENT2: 7278,
    DIAMOND: 7653,
    HELLA_INFRA: 7961,
    SHREE_CEMENT_8000: 8000,
    DELHI_VAPI: 8267,
    SHOORA: 8407,
    SRI_VIJAY_VIDHALAYA: 8561,
    ATS_UAE: 8688,
    NORTHERN_CARGO_SERVICE: 8753,
    SHELL: 8806,
    HRA_LOGISTICS: 8837,
    SHELL_KHANDOBA: 8842,
    ADANI_REPORT: 8846,
    INTERCITY_TRANSPORT: 8937,
    AMANBUS: 9107,
    JOVITRONIX: 9249,
    ADITYA_BIRLA_PALI: 9232,
    RASHMI_METALICS: 9312,
    BEST_OCEAN: 9561,
    SHREE_MARUTI_COURIER_SERVICES: 6002,
    GFS_ZAMBIA: 9606,
    INTERCITY_CARRIERS: 9689,
    CITY_FRONTIERS: 9694,
    JSW_CEMENT: 9826,
    ATHENA_CARS: 9892,
    MAERSK_BANGLADESH: 9950,
    MAERSK_SRI_LANKA: 9949,
    RAJ_STEEL_AND_TUBES: 3206,
    ASHIRVAD_TRAILER_SERVICE: 9531,
    TRANSWAY_CARGO_LIFTERS: 4925,
    RAJ_TRANSPORT: 10124,
    GGG_LOGISTICS: 10125,
    RKMPowergen: 4658,
    SASHIKALA_ENTERPRISE: 10221,
    KARUNADU_SERVICES: 10224,
    SMRT_LOGISTICS: 10247,
    EAGLE_TRADELINKS: 10248,
    TEJAS_CARGO: 10254,
    NILE_LOGIX: 10257,
    AB_GILMANS: 10284,
    JK_CEMENT_FUEL_SENSOR: 10183,
    ADANI_COAL: 8930,
    SIDDHESHWARA: 10326,
    LAKE_TRANS: 6615,
    LABDHI_ASPHALTECH: 10396,
    JUST_DIAL: 3656,
    TATA_STEEL_KALINGNAGAR: 8526,
    OHM_E_LOGISTICS: 8334,
    AMRIT_BOTTLERS: 9379,
    FLYING_CARGO: 9649,
    DOT_TRUCKERS: 9943,
    NARMIN_COOL_CARRIER: 10336,
    SAI_LOGISTIC: 10550,
    BRTC: 10634,
    SADHI_LOGISTIC: 10859,
    SHIVAM_LOGISTIC: 10860,
    SHREE_KRISHNA_FROZEN: 11007,
    OM_SAI_ROADLINES: 10543,
    GLENMARK_PHARMA: 11027,
    KEI_INDUSTRIES: 10577,
    SURYA_TRAVELS: 10781,
    SHRINATH_TRAVEL_AGENCY: 6761,
    SLC_NEWACO: 10825,
    TEAM_PARTNER: 10836,
    ZHPOPPELTD: 10959,
    KISSAN_CARRIERS: 2072,
    PRAKASH_GROUP: 3263,
    VRC_LOGISTICS: 6580,
    GOLDEN_TEMPLE_LOGISTICS: 1886,
    ZYNGO: 10295,
    ABT_PARCEL: 6070,
    SHRI_BANKEY_BIHARI: 11316,
    OM_JAY_FACILITY: 4375,
    HASTI_PETRO: 10049,
    MAIHAR_RAKE: 10934,
    SHYAM_TRAVELS: 11569,
    ADANI_TUMB: 11626,
    FAMBO_INNOVATION: 11866,
    SATANPLUS_TECHNOLOGY: 11743,
    SOUMYA_TOURS_TRAVELS: 12118,
    SHREEMAN_LOGISTICS: 11423,
    MP_BIRLA_BUTIBORI: 8493,
    SRAVAN_SHIPPING: 5117,
    INDIAN_ROAD_CARRIER: 11780,
    RAJESH_AND_BROTHER: 11661,
    SEAHAWK: 12461,
    JUBILANT_FOODWORKS: 12475,
    INDUS_MOTORS_GREEN: 12033,
    PORTMAN: 11686,
    BISLERI: 11999,
    JET_TRADE: 12547,
    GAE_CARGO: 11251,
    SAI_WASTE_MANAGEMENT: 11963,
    KAIZEN_LOGISTICS: 12608,
    JINDAL_LIMITED: 12205,
    RAWAN_CEMENT_PLANT: 4264,
    PVM_LOGISTICS: 354,
    SANJAY_TOURS: 12781,
    SGRG_INFRA_SOLUTIONS: 12593,
    MAGADH_LOGISTICS: 12302,
    VENNELA_LOGISTIC: 12854,
    RAKESH_LOGISTICS: 12306,
    VSE: 12868,
    WELL_X_TRANSPORT: 12921,
    AUTO_LOGISTICS: 13166,
    KEY_SIM_LOGISTICS: 12712,
    INARA_LOGISTICS: 13050,
    SAHU_MILLS: 8190,
    SHIVAAY: 13213,
    TANISHK: 12571,
    VSH: 13464,
    ROSHAN_LAL_CHEMICALS: 9448,
    RSI: 13258,
    MAHALAKSHMI: 12815,
    UNITED_MOVERS: 13594,
    SHREE_CEMENT_ALL: 13179,
    SCC_SOLUTION: 13610,
    JAIN_TRANSPORT_COMPANY: 13032,
    GUARD_LOGISTICS: 5138,
    RAJLAXMI_BULK_LOGISTICS: 12796,
    SHREE_VISHNU_MOTOR: 13392,
    TRANSWORLD: 13461,
    AMNS: 13518,
    NATIONAL_TRANSPORT: 13748,
    VORA_TRANS_FREIGHT: 13511,
    RIMJHIM: 13239,
    SHAAN_CARGO: 13551,
    SRI_GOPAL: 13845,
    SHREE_CEMNET_RMC: 14008,
    SUPER_INDIA_LOGISTICS: 11719,
    ORIENT_CEMENT: 12925,
    JINDAL_POLYFLIM: 13915,
    MARUTI_SUZUKI: 13172,
    CHOUDHARY_TOURS_AND_TRAVELS: 14459,
    GODREJ_JERSEY: 14143,
    ADANI_GREENS: 12987,
    OLA_ELECTRIC: 14127,
    MR_AND_SONS_LIMITED: 14539,
    SULEMAN_ROADWAYS: 13820,
    GOLDEN_GLOBES: 14454,
    STRIKE: 14617,
    ASHLER: 13026,
    ORIENT_CEMENT_LIMITED: 4425,
    ORIENT_TEST_ACCOUNT: 14645,
    MAHINDRA_LOGISTICS: 14275,
    MAHALAKSHMI_TRANSPORTER: 14738,
    VORA_TRANSPORT_SERVICE: 14739,
    VORA_TRANSOLUTIO: 14740,
    BENNETT_COLEMAN: 14800,
    ASHTECH: 12587,
    MEDALLION_TRANSLINKS: 14469,
    PINNACLE: 14191,
    GK_TRANSPORT_COMPANY: 14960,
    ALERT_TESTING: 13501,
    TRIUMPH_SUPPLY_CHAIN_PRIVATE_LIMITED: 15091,
    ULPH: 13278,
    SPR_ROADWAYS: 15191,
    YADAV_GOLDEN_CARRIER: 15320,
};

export const isGodrejJerseyAccount = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return accountId === FLEETX_ACCOUNTS.GODREJ_JERSEY;
};

export const isSeaHawkAccount = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return accountId === FLEETX_ACCOUNTS.SEAHAWK;
};

export const overrideSidebarStyle = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return accountId >= 10000;
};

export const consignmentDownloadShow = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return accountId <= 14000 || accountId !== FLEETX_ACCOUNTS.TRANSWORLD;
};

export const isShreeCementsAccounts = [
    8000,
    11494,
    11468,
    11524,
    11578,
    11604,
    11680,
    11638,
    11639,
    11601,
    11532,
    11805,
    12426,
    13179,
    6215,
];

export const isOrientAccount = [12925, 4425, 14645];

const hellaInfraDisableColumnsMapping = [
    'includeCurrentLocation',
    'includeCurrentStatus',
    'includePercentJobCompleted',
    'includePredictedDelay',
    'includeFreightRate',
    'includeVendor',
    'includeTransporter',
    'includeTat',
    'includeNotes',
    'includeJobNotes',
    'includeWeight',
    'includePackageCount',
    'includeVehicleLoad',
    'includeFreightFare',
    'includeTags',
    'includeDeviationCount',
    'includeRFIDStatus',
    'includeJobAlarm',
    'includeJobUsers',
    'includeBillNumbers',
    'includeCustomFields',
    'includeVolume',
    'includeUnloadedWeight',
];

export const getAddressBookLocation = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
            return {
                lat: '23.56214735083682',
                lng: '87.23382112680356',
                id: '19308',
            };
        case FLEETX_ACCOUNTS.CHANDERIA:
            return {
                lat: '24.932041553562758',
                lng: '74.63175689990337',
                id: '29071',
            };
        default:
            return false;
    }
};

export const RMC_CUSTOM_FIELDS_LIST_ON_MARKER = [
    { keyName: 'Ticket Issue Time', label: 'Ticket Assigned Date' },
    { keyName: 'Customer Name', label: 'Customer Name' },
    { keyName: 'TM Registration Number', label: 'TM Registration Code' },
    { keyName: 'Job Plant Code', label: 'Job Plant Code' },
];

export const isRiskReportEnabled = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isRiskReportEnabled') || isSwitchedUser;

    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //     case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT:
    //         return true;
    //     default:
    //         return isSwitchedUser;
    // }
};
export const isJobNotCreatedReportEnabled = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isJobNotCreatedReportEnabled') || isSwitchedUser;
};
export const isJobAlarmsReportEnabled = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isJobAlarmsReportEnabled') || isSwitchedUser;
};

export const isSAPJobSummaryReportEnabled = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isSAPJobSummaryReportEnabled') || isSwitchedUser;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
    //     case FLEETX_ACCOUNTS.CHANDERIA:
    //         return true;
    //     default:
    //         return isSwitchedUser;
    // }
};

export const setDefaultValuesInDefaultConfigForAccount = (loggedInUser, defaultConfig) => {
    let disableColumnsMapping = [];
    const defaultConfigResult = { ...defaultConfig };

    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.HELLA_INFRA:
            disableColumnsMapping = hellaInfraDisableColumnsMapping;
            break;
        default:
            disableColumnsMapping = [];
    }
    forEach(disableColumnsMapping, (column) => {
        set(defaultConfigResult, `${column}.value`, false);
    });
    return defaultConfigResult;
};
export const showMarkerAsVehicleTypeForAccount = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
};

export const showDriverNameForSharePage = (accountId, isSharedRealtimeVehicle) => {
    return !(includes([FLEETX_ACCOUNTS.SUPER_INDIA_LOGISTICS], accountId) && isSharedRealtimeVehicle);
};

export const showVehicleMakerAndModelForSharePage = (accountId, isSharedRealtimeVehicle) => {
    return !(includes([FLEETX_ACCOUNTS.SUPER_INDIA_LOGISTICS], accountId) && isSharedRealtimeVehicle);
};

export const enableFullScreenForShare = (accountId) => {
    return includes([FLEETX_ACCOUNTS.SUPER_INDIA_LOGISTICS, 14375, 14329], accountId);
};

export const showSharedLinkIsValidTill = (accountId) => {
    return !includes([FLEETX_ACCOUNTS.SUPER_INDIA_LOGISTICS], accountId);
};

export const isCustomOverviewEnabled = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
};
export const isControlRoomDefaultDirect = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.JKLC:
            return true;
        default:
            return false;
    }
};

export const toShowInvoiceNumberInAllJobs = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.KEI_INDUSTRIES:
            return true;
        default:
            return false;
    }
};

export const isNewSidebarAllowToUser = (loggedInUser) => {
    return true;
    // switch (get(loggedInUser, 'accountId', 0)) {
    // case FLEETX_ACCOUNTS.FLEETX:
    //     return true;
    // default:
    //     if (get(loggedInUser, 'accountId', 0) > 4000) {
    //         return true;
    //     }
    //     return false;
    // }
};
export const isDefaultControlRoomAccountForLive = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
            return false;
        default:
            return true;
    }
};

export const showManualEntryModalForAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INTERCITY_TRANSPORT:
            return true;
        case FLEETX_ACCOUNTS.SRI_VIJAY_VIDHALAYA:
            return true;
        default:
            return false;
    }
};
export const showManualEntryModalForAccountWithoutDeviceCheck = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.PINNACLE:
            return true;
        default:
            return false;
    }
};

export const isIncreaseLimitTo30m = () => {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHYAM_TRAVELS:
            return true;
        default:
            return false;
    }
};

export const isDisableCameraViewForTheAccount = () => {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.VENNELA_LOGISTIC:
            return true;
        default:
            return false;
    }
};

export const isTurnoverReportEnabled = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isTurnoverReportEnabled') || isSwitchedUser;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.IPL:
    //         return true;
    //     default:
    //         return isSwitchedUser;
    // }
};

export const areRiskyPointsAvailable = (loggedInUser) => {
    return getFlagValueFromAccountConfig('areRiskyPointsAvailable');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //         return true;
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //         return false;
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //         return true;
    //     case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
    //         return false;
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT:
    //         return true;
    //     default:
    //         return false;
    // }
};
export const isAddJobVisible = (loggedInUser) => {
    return getFlagValueFromAccountConfig('isAddJobVisible');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //         return false;
    //     default:
    //         return true;
    // }
};
export const includeHourlyTimeRangesInCal = () => {
    return getFlagValueFromAccountConfig('includeHourlyTimeRangesInCal');
};

export const includeYearlyTimeRangesInCal = () => {
    return getFlagValueFromAccountConfig('includeYearlyTimeRangesInCal');
};

export const enableUserInputBoxForCalendar = () => {
    return getFlagValueFromAccountConfig('enableUserInputBoxForCalendar');
};

export const enableDriverAnalytics = (loggedInUser) => {
    return getFlagValueFromAccountConfig('enableDriverAnalytics');
};

export const driverAnalyticsWithoutJob = (loggedInUser) => {
    return getFlagValueFromAccountConfig('driverAnalyticsWithoutJob');
};

export const isCeilingPriceRequiredForAuction = () => {
    return getFlagValueFromAccountConfig('isCeilingPriceRequiredForAuction');
};

export const ignoreZeroTrips = (loggedInUser) => {
    return getFlagValueFromAccountConfig('ignoreZeroTrips');
};

export const isMissiningFuelEnable = (isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isMissiningFuelEnable') || isSwitchedUser;
};

export const isManualJobVisible = (loggedInUser) => {
    // return getFlagValueFromAccountConfig('isManualJobVisible');
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.NSL:
        case FLEETX_ACCOUNTS.SHIV_SHAKTI:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.MAHALAXMI:
        case FLEETX_ACCOUNTS.VIJAY_ROADLINES:
        case FLEETX_ACCOUNTS.TOLINS_PURE_GOLD:
        case FLEETX_ACCOUNTS.PLENTEOUS_PHARMA:
        case FLEETX_ACCOUNTS.ENTERPRISE_DEMO_ACCOUNT:
            return true;
        default:
            return false;
    }
};
export const showManualJobsByDefault = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.BIGBASKET:
        case FLEETX_ACCOUNTS.VIJAY_ROADLINES:
            return true;
        default:
            return null;
    }
};

export const isRiskyPointPresenceSupercedeScore = (loggedInUser) => {
    return getFlagValueFromAccountConfig('isRiskyPointPresenceSupercedeScore');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //         return true;
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //         return true;
    //     default:
    //         return false;
    // }
};

export const enableJobLevelCustomFieldsInJobSummaryReport = () =>
    getFlagValueFromAccountConfig('showJobSummaryCustomFields');
export const isStoppagePointPresenceSupercedeScore = (loggedInUser) => {
    return getFlagValueFromAccountConfig('isStoppagePointPresenceSupercedeScore');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
    //         return true;
    //     default:
    //         return false;
    // }
};
export const getRiskyPointMinuteThreshold = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ADITYA_CEMENT:
            return 15;
        case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
            return 10;
        default:
            return 15;
    }
};

export const showMapDefaultViewAsHybrid = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RD_ROADLINES:
            return true;
        default:
            return false;
    }
};

export const getJobReportFromS3 = (loggedInUser) => {
    return true;
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.JK_CEMENT:
        case FLEETX_ACCOUNTS.ADITYA_CEMENT:
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
};

export const getGradeThreshold = (totalScore) => {
    return [round(totalScore * 0.75), round(totalScore * 0.5), round(totalScore * 0.25)];
};
export const getRiskThreshold = (totalScore) => {
    return [round(totalScore * 1), round(totalScore * 0.5)];
};

export const getDefaultDistanceFormula = (loggedInUser, type, column, index) => {
    if (type === 'RISK') {
        if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ADITYA_CEMENT) {
            return `+IF(${column}${index}<700,"N",IF(${column}${index}<800,"C","E"))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE) {
            return `+IF(${column}${index}<610,"N",IF(${column}${index}<640,"C","E"))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS) {
            return `+IF(${column}${index}<312,"N",IF(${column}${index}<330,"C","E"))`;
        }
        return `+IF(${column}${index}<700,"N",IF(${column}${index}<800,"C","E"))`;
    } else {
        if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ADITYA_CEMENT) {
            return `+IF(${column}${index}<700,0,IF(${column}${index}<800,3,5))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE) {
            return `+IF(${column}${index}<610,0,IF(${column}${index}<640,3,5))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE) {
            return `+IF(${column}${index}<312,0,IF(${column}${index}<330,3,5))`;
        }
        return `+IF(${column}${index}<700,0,IF(${column}${index}<800,3,5))`;
    }
};
export const getDefaultDelayFormula = (loggedInUser, type, column, index) => {
    if (type === 'RISK') {
        if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ADITYA_CEMENT) {
            return `+IF(${column}${index}<1500,"N",IF(${column}${index}<2100,"N",IF(${column}${index}<2400,"C","E")))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE) {
            return `+IF(${column}${index}<2400,"N",IF(${column}${index}<2700,"C","E"))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS) {
            return `+IF(${column}${index}<720,"N",IF(${column}${index}<900,"C","E"))`;
        }
        return `+IF(${column}${index}<1500,"N",IF(${column}${index}<2100,"N",IF(${column}${index}<2400,"C","E")))`;
    } else {
        if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ADITYA_CEMENT) {
            return `+IF(${column}${index}<1500,0,IF(${column}${index}<2100,0,IF(${column}${index}<2400,3,5)))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE) {
            return `+IF(${column}${index}<2400,0,IF(${column}${index}<2700,3,5))`;
        } else if (get(loggedInUser, 'accountId', '') === FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS) {
            return `+IF(${column}${index}<720,0,IF(${column}${index}<900,3,5))`;
        }
        return `+IF(${column}${index}<1500,0,IF(${column}${index}<2100,0,IF(${column}${index}<2400,3,5)))`;
    }
};

export const isMultiJobReport = (loggedInUser, isSwitchedUser) => {
    return getFlagValueFromAccountConfig('isMultiJobReport') || isSwitchedUser;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN:
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //         return true;
    //     default:
    //         return isSwitchedUser;
    // }
};

export function showTripDistanceFromGPS(loggedInUser, isSwitchedUser) {
    return getFlagValueFromAccountConfig('showTripDistanceFromGPS') || isSwitchedUser;
    // switch (get(loggedInUser, 'account', 0)) {
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         // case FLEETX_ACCOUNTS.DENEB:
    //         return true;
    //     default:
    //         return isSwitchedUser;
    // }
}

export function onlyVerifiedDrivers() {
    return getFlagValueFromAccountConfig('onlyVerifiedDrivers');
}

export function excludeBlacklistedUsers() {
    return getFlagValueFromAccountConfig('excludeBlacklistedUsers');
}

export function showOdometer(loggedInUser, isSwitchedUser) {
    if (isSwitchedUser) {
        return true;
    }
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.IP_TRAVEL:
        case FLEETX_ACCOUNTS.MAYURI_BROILER:
        case FLEETX_ACCOUNTS.SHAHI:
            return true;
        default:
            return false;
    }
}

export function isFleetxAccount(loggedInUser) {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.FLEETX;
}

export function isHellaInfraAccount(loggedInUser) {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.HELLA_INFRA;
}

export function isInfraMarketAccount(loggedInUser) {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.INFRA_MARKET;
}

export function isBajajAccount(loggedInUser) {
    return get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.BAJAJ;
}

export function isChannelSalesAccount(loggedInUser) {
    return get(loggedInUser, 'accountId') === 4241 || get(loggedInUser, 'accountId') === 3167;
}

export function isNSLAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 204;
}

export function isPCLAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 15150;
}

export function isSandeepLogisticsAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 271;
}

export function isBirlaDurgapurAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 914;
}

export function isJSWAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 9826;
}

export function isSaintGobainAccount(loggedInUser) {
    return loggedInUser && (loggedInUser.accountId === 300 || loggedInUser.accountId === 407);
}

export function isRSaiAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 280;
}

export function isDtdcAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 295;
}

export function isBirlaCorporationDurgapurAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 914;
}

export function isOmLogisticAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.OM_LOGISTICS;
}

export function isOmLogistic2Account(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.OM_LOGISTICS_2;
}

export function isOmLogisticsSharedUrl(accountId) {
    return accountId == FLEETX_ACCOUNTS.OM_LOGISTICS || accountId == FLEETX_ACCOUNTS.OM_LOGISTICS_2;
}

export function isOmLogisticTelecom(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 315;
}

export function showZigZagOnMap() {
    const isSwitchedUser = get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
    if (isSwitchedUser) {
        return true;
    }
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);

    if (includes(isShreeCementsAccounts, accountId)) {
        return true;
    } else {
        return false;
    }

    // switch (accountId) {
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT_8000:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isJupiterAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 326;
}

export function isBrahamaputraAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 564;
}

export function isGujaratLogisticAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 784;
}

export function isExpressRoadwaysAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 813;
}

export function isULPHAccount() {
    return getAccountId() === 13278;
}

export function isSunFlagAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId == FLEETX_ACCOUNTS.SUNFLAG;
}

export function isAdaniCoalAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId == FLEETX_ACCOUNTS.ADANI_COAL;
}

export function isBigBasketAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId == FLEETX_ACCOUNTS.BIGBASKET;
}

export function isGrasimAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === 4895;
}

export function isBhartiCementBulkerAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === 2034;
}

export function isStarCementAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.START_CEMENT;
}

export function isClassicLogisticsAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.CLASSIC_TRANS;
}

function showAddressBookListForUserId(loggedInUser) {
    return loggedInUser && loggedInUser.id !== 3363;
}

function isSandeepLogisticsMarutiGroupAccount(loggedInUser) {
    return loggedInUser && loggedInUser.id === 7537;
}

function isUltraTechSwitchUser(loggedInUser) {
    // this user is able to switch between all Ultratech accounts
    return loggedInUser && loggedInUser.id === 35477;
}

export function isKVTCAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.KVTC;
}

export function isBafnaAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.BAFNA_TRANSLINES;
}

export function isSmartGreenAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.SMART_GREEN;
}

export function isGujaratCementAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS;
}

export function isSRVLogisticsAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.SRV_LOGISTICS;
}

export function isSuzukiMotorsGujaratAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.SUZUZI_MOTORS_GUJARAT;
}

export function isWelSpunAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.WELSPUN;
}

export function isAryanTransAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.ARYAN_TRANS;
}

export function isJyotiStripsAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.JYOTI_STRIPS;
}

export function isRCIAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.RCI;
}

export function isDemoAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.DEMO_ACCOUNT;
}

export function isShivaniAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.SHIVANI;
}

export function isRMCAccount() {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.RMC;
}

export function isAmanBusAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.AMANBUS;
}

export function isRecordsHiddenForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GLENMARK_PHARMA:
        case FLEETX_ACCOUNTS.PLENTEOUS_PHARMA:
            return true;
        default:
            return false;
    }
}

export function isShowCodeInCustomerNameForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.HUL:
            return true;
        default:
            return false;
    }
}

export function isLabelConsignee(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.PLENTEOUS_PHARMA:
        case FLEETX_ACCOUNTS.GLENMARK_PHARMA:
            return 'Consignee';
        default:
            return 'Consignor';
    }
}

export function isGlenmarkPharma() {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    return get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.GLENMARK_PHARMA;
}

export function isCustomerContractsHiddenForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GLENMARK_PHARMA:
        case FLEETX_ACCOUNTS.PLENTEOUS_PHARMA:
            return true;
        default:
            return false;
    }
}

export function showLargeUiForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showLargeUiForAccount');
}

export function isTripNormalizationEnabledForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isTripNormalizationEnabledForAccount');
}

export function showUnloadingCompleteInRealtimeForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showUnloadingCompleteInRealtime');
}

export function isShowSmsButtonForGeofence() {
    return getFlagValueFromAccountConfig('isShowSmsButtonForGeofence');
}

export function showSmsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showSmsForAccount');
}

export function showThirteenPointsOnXaxis(loggedInUser) {
    return getFlagValueFromAccountConfig('showThirteenPointsOnXaxis');
}

export function showSinglePartEntry(loggedInUser) {
    return getFlagValueFromAccountConfig('showSinglePartEntry');
}

export function workOrderApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('workOrderApprovalConfigEnabled');
}

export function fuelLogApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('fuelLogApprovalConfigEnabled');
}

export function jobTransactionApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('transactionsApprovalConfigEnabled');
}

export function serviceEntryApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('serviceEntryApprovalConfigEnabled');
}

export function billingInfoApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('billingInfoApprovalConfigEnabled');
}

export function vehicleDocumentApprovalMatrixConfigEnabled() {
    return getFlagValueFromAccountConfig('vehicleDocumentApprovalMatrixConfigEnabled');
}

export const getAccountConfig = () => {
    const reduxState = getReduxState();
    return reduxState?.config?.accountConfig ?? {};
    // if (reduxState) {
    //     return reduxState?.config?.accountConfig || {};
    // } else {
    //     return {};
    // }
};

export function showOsmInTimeLineMap(accountId, isShared) {
    if (get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false)) {
        return true;
    }

    const accId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (accId === 10049) {
        return false;
    }

    // if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) == FLEETX_ACCOUNTS.VENTURE_SUPPLY) {
    //     return false;
    // }
    // if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) >= 5000) {
    //     return true;
    // }
    const account_config = getAccountConfig();
    if (isEmpty(account_config)) {
        return true;
    } else {
        return getFlagValueFromAccountConfig('showOsmInTimeLineMap');
    }
}

export function showOsmInJobMap(accountId, isShared) {
    // if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) == FLEETX_ACCOUNTS.VENTURE_SUPPLY) {
    //     return false;
    // }
    // if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) >= 6000) {
    //     return true;
    // }

    const accId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (accId === 10049) {
        return false;
    }
    const account_config = getAccountConfig();
    if (isEmpty(account_config)) {
        return true;
    }
    if (
        get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false) ||
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) == FLEETX_ACCOUNTS.FLEETX
    ) {
        return true;
    } else {
        return getFlagValueFromAccountConfig('showOsmInJobMap');
    }
}

export function showOsmMapInRealTime(accountId, isShared) {
    if (get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false)) {
        return true;
    }
    const accId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (
        accId === FLEETX_ACCOUNTS.DAULAT ||
        accId === FLEETX_ACCOUNTS.SHIVANI ||
        accId === FLEETX_ACCOUNTS.DELTAWAY ||
        accId === FLEETX_ACCOUNTS.KAPOOR_DIESELS ||
        accId === FLEETX_ACCOUNTS.CLASSIC_TRANS ||
        accId === FLEETX_ACCOUNTS.CAREWAY_CARGO ||
        accId === FLEETX_ACCOUNTS.RIGHT_CHOICE ||
        accId === 10049
    ) {
        return false;
    }
    if (accountId && isShared && hideOsmInShare(accountId)) {
        return false;
    }
    const account_config = getAccountConfig();
    if (isEmpty(account_config)) {
        return true;
    } else {
        return getFlagValueFromAccountConfig('showOsmMapInRealTime');
    }
}

export function hideOsmInShare(accountId, isShared) {
    return includes([FLEETX_ACCOUNTS.CREATIVE_PUBLIC, 15346], accountId, 0);
}

export function showJobAlarmsForAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.GRASIM_INBOUND;
}

export function showfastagMoreConfig(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHIVANI:
        case FLEETX_ACCOUNTS.FLYING_CARGO:
        case FLEETX_ACCOUNTS.JET_TRADE:
            return true;
        default:
            return false;
    }
}

export function showLargeMapSmallListForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showLargeMapSmallListForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.GOLDEN_ROADWAYS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isDenebAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.DENEB;
}

export function showReverseAddressColoringForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showReverseAddressColoringForAccount');
    //return isSaintGobainAccount(loggedInUser);
}

export function hideVehicleNameForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('hideVehicleNameForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BFC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isVendorOnRealtimeOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isVendorOnRealtimeOpen');
}

export function isVehicleSizeOnRealtimeOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isVehicleSizeOnRealtimeOpen');
}

export function isInvalidNetworkPolylineEnabled() {
    return getFlagValueFromAccountConfig('isInvalidNetworkPolylineEnabled');
}

export function promptLockOverSimInJobFormForAccount() {
    return getFlagValueFromAccountConfig('promptLockOverSimInJobFormForAccount');
}

export function isDriverMandatoryForEdit() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BIGBASKET:
        case FLEETX_ACCOUNTS.STRIKE:
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
            return true;
        default:
            return false;
    }
}

export function isNotShowTranporterVehicle(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return false;
        default:
            return true;
    }
}

export function showAddDataIndentFields(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return false;
        default:
            return true;
    }
}

export function includeAttachedInSelectableDeviceApi(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.XIAOMI:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isDestinationPointMandatoryForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GROFERS:
            return true;
        default:
            return false;
    }
}

export function showAllLinksOnMapInfowindowForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showAllLinksOnMapInfowindowForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BFC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showRemovedVehiclesForAccount(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    if (get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.DENEB) {
        // only 1233 (monitoring@denebpollux.com) should be shown removed vehicles
        return get(loggedInUser, 'id', 0) === 1233;
    }
    return getFlagValueFromAccountConfig('showRemovedVehiclesForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.FLEETX:
    //     case FLEETX_ACCOUNTS.HARYANA:
    //     case FLEETX_ACCOUNTS.ORANGE_CAB:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS_2:
    //     case FLEETX_ACCOUNTS.SAJAL_MAJUMDAR:
    //     case FLEETX_ACCOUNTS.QUICK_DIGITAL:
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //     case FLEETX_ACCOUNTS.NARAYANA:
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //     case FLEETX_ACCOUNTS.SPEEDWING:
    //     case FLEETX_ACCOUNTS.TRANSIN_AMBUJA:
    //     case FLEETX_ACCOUNTS.KVTC:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT:
    //     case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //         return true;
    //     case FLEETX_ACCOUNTS.DENEB:
    //         return !get(loggedInUser, 'groups', []).length;
    //     default:
    //         return false;
    // }
}

export function hideRemovedVehiclesOnRealtimeForAccount(loggedInUser) {
    if (get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.JKLC) {
        return true;
    }
    return false;
}

export function showNewTemperatureReportForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ALGOR:
            return true;
        default:
            return false;
    }
}

export function sendFixApprovedCount(accountId) {
    switch (toSafeInteger(accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function useBackendIndentReportForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function showContainerNumbersOutInJobListing() {
    return includes([FLEETX_ACCOUNTS.MANVILLE], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showRemoveVehiclesInSearchForAccount(loggedInUser, isSwitchedUser) {
    return (
        getFlagValueFromAccountConfig('showRemoveVehiclesInSearchForAccount') ||
        get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false) ||
        allowSimBasedTrackingForAccount(loggedInUser) ||
        showRemovedVehiclesForAccount(loggedInUser) ||
        isOrientCementAccount(getAccountId())
    );
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.MP_BIRLA:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //     case FLEETX_ACCOUNTS.TRANSIN_AMBUJA:
    //     case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT:
    //     case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //         return true;
    //     default:
    //         return (
    //             isSwitchedUser ||
    //             showRemovedVehiclesForAccount(loggedInUser) ||
    //             allowSimBasedTrackingForAccount(loggedInUser)
    //         );
    // }
}

export function isPushNotificationAlsoCheckedViaRealtimeForAccount(loggedInUser, alertId) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        case FLEETX_ACCOUNTS.DENEB:
        case FLEETX_ACCOUNTS.SRINIVASA:
            //FOR SOS
            return alertId === ALERT_SETTINGS_FIELDS[14].id;
        case FLEETX_ACCOUNTS.SURYA_TRAVELS:
            //FOR SOS
            return alertId === ALERT_SETTINGS_FIELDS[14].id;
        default:
            return false;
    }
}

export function getIdleMarkerDelayTimeoutForAccount(loggedInUser) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.DENEB:
        case FLEETX_ACCOUNTS.SUZUZI_MOTORS_GUJARAT:
        case FLEETX_ACCOUNTS.ADITYA_BIRLA:
        case FLEETX_ACCOUNTS.ADITYA_BIRLA_BAGA:
        case FLEETX_ACCOUNTS.ADITYA_BIRLA_PALI:
        case FLEETX_ACCOUNTS.GRASIM_INDUSTRY:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.SANJAY_TOURS:
            return 0;
        default:
            return 40 * 1000;
    }
}

export const isConsignorShownInJob = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DTDC:
        case FLEETX_ACCOUNTS.BVC:
            return false;
        default:
            return true;
    }
};
export const isOdometerMandatoryJobForm = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DTDC:
            return true;
        default:
            return false;
    }
};
export const isScheduledDistanceOnJobForm = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER:
            return true;
        default:
            return false;
    }
};
export const isDriverShownInJob = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GUJARAT_LOGISTICS:
            return true;
        default:
            return false;
    }
};
export const isVehicleSelectMulti = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GUJARAT_LOGISTICS:
            return true;
        default:
            return false;
    }
};

export function getMaxSpeedGovernerLimitForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.VICEROY:
        case FLEETX_ACCOUNTS.SRINIVASA:
        case 6829:
            return 80;
        default:
            return 0;
    }
}

export function getParkingAddressBookId(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
            return 19517;
        case FLEETX_ACCOUNTS.CHANDERIA:
            return 29069;
        default:
            return 0;
    }
}

export function getCustomJobReportModalLabel(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return 'Job Report for Group (SGU Trucks)';
        default:
            return '';
    }
}

export function getNearestAddressKmLimitForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FAST_SOLUTION:
            return -1;
        case FLEETX_ACCOUNTS.VARUN_BEVERAGES:
            return 1000;
        default:
            return 8000;
    }
}

export function getSingleMarkerZoomLevelForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JUPITER:
            return 8;
        case FLEETX_ACCOUNTS.RASHMI:
            return 12;
        case FLEETX_ACCOUNTS.CLASSIC_TRANS:
            return 9;
        case 11099:
            return 9;
        default:
            return 16;
    }
}

export function getMultipleMarkerZoomLevelForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DENEB:
            return 20;
        default:
            return 13;
    }
}

export function getMaxClusterZoomLevelForAccount(loggedInUser) {
    const singleMarkerZoomLevelForAccount = getSingleMarkerZoomLevelForAccount(loggedInUser);
    if (singleMarkerZoomLevelForAccount < 13) {
        return singleMarkerZoomLevelForAccount - 1;
    } else if (singleMarkerZoomLevelForAccount === 16) {
        return getMultipleMarkerZoomLevelForAccount(loggedInUser) - 1;
    }
    return 12;
}

export function getJobBookingUploaderFile(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return '/public/xlsx/jobs/Job_Booking_Upload_Sample_file_Maersk_v2.xlsx';
        default:
            return '/public/xlsx/jobs/Job_Booking_Upload_Sample_file.xlsx';
    }
}

export function getConsingmentsUploaderFile(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.PLENTEOUS_PHARMA:
            return '/public/xlsx/akums_consignment_uploader.xlsx';
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return '/public/xlsx/sample_consignment_uploader_Maersk.xlsx';
        default:
            return '/public/xlsx/sample_consignment_uploader.xlsx';
    }
}

export function allowMultipleTimestampsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('allowMultipleTimestampsForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showMarkersInsteadOfPolylineAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showMarkersInsteadOfPolylineAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BAJAJ:
    //     case FLEETX_ACCOUNTS.MARUTI_TRAINS:
    //     case FLEETX_ACCOUNTS.TVS_SUPPLY_CHAIN_SOLUTIONS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function allowAnyStoppageMinutesForAccount() {
    return getFlagValueFromAccountConfig('allowAnyStoppageMinutesForAccount');
}

export function showStopNoForAccount() {
    return getFlagValueFromAccountConfig('showStopNoForAccount');
}

export function isTMSPtlFlowEnabledForAccount() {
    return getFlagValueFromAccountConfig('isTMSPtlFlowEnabled');
}

export function isIssueUpdateAllowedOnTransporterSide() {
    return getFlagValueFromAccountConfig('isIssueUpdateAllowedOnTransporterSide');
}

export function confirmationForAlreadyAssignedVehicle() {
    return getFlagValueFromAccountConfig('confirmationForAlreadyAssignedVehicle');
}

export function defaultValueForCloseJobBeforeAssign() {
    return getFlagValueFromAccountConfig('defaultValueForCloseJobBeforeAssign');
}

export function showVehiclesOfIndentedSizeForAccount() {
    return getFlagValueFromAccountConfig('showVehiclesOfIndentedSize');
}

/**
 *
 * @param {*} loggedInUser
 * @returns
 */

export function excludeOverlappingTripsForAccount(loggedInUser = null) {
    return getFlagValueFromAccountConfig('excludeOverlappingTripsForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BAJAJ:
    //     case FLEETX_ACCOUNTS.DENEB:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function allowExcludeOverlappingTripsToggleForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('allowExcludeOverlappingTripsToggleForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //     case FLEETX_ACCOUNTS.SAI_GANESH_TRAVELS:
    //     case FLEETX_ACCOUNTS.RP_SINGH_LOGISTICS:
    //     case FLEETX_ACCOUNTS.DTDC:
    //     case FLEETX_ACCOUNTS.NUTAN_RAJUMANI:
    //     case FLEETX_ACCOUNTS.ARYAN_CABS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function fetchGeoCodedLocationForSwitcherUserForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('fetchGeoCodedLocationForSwitcherUserForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DENEB:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function fetchGeocodedlocationsAlwaysForReportForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('fetchGeocodedlocationsAlwaysForReportForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.NSL:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getDefaultTripViewTypeForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SAINT_GOBAIN:
        case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
            return TRIP_VIEW_TYPE.STOPPAGE_LIST;
        default:
            return TRIP_VIEW_TYPE.DEFAULT;
    }
}

export function getSimPingMinThresholdForAccount(loggedInUser, simProvider = null) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.VINSUM_AXPRESS:
        case FLEETX_ACCOUNTS.KPTRANSPORT:
            return 3600;
        case FLEETX_ACCOUNTS.CTA_LOGISTICS:
            return 7200;
        case FLEETX_ACCOUNTS.ANAND_ROADLINES:
        case FLEETX_ACCOUNTS.FENESTA:
        case FLEETX_ACCOUNTS.MAHALAXMI:
        case FLEETX_ACCOUNTS.JUST_DIAL:
            return 10800;
        case FLEETX_ACCOUNTS.SUNTEK_AXPRESS:
            return 28800;

        default:
            if (simProvider === 'AIRTEL') {
                return 1800;
            }
            return 900;
    }
}

export function getSimPingMaxThresholdForAccount(loggedInUser, simProvider = null) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KPTRANSPORT:
            return 3600;
        case FLEETX_ACCOUNTS.MAHALAXMI:
        case FLEETX_ACCOUNTS.JUST_DIAL:
            return 10800;
        case FLEETX_ACCOUNTS.SUNTEK_AXPRESS:
            return 86400;
        default:
            return 86400;
    }
}

export function showVehicleNumber(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.VICEROY:
        case FLEETX_ACCOUNTS.MRSHAH:
            return false;
        default:
            return !isOnlyAssetTrackingAccount(loggedInUser);
    }
}

export function isDriverColClosedInDayWiseReport() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BLR_LOGISTICS:
            return false;
        default:
            return true;
    }
}

export function isDriverOpenInHistoryReportAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BLR_LOGISTICS:
            return false;
        default:
            return true;
    }
}

export function showUptimeDayWiseReportForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.AVINASH:
            return true;
        default:
            return getFlagValueFromAccountConfig('showDaywiseUptimeReport');
    }
}

export function isShowIntevalOfTenOfTempGraph(loggedInUser) {
    return getFlagValueFromAccountConfig('isShowIntevalOfTenOfTempGraph');
}

export function isShowDotsToAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ALGOR:
        case FLEETX_ACCOUNTS.NARMIN_COOL_CARRIER:
        case FLEETX_ACCOUNTS.SAI_LOGISTIC:
        case FLEETX_ACCOUNTS.BRTC:
        case FLEETX_ACCOUNTS.SADHI_LOGISTIC:
        case FLEETX_ACCOUNTS.SHIVAM_LOGISTIC:
        case FLEETX_ACCOUNTS.SHREE_KRISHNA_FROZEN:
        case 13021:
            return false;
        default:
            return true;
    }
}

export function showUptimeSectionForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showUptimeSectionForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DTDC:
    //     case FLEETX_ACCOUNTS.JUPITER:
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS:
    //     case FLEETX_ACCOUNTS.VISHWAA:
    //     case FLEETX_ACCOUNTS.BEST_ROADWAYS:
    //     case FLEETX_ACCOUNTS.RAO_TOURIST:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isRemoteSelectOpenForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isRemoteSelectOpenForAccount');
}

export function isSplitTrueForAcccount() {
    return getFlagValueFromAccountConfig('isSplitTrueForAcccount');
}

export function allowMultiVehicleUptimeDownloadForAccount(loggedInUser, isSwitchedUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DTDC:
        case FLEETX_ACCOUNTS.RMC:
            return true;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.VISHWAA:
        case FLEETX_ACCOUNTS.BEST_ROADWAYS:
        case FLEETX_ACCOUNTS.SINGAL:
            return isSwitchedUser;
        default:
            return isSwitchedUser;
    }
}

export function showLeafLetMapForAccount(loggedInUser) {
    return true;
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function showFuelDetailsInsideVehicleRunning(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EVEREST:
        case FLEETX_ACCOUNTS.MANCHUKONDA_PRAKASHAM_INDUSTRIES:
        case FLEETX_ACCOUNTS.LALITHA_ENTERPISE:
            return true;
        default:
            return false;
    }
}

export function showFuelDetailsInFuelTab(loggedInUser) {
    return getFlagValueFromAccountConfig('includeFuelDetails');
}

export function isHideTripDataInRealtimeRowForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isHideTripDataInRealtimeRowForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RSAI:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isOnlyAssetTrackingAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isOnlyAssetTrackingAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RAI_TRAINS:
    //     case FLEETX_ACCOUNTS.MARUTI_TRAINS:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS_2:
    //     case FLEETX_ACCOUNTS.QUICK_DIGITAL:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function hasAssetTrackingForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.OM_LOGISTICS_PORTABLE:
        case FLEETX_ACCOUNTS.OM_LOGISTICS_2:
        case FLEETX_ACCOUNTS.SAJAL_MAJUMDAR:
        case FLEETX_ACCOUNTS.QUICK_DIGITAL:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.RAI_TRAINS:
        case FLEETX_ACCOUNTS.MARUTI_TRAINS:
        case FLEETX_ACCOUNTS.SHIVANI:
        case FLEETX_ACCOUNTS.KPTRANSPORT:
        case FLEETX_ACCOUNTS.NECC:
        case FLEETX_ACCOUNTS.INIFARMS:
        case FLEETX_ACCOUNTS.MEHTA_TRAVELS:
        case FLEETX_ACCOUNTS.GODREJ_CHEMICALS:
        case FLEETX_ACCOUNTS.SINGH_TRANSPORT_3843:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.ICICI_LOMBARD:
            return true;
        default:
            return allowSimBasedTrackingForAccount(loggedInUser);
    }
}

export function getDeviceSearchKeyForAccount() {
    return getFlagValueFromAccountConfig('deviceSearchKeyForAccount');
}

export function allowDeviceSettingsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('allowDeviceSettingsForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS_2:
    //     case FLEETX_ACCOUNTS.SAJAL_MAJUMDAR:
    //     case FLEETX_ACCOUNTS.QUICK_DIGITAL:
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isJobChecklistOpenForAccount() {
    return getFlagValueFromAccountConfig('isJobChecklistOpenForAccount');
}

export function showDeviceDetailsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showDeviceDetailsForAccount');
    //return isDtdcAccount(loggedInUser);
}

export function showDeviceHealthDetailsForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
            return true;
        default:
            return false;
    }
}

export function isTimelineAndItinearayPositionChange(loggedInUser) {
    if (get(loggedInUser, 'accountId', 0) > 5000 || get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.FLEETX) {
        return true;
    } else {
        return false;
    }
}

export function showBulkVehicleDeviceHistoryForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return true;
        default:
            return false;
    }
}

export function isTataSteelLimitedAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.TATA_STEEL_LIMITED;
}

export function isVehicleStatusReportEnabled(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KALBURGI:
            return true;
        default:
            return false;
    }
}

/**
 *
 * @param {*} loggedInUser
 * @returns
 */

export function isRouteTimingsInMinutesForAccount(loggedInUser = null) {
    return getFlagValueFromAccountConfig('isRouteTimingsInMinutesForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DTDC:
    //     case FLEETX_ACCOUNTS.DAINIK_BHASKAR:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isProgressbarshowToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.HAULWAYS:
            return true;
        default:
            return false;
    }
}

export function isTransporterCodeShownToAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GRASIM:
            return true;
        default:
            return false;
    }
}

export function isRouteEditDisabledForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DTDC:
            return true;
        default:
            return false;
    }
}

export function isVehicleLoadRequired(loggedInUser) {
    return isJupiterAccount(loggedInUser);
}

export function isJobNameDerivedFromGroupName(loggedInUser) {
    return isDtdcAccount(loggedInUser);
}

export function prefillWithHistoricalLocationForAccount(loggedInUser) {
    return isDtdcAccount(loggedInUser);
}

export function allowDepartArriveTimeInRouteForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('allowDepartArriveTimeInRouteForAccount');
}

export function allowRealtimeVehiclesMonthReportForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('allowRealtimeVehiclesMonthReportForAccount');
}

export function isLogRocketOpen() {
    return getFlagValueFromAccountConfig('openLogRocket');
}

export function showLiveOdometerForAccount(loggedInUser) {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return getFlagValueFromAccountConfig('showLiveOdometerForAccount') && !includes([111689], roleId);
}

export function showDownloadedReportsForAccount(loggedInUser) {
    return true;
    return getFlagValueFromAccountConfig('showDownloadedReportsForAccount');
}

export function showEngineHoursOfOperationForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.AERIS:
        case FLEETX_ACCOUNTS.AERIS2:
        case FLEETX_ACCOUNTS.AERIS3:
        case FLEETX_ACCOUNTS.AERIS4:
        case FLEETX_ACCOUNTS.AERIS5:
        case FLEETX_ACCOUNTS.CHAKRA_JAWARA:
        case FLEETX_ACCOUNTS.PT_USI:
        case FLEETX_ACCOUNTS.APS_DIGITAL:
        case FLEETX_ACCOUNTS.HINO:
            return true;
        default:
            return false;
    }
}

export function showEngineOilPressureForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.AERIS:
        case FLEETX_ACCOUNTS.AERIS2:
        case FLEETX_ACCOUNTS.AERIS3:
        case FLEETX_ACCOUNTS.AERIS4:
        case FLEETX_ACCOUNTS.AERIS5:
        case FLEETX_ACCOUNTS.CHAKRA_JAWARA:
        case FLEETX_ACCOUNTS.PT_USI:
        case FLEETX_ACCOUNTS.APS_DIGITAL:
        case FLEETX_ACCOUNTS.HINO:
            return true;
        default:
            return false;
    }
}

export function showEngineOilTemperatureForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.AERIS:
        case FLEETX_ACCOUNTS.AERIS2:
        case FLEETX_ACCOUNTS.AERIS3:
        case FLEETX_ACCOUNTS.AERIS4:
        case FLEETX_ACCOUNTS.AERIS5:
        case FLEETX_ACCOUNTS.CHAKRA_JAWARA:
        case FLEETX_ACCOUNTS.PT_USI:
        case FLEETX_ACCOUNTS.APS_DIGITAL:
        case FLEETX_ACCOUNTS.HINO:
            return true;
        default:
            return false;
    }
}

export function showFullnameInMapForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showFullnameInMapForAccount');
}

export function showLicensePlateInsteadOfNameInMapForAccount() {
    return getFlagValueFromAccountConfig('showLicensePlateInsteadOfNameInMapForAccount');
}

export function showFinanceForMasterRoleForAccount(loggedInUser) {
    return isRoleOwner(loggedInUser) || isRoleMaster(loggedInUser);
}

export function showEditAccountForMasterRoleForAccount(loggedInUser, scope) {
    return (
        isRoleOwner(loggedInUser) ||
        isRoleMaster(loggedInUser) ||
        isUpdateAccountsStatusAllowedForUser(scope) ||
        includes(
            ['vikas.goswami@fleetx.io', 'amreesh.singh@fleetx.io'],
            get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId')
        )
    );
    //|| loggedInUser.id === 3056; /*rahul@fleetx.io*/
}

export function showCommentEditForMasterRoleForAccount(loggedInUser) {
    return (
        isRoleOwner(loggedInUser) || isRoleMaster(loggedInUser) || isRoleDataMasterEdit(loggedInUser)
        // || loggedInUser.id === 3056 /*rahul@fleetx.io*/
    );
}

export function activateCreateJobVehicleToggle(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
        case FLEETX_ACCOUNTS.JSW:
        case FLEETX_ACCOUNTS.JSW_1:
            return true;
        default:
            return false;
    }
}

export function showNotOnJobVehiclesByDefault() {
    return includes(
        [FLEETX_ACCOUNTS.SRV_LOGISTICS, FLEETX_ACCOUNTS.MANVILLE, 11553],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId')
    );
}

export function allowDowloadForMasterRoleForAccount(loggedInUser) {
    return isRoleOwner(loggedInUser) || isRoleMaster(loggedInUser) || loggedInUser.id === 3056; /*rahul@fleetx.io*/
    //|| loggedInUser.id === 3056; /*rahul@fleetx.io*/
}

export function allowAccountPaid(loggedInUser, scopes) {
    return isRoleOwner(loggedInUser) || isRoleMaster(loggedInUser) || isUpdateAccountsStatusAllowedForUser(scopes);
    //|| loggedInUser.id === 3056; /*rahul@fleetx.io*/
}

export function hideClustersForAccount(vehiclesLength, loggedInUser) {
    return false;
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RUDRA:
            return true;
        case FLEETX_ACCOUNTS.DENEB:
            return vehiclesLength < 50;
        default:
            return false;
    }
}

export function showVehicleIconsAsMarker(loggedInUser, isSwitchedUser) {
    return getFlagValueFromAccountConfig('showVehicleIconsAsMarker') || isSwitchedUser;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DENEB:
    //     case FLEETX_ACCOUNTS.RCM:
    //     case FLEETX_ACCOUNTS.SK_LOGISTICS:
    //     case FLEETX_ACCOUNTS.TRANS_CORP:
    //         return false;
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //         return isSwitchedUser;
    //     default:
    //         return true;
    // }
}

export function fakePolylineKmDistanceCheckForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 2;
        case FLEETX_ACCOUNTS.RCM:
            return 2000;
        case FLEETX_ACCOUNTS.RAJNEESH_AWASHTI:
            return 0.5;
        case FLEETX_ACCOUNTS.VENDANTA_BALCO:
            return 0.25;
        case 503:
        case 2769:
            return 10;
        default:
            return 5;
    }
}

export function invalidNetworkPolylineKmDistanceCheckForAccount(loggedInUser) {
    return 5;
}

export function isReverseNameFormatForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isReverseNameFormatForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.VICEROY:
    //     case FLEETX_ACCOUNTS.MRSHAH:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showCreateJobButtonOnVehicleForm() {
    return getFlagValueFromAccountConfig('showCreateJobButtonOnVehicleForm');
}

export function getBrahamaputraJobTag(vehicleJob, preCalcData) {
    const latitude = get(vehicleJob, 'startLat');
    const longitude = get(vehicleJob, 'startLon');
    if (!latitude || !longitude) {
        return null;
    }
    const isInsideTheNorth = isPointInsidePolygon(
        latitude,
        longitude,
        BRAHAMAPUTRA_NORTH_REGION_COORDINATES,
        preCalcData.constant,
        preCalcData.multiple,
        preCalcData.needNormalize
    );

    if (isInsideTheNorth) {
        return JOB_TAGS.JOB_UP;
    } else {
        return JOB_TAGS.JOB_DOWN;
    }
}

export function getExpressRoadwaysBoundTags(vehicleJob, userTags, addressBookAsMap) {
    if (vehicleJob.status === VEHICLE_JOB_STATE.LOADING) {
        const startAddressObj = addressBookAsMap[vehicleJob.startAddressBookId];
        const tagsAttachedToStartAddress =
            startAddressObj && startAddressObj.tagIdsList ? startAddressObj.tagIdsList : [];
        if (intersection(userTags, tagsAttachedToStartAddress).length > 0) {
            return JOB_TAGS.OUT_BOUND;
        } else {
            return JOB_TAGS.IN_BOUND;
        }
    } else {
        return null;
    }
}

export function restrictAddressToMajorCitiesForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('restrictAddressToMajorCitiesForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BFC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export const getStartDateColumnName = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 'Reporting Time At Source';
        default:
            return 'Start Date';
    }
};
export const isChannelSalesUser = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX_CHANNEL_SALES:
        case FLEETX_ACCOUNTS.CHANNEL_SALES_PROD:
            return true;
        default:
            return false;
    }
};
export const isAgConstructionAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.AG_CONSTRUCTION:
            return true;
        default:
            return false;
    }
};
export const getStartDateFormatType = (loggedInUser, startDate) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return getMomentTime(startDate).format(DATE_FORMAT_REPORT);
        default:
            return getHumanizeTime(startDate);
    }
};

export function getUserFleetType(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    return get(loggedInUser, 'fleetType', null);
}

export function isTruckFleetType(loggedInUser) {
    return FLEET_TYPE.TRUCK === getUserFleetType(loggedInUser);
}

export function isCarFleetType(loggedInUser) {
    return FLEET_TYPE.CAR === getUserFleetType(loggedInUser);
}

export function showPlantDashboardInControlRoomForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showPlantDashboardInControlRoomForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DHILLON:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showCommentsInJobReport() {
    return getFlagValueFromAccountConfig('isJobReportCommentsEnabled');
}

export function showMovementTypeInJobBooking(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.SATVA_HITECH:
            return true;
        default:
            return false;
    }
}

export function getCustomerId() {
    return get(window.FLEETX_LOGGED_IN_USER, 'customerIds.[0]');
}

export function getAutoChallanType() {
    return getFlagValueFromAccountConfig('autoChallanType');
}

export function getJobBookingSortBasedOn() {
    return getFlagValueFromAccountConfig('jobBookingSortBasedOn');
}

export function isIncludeGroupInJobForm() {
    return getFlagValueFromAccountConfig('includeGroupInJobForm');
}

export function getBusinessType() {
    return getFlagValueFromAccountConfig('businessType');
}

export function fastagEnabledVendor() {
    return getFlagValueFromAccountConfig('fastagEnabledVendor');
}

export function fastagCriticalAmount() {
    return getFlagValueFromAccountConfig('fastagCriticalAmount');
}

export function showEmployeeCodeInUserSelect() {
    return getFlagValueFromAccountConfig('showEmployeeCodeInUserSelect');
}

export function showRealtimeMapInfoWindowOnCardHover() {
    return getFlagValueFromAccountConfig('showRealtimeMapInfoWindowOnCardHover');
}

export function showFuelSensorStatusRealtime() {
    return getFlagValueFromAccountConfig('showFuelSensorStatusRealtime');
}

export function tagsInWhichConsignmentUpdateAllowed() {
    return getFlagValueFromAccountConfig('tagsForWhichConsignmentUpdateAllowedFromEPOD');
}

export function tagsInWhichArrivalDepartureUpdateAllowed() {
    return getFlagValueFromAccountConfig('tagsForWhichArrivalDepartureIsAllowedFromEPOD');
}

export function tagsForWhichAssignVehicleTimeLimitAdded() {
    return getFlagValueFromAccountConfig('tagsForWhichAssignVehicleTimeLimitAdded');
}

export function showPinCodeInAddressForAccount() {
    return getFlagValueFromAccountConfig('showPinCodeInAddressForAccount');
}

export function allowRouteSelectInJobEdit() {
    return getFlagValueFromAccountConfig('allowRouteSelectOnJobEdit');
}

export function refreshJobStationaryBeforeSubmit() {
    return getFlagValueFromAccountConfig('refreshJobStationaryBeforeSubmit');
}

export function allControlRoomViewTypeEnabled(loggedInUser) {
    const allEnabled = [CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD];

    allEnabled.push(CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD);

    // if (!isExpressRoadwaysAccount(loggedInUser)) {
    //     allEnabled.push(CONTROLROOM_VIEW_TYPE.RUNNING_DASHBOARD);
    // }
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            allEnabled.push(CONTROLROOM_VIEW_TYPE.CONTROL_TOWER);
            break;
        case FLEETX_ACCOUNTS.VARUNA:
            allEnabled.push(CONTROLROOM_VIEW_TYPE.CONTROL_TOWER);
            allEnabled.push(CONTROLROOM_VIEW_TYPE.VARUNA_EVENT_BOOK);
            allEnabled.push(CONTROLROOM_VIEW_TYPE.VAARUNA_ORDER_REPORT);
            break;
        case FLEETX_ACCOUNTS.DHILLON:
            allEnabled.push(CONTROLROOM_VIEW_TYPE.PLANT_DASHBOARD_TVS);
            break;
        case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
            allEnabled.push(CONTROLROOM_VIEW_TYPE.DISCONNECT_DASHBOARD);
            break;
    }
    return allEnabled;
}

export function isDownloadRealtimeReportOnMobileForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isDownloadRealtimeReportOnMobileForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RAVRAY:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isMovementReportDownloadFromFrontedForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isMovementReportDownloadFromFrontedForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.MP_BIRLA:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isJobSummaryReportEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('isJobSummaryReportEnabled');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.DTDC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isLatestJobReportEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('isLatestJobReportEnabled');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showDashboardForAccount(loggedInUser) {
    if (isSandeepLogisticsMarutiGroupAccount(loggedInUser)) {
        return false;
    } else {
        return getFlagValueFromAccountConfig('showDashboardForAccount');
    }
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    //return isOverviewEnabled(loggedInUser);
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.NAMRADA:
    //     case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //         return false;
    //     default:
    //         return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    // }
}

export function showTripsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showTripsForAccount');
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
}

export function showVehiclesForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showVehiclesForAccount');
}

export function isVehiclePerformanceReportOpenForAccount() {
    return getFlagValueFromAccountConfig('isVehiclePerformanceReportOpenForAccount');
}

export function showJobsForAccount() {
    return getFlagValueFromAccountConfig('showJobsForAccount');
}

export function showIdlinginSiteAnalytics() {
    return getFlagValueFromAccountConfig('showIdlinginSiteAnalytics');
}

export function showLastTripDetailsinRealtimeReport() {
    return getFlagValueFromAccountConfig('showLastTripDetailsInRealTimeReportForAccount');
}

export function showTimeOnSiteForAccount() {
    return getFlagValueFromAccountConfig('showTimeOnSiteForAccount');
}

export function showAddressBookListForAccount(loggedInUser) {
    return showAddressBookListForUserId(loggedInUser) && getFlagValueFromAccountConfig('showAddressBookListForAccount');
}

export function showJobDestinationForAccount() {
    return getFlagValueFromAccountConfig('showJobDestinationForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function dontHonorMapActionTaken(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.JAI_AMBAY:
        case FLEETX_ACCOUNTS.RD_ROADLINES:
        case FLEETX_ACCOUNTS.GRASIM_INBOUND:
            return true;
        default:
            return getFlagValueFromAccountConfig('dontHonorMapActionTaken');
    }
}

export function refreshHighlightedVehicleRealtime(loggedInUser) {
    return getFlagValueFromAccountConfig('refreshHighlightedVehicleRealtime');
}

export function useCurrentZoomForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RASHMI:
            return true;
        default:
            return false;
    }
}

export function showRemindersForAccount(loggedInUser) {
    if (isSandeepLogisticsMarutiGroupAccount(loggedInUser)) {
        return false;
    }
    return getFlagValueFromAccountConfig('showRemindersForAccount');
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.NAMRADA:
    //     case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //         return false;
    //     default:
    //         return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    // }
}

export function showFinanceForAccount(loggedInUser) {
    if (isSandeepLogisticsMarutiGroupAccount(loggedInUser) || isUltraTechSwitchUser(loggedInUser)) {
        return false;
    }
    return getFlagValueFromAccountConfig('showFinanceForAccount');
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    //return !(isSandeepLogisticsMarutiGroupAccount(loggedInUser) || isUltraTechSwitchUser(loggedInUser));
}

export function showServiceEntriesForAccount(loggedInUser) {
    if (isSandeepLogisticsMarutiGroupAccount(loggedInUser)) {
        return false;
    }
    //return getFlagValueFromAccountConfig('showServiceEntriesForAccount');
    return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.NAMRADA:
    //     case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
    //     case FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE:
    //     case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
    //         return false;
    //     default:
    //         return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
    // }
}

export function showFuelEntriesForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showFuelEntriesForAccount');
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
}

export function showAlarmsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showAlarmsForAccount');
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
}

export function turnOnJobAlarmsFilterOnDashboard() {
    return getFlagValueFromAccountConfig('turnOnJobAlarmsFilterOn');
}

export function showDtcsForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showDtcsForAccount') && showOBDLinkedModuleDataForAccountEnabled();
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
}

export function showButtonRowForAccount(loggedInUser) {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return getFlagValueFromAccountConfig('showButtonRowForAccount') && !includes([111689], roleId);
    //return !isSandeepLogisticsMarutiGroupAccount(loggedInUser);
}

export function show24HoursCalenderFormatForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('show24HoursCalenderFormatForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BAJAJ:
    //     case FLEETX_ACCOUNTS.DENEB:
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN:
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
    //     case FLEETX_ACCOUNTS.DTDC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function show24HoursFormatEverywhereForAccount() {
    return getFlagValueFromAccountConfig('show24HoursFormatEverywhereForAccount');
    // switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN:
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //     case FLEETX_ACCOUNTS.DTDC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showSimpliedStoppageUiForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showSimpliedStoppageUiForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
    //     case FLEETX_ACCOUNTS.SAINT_GOBAIN:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isFirstRunReportOpenForAccount(loggedInUser, isSwitchedUser) {
    return getFlagValueFromAccountConfig('isFirstRunReportOpenForAccount') || isSwitchedUser;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.ANS_INFRACON:
    //         return true;
    //     default:
    //         if (isSwitchedUser) {
    //             return true;
    //         }
    //         return false;
    // }
}

export function show2MileagesInVehicles(loggedInUser) {
    return getFlagValueFromAccountConfig('show2MileagesInVehicles');
}

export function timeIn24HrFormatJobAndWorkOrderReport() {
    return getFlagValueFromAccountConfig('timeIn24HrFormatJobAndWorkOrderReport');
}

export function includeIdleTimeInRunningTimeForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('includeIdleTimeInRunningTimeForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BLR_LOGISTICS:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function toShowCityForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('toShowCityForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EAST_INDIA_TRANSPORT:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getJobShareLogoUrl(accountId) {
    if (accountId === FLEETX_ACCOUNTS.GUJARAT_LOGISTICS) {
        //return '/public/img/logos/clients/784_gujaratlogistics/gujaratlogistics_full.png?v=3';
        return '/public/img/logos/clients/784.png';
    }
}

export function onlyJobDetailMapShare(accountId) {
    if (accountId === FLEETX_ACCOUNTS.INFRA_MARKET) {
        return true;
    } else false;
}

export function getAccountLogo(accountId) {
    return `/public/img/logos/clients/${accountId}.png`;
}

export function showJobShareLogo(isFromJobShare, accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.GUJARAT_LOGISTICS:
            return isFromJobShare;
        default:
            return false;
    }
}

export function isAccountAllowedToChangeDate(loggedInUser) {
    const roleName = get(loggedInUser, 'role.name');
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SMART_GREEN:
            return roleName === ROLE_MAP.ROLE_OWNER.name || roleName === ROLE_MAP.ROLE_ADMIN.name;
        default:
            return true;
    }
}

export function isShareableHeaderNotToShowAccount(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return false;
        default:
            return true;
    }
}

export function allowDeleteForSwitchedUser(isSwitchedUser, switchedUserOriginalRoleId = 0) {
    return isSwitchedUser && switchedUserOriginalRoleId === ROLE_MAP.ROLE_MASTER.id;
}

export function allowSimBasedTrackingForAccount() {
    const { scope, modules, isSwitchedUser } = window.FLEETX_LOGGED_IN_DATA || {};
    return (
        (isManageSimDevices(scope) || isSwitchedUser || isUpdateSimTrackingAllowedForUser(scope)) &&
        isSimTrackingModuleEnabled(modules)
    );
}

export function allowAppBasedTrackingForAccount() {
    const { appTrackingEnabled } = window.FLEETX_LOGGED_IN_USER || {};
    return appTrackingEnabled;
}

export function allowUserCreationWithTechnicianMasterRole(loggedInUser) {
    return getFlagValueFromAccountConfig('allowUserCreationWithTechnicianMasterRole');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.FLEETX_OPERATIONS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function dontShowNewTo(loggedInUser, showNewUi, isSwitchUserAllowed) {
    const accountsWiseShowCondition = showOldVersionRealtime(loggedInUser) ? showNewUi.showNewUiDefault : true;

    // return isSwitchUserAllowed || !accountsWiseShowCondition;

    return isSwitchUserAllowed || !accountsWiseShowCondition;
}

export function showOldVersionRealtime(loggedInUser) {
    return false;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
    //     case FLEETX_ACCOUNTS.MP_BIRLA:
    //     case FLEETX_ACCOUNTS.DTDC:
    //     case FLEETX_ACCOUNTS.ADITYA_CEMENT:
    //     case FLEETX_ACCOUNTS.BRAHAMPUTRA:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS_2:
    //     case FLEETX_ACCOUNTS.OM_LOGISTICS_TELECOM:
    //     case FLEETX_ACCOUNTS.OM_TRANSPORT_SERVICE:
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function disableExpenseEditForExistingSelectedRoute(loggedInUser, isExistingRouteSelected) {
    const roleName = get(loggedInUser, 'role.name');
    const accountId = get(loggedInUser, 'accountId', 0);
    if (
        accountId === FLEETX_ACCOUNTS.KVTC &&
        isExistingRouteSelected &&
        roleName !== ROLE_MAP.ROLE_OWNER.name &&
        roleName !== ROLE_MAP.ROLE_ADMIN.name
    ) {
        return true;
    } else if (accountId === FLEETX_ACCOUNTS.SRV_LOGISTICS && isExistingRouteSelected) {
        return true;
    } else {
        return false;
    }
}

export function isCreateRouteDisabledToRole(loggedInUser) {
    const roleName = get(loggedInUser, 'role.name');
    if (roleName === ROLE_MAP.ROLE_OWNER.name || roleName === ROLE_MAP.ROLE_ADMIN.name) return false;
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KVTC:
            return true;
        default:
            return false;
    }
}

export function isEPODEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('isEPODEnabled');
    //return get(loggedInUser, 'epodEnabled', false);
}

export function isTotalDurationInHistoryVisible() {
    return getFlagValueFromAccountConfig('isTotalDurationInHistoryVisible');
}

export function getRealtimeTriggerTime(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SINGAL:
            return 45;
        case 9107:
        case FLEETX_ACCOUNTS.AJAY_CAR_RENTAL:
        case 13597:
        case 12215:
            return 5;
        case FLEETX_ACCOUNTS.DENEB:
            return 20;
        case FLEETX_ACCOUNTS.TEJAS_CARGO:
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return 10;
        default:
            return REAL_TIME_TIMER_TRIGGER_SECONDS_MULTI_VEHICLES;
    }
}

export function getTimelineTriggerTime(loggedInUser) {
    return TIMELINE_TIMER_TRIGGER_SECONDS;
}

export function restrictAddressBookAndRouteCreationForExpressRoadwaysUser(loggedInUser) {
    const toUserIds = [9858, 7002, 12672, 6344, 7415]; //allow action to these user only
    return isExpressRoadwaysAccount(loggedInUser) && !includes(toUserIds, get(loggedInUser, 'id'));
}

/**
 * Short description : Disallowed, Showing EndTracking and ExtendDateOption Button in `Sensors & Tracking` Section inside Vehicle page
 * @param {object} loggedInUser helps in user based checks inside.
 * @return {boolean} returned True/False based on the user is Allowed or Disallowed to see both the buttons
 */
export function restrictEndTrackingAndExtendDateOptionForUsers(loggedInUser) {
    const toUserIds = [152536];
    return !includes(toUserIds, get(loggedInUser, 'id'));
}

export function isJobProjectsFeatureEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('isJobProjectsFeatureEnabled');
    //return isBafnaAccount(loggedInUser);
}

export function isNewJobFormEnabled(loggedInUser, showNewJobFormDefault) {
    return true;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.JUPITER:
    //     case FLEETX_ACCOUNTS.RCI:
    //     case FLEETX_ACCOUNTS.HARGUN:
    //     case FLEETX_ACCOUNTS.CLASSIC_TRANS:
    //     case FLEETX_ACCOUNTS.SRV_LOGISTICS:
    //     case FLEETX_ACCOUNTS.SHIVANI:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //     case FLEETX_ACCOUNTS.SHUBHAM_CARGO:
    //     case FLEETX_ACCOUNTS.DEV_TRANSPORT:
    //     case FLEETX_ACCOUNTS.GIRISH_TRANSPORT:
    //     case FLEETX_ACCOUNTS.RAMDEO_REFINERY:
    //     case FLEETX_ACCOUNTS.PROWAY:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isNewRouteFormEnabled(loggedInUser) {
    return true;
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return true;
    // }
}

export function isDefaultNewJobFormForAccount(loggedInUser, showNewJobFormByDefault) {
    if (showNewJobFormByDefault) {
        return showNewJobFormByDefault;
    }
    if (+get(loggedInUser, 'accountId', 0) > 2000) {
        return true;
    }
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JUPITER:
        case FLEETX_ACCOUNTS.RCI:
        case FLEETX_ACCOUNTS.HARGUN:
        case FLEETX_ACCOUNTS.CLASSIC_TRANS:
        case FLEETX_ACCOUNTS.SRV_LOGISTICS:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.PDH_LOGISTICS:
        case FLEETX_ACCOUNTS.BLR_LOGISTICS:
        case FLEETX_ACCOUNTS.BVC:
        case FLEETX_ACCOUNTS.NSB_TRANSPORT:
        case FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER:
        case FLEETX_ACCOUNTS.SHIVANI:
        case 1400:
            return true;
        default:
            return false;
    }
}

export function showTagsHierarchyFilter(loggedInUser) {
    return getFlagValueFromAccountConfig('showTagsHierarchyFilter');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isDriverVerificationEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('isDriverVerificationEnabled');
    //return isGujaratCementAccount(loggedInUser) || isFleetxAccount(loggedInUser);
}

export function isVehicleNameColorBasedOnStatus(loggedInUser, accountId = null, isShareLink = false) {
    if (!isShareLink) {
        return getFlagValueFromAccountConfig('isVehicleNameColorBasedOnStatus');
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return true;
    } else {
        return false;
    }
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isHorizontalReportEnabled() {
    return getFlagValueFromAccountConfig('isHorizontalReportEnabled');
}

export function showTagNameInsteadOfJointNameForAcnt(loggedInUser) {
    return getFlagValueFromAccountConfig('showTagNameInsteadOfJointNameForAcnt');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showRealTimePageAtTransporterSide() {
    return getFlagValueFromAccountConfig('allowRealTimeAtTransporterSide');
}

export function appendCustomJobsOnVehicleRealtime(loggedInUser) {
    return getFlagValueFromAccountConfig('appendCustomJobsOnVehicleRealtime');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isMapSingleVehicleOnly() {
    return (
        getFlagValueFromAccountConfig('isMapSingleVehicleOnly') &&
        !includes([1166778], get(window.FLEETX_LOGGED_IN_USER, 'id'))
    );
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getHierarchyArray(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
        case FLEETX_ACCOUNTS.FLEETX:
            return HIERARCHY_ARRAY_IN_ORDER;
        default:
            return false;
    }
}

export function showLatLongOnMarker() {
    return getFlagValueFromAccountConfig('showLatLongOnMarker');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showTagIdsToAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('showTagIdsToAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getEntityTypeTag(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'VENDOR';
        default:
            return false;
    }
}

export function showDeviceIdOnMarker(loggedInUser) {
    return getFlagValueFromAccountConfig('showDeviceIdOnMarker');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showCustomFieldsOnMarker(loggedInUser, accountId, isShareLink) {
    if (!isShareLink) {
        return getFlagValueFromAccountConfig('showCustomFieldsOnMarker');
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return true;
    } else {
        return false;
    }
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export const getCustomFieldsToShowOnMarker = (loggedInUser, accountId, isSharedLink = false) => {
    if (!isSharedLink) {
        switch (get(loggedInUser, 'accountId', 0)) {
            case FLEETX_ACCOUNTS.RMC:
                return RMC_CUSTOM_FIELDS_LIST_ON_MARKER;
            case FLEETX_ACCOUNTS.FLEETX:
                return RMC_CUSTOM_FIELDS_LIST_ON_MARKER;
            default:
                return false;
        }
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return RMC_CUSTOM_FIELDS_LIST_ON_MARKER;
    } else {
        return false;
    }
};

export const showOtherFieldsOnMarker = (loggedInUser, accountId, isShareLink) => {
    if (!isShareLink) {
        return getFlagValueFromAccountConfig('showOtherFieldsOnMarker');
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return true;
    } else {
        return false;
    }
};
export const showJobConsignorOnMarker = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
};
export const showJobStartDate = (loggedInUser, accountId, isShareLink) => {
    if (!isShareLink) {
        return getFlagValueFromAccountConfig('showJobStartDate');
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return true;
    } else {
        return false;
    }
};

export function canOnJobVehicleCreateJob(loggedInUser) {
    return getFlagValueFromAccountConfig('canOnJobVehicleCreateJob');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function isAddressBookOnlyStopAtoBTrip(loggedInUser) {
    return getFlagValueFromAccountConfig('isAddressBookOnlyStopAtoBTrip');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.NUTAN_RAJUMANI:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function getColumnNameForHorizontalReportKM(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DTDC:
            return 'DTDC KM';
        default:
            return 'Total Odometer KM';
    }
}

export function useVehicleTypeValueForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('useVehicleTypeValueForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return null;
    // }
}

export function showRakeIconForAccount() {
    return getFlagValueFromAccountConfig('showRakeIconForAccount');
}

export function getJobNameKeyForAcnt(loggedInUser, accountId, isShareLink) {
    const account = get(loggedInUser, 'accountId', accountId);
    switch (account) {
        case FLEETX_ACCOUNTS.RMC:
            return 'Ticket No';
        default:
            return null;
    }
}

export function showJobNameKeyForAcnt(loggedInUser, accountId, isShareLink) {
    if (!isShareLink) {
        return getFlagValueFromAccountConfig('showJobNameKeyForAcnt');
    } else if (accountId === FLEETX_ACCOUNTS.RMC) {
        return true;
    } else {
        return false;
    }
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isColCustomOrderingForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isColCustomOrderingForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getHaltLimitForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
            return 2;
        case FLEETX_ACCOUNTS.CHANDERIA:
            return 10;
        default:
            return 2;
    }
}

export function showTATCompareColumnsForDiff(loggedInUser) {
    return getFlagValueFromAccountConfig('showTATCompareColumnsForDiff');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.BIRLA_DURGAPUR:
    //         return false;
    //     case FLEETX_ACCOUNTS.CHANDERIA:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isShowColumnsToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KALS:
            return true;
        default:
            return false;
    }
}

export function isTotalStoppageColumnActiveAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
            return 1;
        default:
            return 0;
    }
}

export function isAllowedAddressPointColumnForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
            return 1;
        default:
            return 0;
    }
}

export function realtimeNodeReportTypeForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return 'REALTIME_RMC';
        default:
            return 'REALTIME';
    }
}

export function isCustomRelatimeReport(loggedInUser) {
    return getFlagValueFromAccountConfig('isCustomRelatimeReport');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isIdleToolTipAvailableForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return 'blue color when idle<10 mins, orange color when idle>10 mins';
        default:
            return false;
    }
}

export function isUIJobReportOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isUIJobReportOpen');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function enableFuelLevelIgnitionForAccount() {
    return getFlagValueFromAccountConfig('enableFuelLevelIgnitionForAccount');
}

export function isFuelReportOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isFuelReportOpen');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function isTemperatureReportOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isTemperatureReportOpen');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function isLiveFuelRateOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isLiveFuelRateOpen');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return false;
    //     default:
    //         return true;
    // }
}

export function isUIAssetDataOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isUIAssetDataOpen');
}

export function isVehicleTypeSummaryAvailable(loggedInUser) {
    return getFlagValueFromAccountConfig('isVehicleTypeSummaryAvailable');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isPlantDirectionSummaryAvailable(loggedInUser) {
    return getFlagValueFromAccountConfig('isPlantDirectionSummaryAvailable');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.PARTHASARATHY:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isMultiJobAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isMultiJobAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.GUJARAT_LOGISTICS:
    //     case FLEETX_ACCOUNTS.YOLO:
    //     case FLEETX_ACCOUNTS.ACTIVE_CARGO:
    //         return true;
    //     default:
    //         return false;
    // }
}

/**
 *
 * @param loggedInUser
 * @returns {number}  minutes
 */
export function getParkedVehicleDetailedTimeForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ABR_LOGISTICS:
        case FLEETX_ACCOUNTS.ABR_ROADLINES:
            return 29.5;
        case FLEETX_ACCOUNTS.KAPOOR_DIESELS:
            return 2 * 24 * 60 + 0.5; //parked greater than 2 hours
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return 4 * 60 + 0.5; // 4 hours
        case FLEETX_ACCOUNTS.ZYNGO:
            return 7 * 24 * 60 + 0.5; //parked greater than 7  days
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 0.5 * 60; //parked less than 0.5 hours
        case FLEETX_ACCOUNTS.BISLERI:
            return 120 + 0.05; //parked greater than 2  hours
        case 3916:
            return 4 * 60; //parked greater than 4 hours
        default:
            return 14.5;
    }
}

/**
 *
 * @param loggedInUser
 * @returns {number}  minutes
 */
export function getIdleVehicleDetailedTimeForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return 4 * 60 + 0.5; // 4 hours
        default:
            return 14.5;
    }
}

/**
 *
 * @returns {{lessThan: number, greaterThan: number}} in minutes
 */
export function getParkedVehicleDetailedBetweenTimeForAccount() {
    let greaterThan = 60;
    let lessThan = 120;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            greaterThan = 20; // minutes
            lessThan = 4 * 60; // minutes
            break;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            greaterThan = 30; // minutes
            lessThan = 60; // minutes
            break;
        case FLEETX_ACCOUNTS.BISLERI:
            greaterThan = 0; // minutes
            lessThan = 10; // minutes
            break;
        default:
    }

    return {
        greaterThan, // minutes
        lessThan, // minutes
    };
}

/**
 *
 * @returns {{lessThan: number, greaterThan: number}} in minutes
 */
export function getIdleVehicleDetailedBetweenTimeForAccount() {
    let greaterThan = 60;
    let lessThan = 120;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            greaterThan = 20; // minutes
            lessThan = 4 * 60; // minutes
            break;
        default:
    }

    return {
        greaterThan, // minutes
        lessThan, // minutes
    };
}

export function getRunningVehicleDetailedKmForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ICM_LOGISTICS:
            return 10;
        default:
            return 10;
    }
}

export function runningVehicleDetailedSummaryAvailable(loggedInUser) {
    const isDetailAvailable = getFlagValueFromAccountConfig('runningVehicleDetailedSummaryAvailable');
    return {
        value: isDetailAvailable && getRunningVehicleDetailedKmForAccount(loggedInUser),
        status: isDetailAvailable,
    };
}

export function parkedVehicleDetailedSummaryAvailable(loggedInUser) {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleDetailedSummaryAvailable');
    return {
        value: isDetailAvailable && getParkedVehicleDetailedTimeForAccount(loggedInUser),
        status: isDetailAvailable,
    };
}

export function idleVehicleDetailedSummaryAvailable() {
    const isDetailAvailable = getFlagValueFromAccountConfig('idleVehicleDetailedSummaryAvailable');
    return {
        value: isDetailAvailable && getIdleVehicleDetailedTimeForAccount(),
        status: isDetailAvailable,
    };
}

export function parkedVehicleGT1AndLT2Hour() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleGT1AndLT2Hour');
    return {
        greaterThan: 60,
        lessThan: 120,
        status: isDetailAvailable,
    };
}

export function parkedVehicleGT0AndLT24Hours() {
    const isShowParkedVehicleIn24h48h = getFlagValueFromAccountConfig('isShowParkedVehicleIn24h48h');
    return {
        greaterThan: 0,
        lessThan: 60 * 24,
        status: isShowParkedVehicleIn24h48h,
    };
}

export function parkedVehicleGT24AndLT48Hours() {
    const isShowParkedVehicleIn24h48h = getFlagValueFromAccountConfig('isShowParkedVehicleIn24h48h');
    return {
        greaterThan: 60 * 24,
        lessThan: 60 * 48,
        status: isShowParkedVehicleIn24h48h,
    };
}

export function parkedVehicleGT48Hours() {
    const isShowParkedVehicleIn24h48h = getFlagValueFromAccountConfig('isShowParkedVehicleIn24h48h');
    return {
        greaterThan: 60 * 48,
        status: isShowParkedVehicleIn24h48h,
    };
}

export function parkedVehicleGTXAndLTXHours() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleGT1AndLT2Hour');
    return {
        ...getParkedVehicleDetailedBetweenTimeForAccount(),
        status: isDetailAvailable,
    };
}

export function idleVehicleGTXAndLTYHours() {
    const isDetailAvailable = getFlagValueFromAccountConfig('idleVehicleGTXAndLTYHours');
    return {
        ...getIdleVehicleDetailedBetweenTimeForAccount(),
        status: isDetailAvailable,
    };
}

export function isParkedLessThanXMinutesNeeded() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
        case FLEETX_ACCOUNTS.BISLERI:
        case 3916:
            return false;
        default:
            return true;
    }
}

export function isParkedGreaterThanXMinutesNeeded() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return false;
        default:
            return true;
    }
}

export function isIdleLessThanXMinutesNeeded() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return false;
        default:
            return true;
    }
}

/**
 *
 * @returns {{lessThan: number, greaterThan: number}} in minutes
 */
export function getParkedVehicleDetailedGTX2ANDLTY2HoursForAccount() {
    let greaterThan = 120;
    let lessThan = 240;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BISLERI:
            greaterThan = 10; // minutes
            lessThan = 30; // minutes
            break;
        default:
    }

    return {
        greaterThan, // minutes
        lessThan, // minutes
    };
}

export function getLabelForParkedVehicleDetailedGTX2ANDLTY2Hours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX2ANDLTY2HoursForAccount();

    let greaterThanValue;
    let lessThanValue;
    let unit1 = '';
    let unit2 = 'hr';
    switch (accountId) {
        case FLEETX_ACCOUNTS.BISLERI:
            // unit1 = 'min';
            unit2 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan; // hr;
            break;
        default:
            greaterThanValue = greaterThan / 60; // hr ;
            lessThanValue = lessThan / 60; // hr;
    }
    return `${text}`
        .replace('X1', `${round(greaterThanValue)}${unit1}`)
        .replace('X2 hr', `${round(lessThanValue)} ${unit2}`);
}

export function parkedVehicleGT2AndLT4Hour() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleGT2AndLT4Hour');
    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX2ANDLTY2HoursForAccount();
    return {
        greaterThan,
        lessThan,
        status: isDetailAvailable,
    };
}

export function getParkedVehicleDetailedGTX3ANDLTY3HoursForAccount() {
    let greaterThan = 240;
    let lessThan = 300;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BISLERI:
            greaterThan = 30; // minutes
            lessThan = 60; // minutes
            break;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            greaterThan = 60; // minutes
            lessThan = 60 * 2; // minutes
            break;
        default:
    }

    return {
        greaterThan, // minutes
        lessThan, // minutes
    };
}

export function getLabelForParkedVehicleDetailedGTX3ANDLTY3Hours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX3ANDLTY3HoursForAccount();

    let greaterThanValue;
    let lessThanValue;
    let unit1 = '';
    let unit2 = 'hr';
    switch (accountId) {
        case FLEETX_ACCOUNTS.BISLERI:
            // unit1 = 'min';
            unit2 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan; // hr;
            break;
        default:
            greaterThanValue = greaterThan / 60; // hr ;
            lessThanValue = lessThan / 60; // hr;
    }
    return `${text}`
        .replace('X1', `${round(greaterThanValue)}${unit1}`)
        .replace('X2 hr', `${round(lessThanValue)} ${unit2}`);
}

export function parkedVehicleGT4AndLT5Hour() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleGT4AndLT5Hour');

    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX3ANDLTY3HoursForAccount();
    return {
        greaterThan,
        lessThan,
        status: isDetailAvailable,
    };
}

export function getParkedVehicleDetailedGTX4ANDLTY4HoursForAccount() {
    let greaterThan = 240;
    let lessThan = 300;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BISLERI:
            greaterThan = 60; // minutes
            lessThan = 120; // minutes
            break;
        default:
    }

    return {
        greaterThan, // minutes
        lessThan, // minutes
    };
}

export function getLabelForParkedVehicleDetailedGTX4ANDLTY4Hours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX4ANDLTY4HoursForAccount();

    let greaterThanValue;
    let lessThanValue;
    let unit1 = '';
    let unit2 = 'hr';
    switch (accountId) {
        case FLEETX_ACCOUNTS.BISLERI:
            // unit1 = 'min';
            unit2 = 'min';
            greaterThanValue = greaterThan; // min ;
            lessThanValue = lessThan; // hr;
            break;
        default:
            greaterThanValue = greaterThan / 60; // hr ;
            lessThanValue = lessThan / 60; // hr;
    }
    return `${text}`
        .replace('X1', `${round(greaterThanValue)}${unit1}`)
        .replace('X2 hr', `${round(lessThanValue)} ${unit2}`);
}

export function getLabelForParkedVehicleDetailedT5Hours(text) {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER || {};
    const { accountId } = loggedInUser;
    let newText;
    switch (accountId) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            newText = text.replace('5', '4');
            break;
        default:
            newText = text;
    }
    return newText;
}

export function parkedVehicleGTX4AndLTY4Hour() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleGTX4AndLTY4HourFlag');

    const { greaterThan, lessThan } = getParkedVehicleDetailedGTX4ANDLTY4HoursForAccount();
    return {
        greaterThan,
        lessThan,
        status: isDetailAvailable,
    };
}

export function parkedVehicleLT5Hour() {
    const isDetailAvailable = getFlagValueFromAccountConfig('parkedVehicleLT5Hour');
    return {
        greaterThan: 300,
        status: isDetailAvailable,
    };
}

export function includeUnloadingCompleteInUnloading(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
}

export function openHistoryFromRealtimeInNewTab() {
    return includes([FLEETX_ACCOUNTS.KAPOOR_DIESELS], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null))
        ? '_blank'
        : '_self';
}

export function showViewCommentsForAccount() {
    return getFlagValueFromAccountConfig('showViewCommentsForAccount');
}

export function redirectToCustomViewFromRealtimeHistory({ loggedInUser, vehicleId }) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHUBHAM_CARGO:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(3, 'days'),
                viewType: TRIP_VIEW_TYPE.TIMELINE,
            });
        case FLEETX_ACCOUNTS.ANDHRA_GUJARAT:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(6, 'days'),
                viewType: TRIP_VIEW_TYPE.LIST,
            });

        case FLEETX_ACCOUNTS.BAJAJ:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(1, 'hours'),
                viewType: TRIP_VIEW_TYPE.TIMELINE,
            });
        case FLEETX_ACCOUNTS.KAPOOR_DIESELS:
        case FLEETX_ACCOUNTS.SPEEDFOX:
        // case FLEETX_ACCOUNTS.VENTURE_SUPPLY:
        case FLEETX_ACCOUNTS.ARPL:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(24, 'hours'),
                viewType: TRIP_VIEW_TYPE.TIMELINE,
            });

        case FLEETX_ACCOUNTS.RSAI:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(1, 'days').startOf('day'),
                endDate: getMomentTime().subtract(1, 'days').endOf('day'),
                viewType: TRIP_VIEW_TYPE.TIMELINE,
            });

        case FLEETX_ACCOUNTS.SAINT_GOBAIN:
        case FLEETX_ACCOUNTS.SAINT_GOBAIN_2:
        case FLEETX_ACCOUNTS.FENESTA:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(3, 'days'),
                viewType: TRIP_VIEW_TYPE.STOPPAGE_LIST,
            });
        case FLEETX_ACCOUNTS.RAJNEESH_AWASHTI:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().subtract(1, 'days').startOf('day'),
                endDate: getMomentTime().subtract(1, 'days').endOf('day'),
                viewType: TRIP_VIEW_TYPE.ASSET_MOVEMENT,
            });

        default:
            return generateSuffix({
                vehicleId,
                startDate: getMomentTime().startOf('day'),
                viewType: TRIP_VIEW_TYPE.LIST,
            });
    }
}

export function isVehicleTypeValueEnabledOnRealtimeList(loggedInUser) {
    return getFlagValueFromAccountConfig('isVehicleTypeValueEnabledOnRealtimeList');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.RMC:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isjobPercentDownTimeOpenForAccount(loggedInUser) {
    return getFlagValueFromAccountConfig('isjobPercentDownTimeOpenForAccount');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SHREE_CEMENT:
    //     case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
    //     case FLEETX_ACCOUNTS.MP_BIRLA:
    //     case FLEETX_ACCOUNTS.CHANDERIA:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function getRateFieldForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAMTA_ROADLINES:
            return 176;
        case FLEETX_ACCOUNTS.AGARWAL_BUILDERS:
            return 206;
        case FLEETX_ACCOUNTS.RRC:
            return 285;
        default:
            return 0;
    }
}

export function isCommodityFieldOpen(loggedInUser) {
    return getFlagValueFromAccountConfig('isCommodityFieldOpen');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.MP_BIRLA:
    //     case FLEETX_ACCOUNTS.FLEETX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function preferredTimeStampFormat(loggedInUser) {
    return getFlagValueFromAccountConfig('preferredTimeStampFormat');
}

export function showAddressNameInLocationConfig(loggedInUser) {
    return getFlagValueFromAccountConfig('showAddressNameInLocationCol');
}

export function deviationKmOverivewMapingWithJobFilter(loggedInUser) {
    return getFlagValueFromAccountConfig('deviationKmOverivewMapingWithJobFilter');
}

export function isJobEndPointHorizontalReport(loggedInUser) {
    return getFlagValueFromAccountConfig('isJobEndPointHorizontalReport');
    // switch (get(loggedInUser, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.SAI_GANESH_TRAVELS:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function showPlantFilterInOverview() {
    return getFlagValueFromAccountConfig('showPlantFilterInOverview');
}

export function showAccountSpecificAlarms() {
    return getFlagValueFromAccountConfig('showAccountSpecificAlarms');
}

export function getPlantWidgetConfig(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.RMC:
            return [
                'DEVICE_STATUS',
                'JOB_STATUS',
                'UNDEPLOYED_COUNT',
                'EPOD_SUMMARY',
                'OVERALL_TAT',
                'PLANT_PERFORMANCE',
            ];
        default:
            return [];
    }
}

export function enableSpeedStratification() {
    return getFlagValueFromAccountConfig('enableSpeedStratification');
}

export function isDeviceTranpsortMapping(scope) {
    return isDeleteTransporterAllowedForUser(scope);
    // switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.MAERSK:
    //     case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
    //     case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function logShiftShown() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.TECHNICIAN:
            return true;
        default:
            return false;
    }
}

export function getWidgetList(accountId, showPlantWidgets) {
    if (showPlantWidgets) {
        return getPlantWidgetConfig(accountId);
    }
    const widgetConfig = getWidgetConfigRedux();
    let widgetList = [];
    if (!isEmpty(widgetConfig)) {
        if (!showPlantWidgets) {
            widgetConfig['OVERALL_TAT'] = false;
            widgetConfig['PLANT_PERFORMANCE'] = false;
        }
        map(widgetConfig, (widgetEnabled, key) => {
            if (widgetEnabled) {
                widgetList.push(key);
            }
        });
        return widgetList;
    }
    return widgetList;
}

function getConfigDefaultValue(key) {
    return get(settings_metadata({ rolesList: null, tags: [] }), `${key}.default`);
}

export const getReduxState = () => {
    return window?.FX_APP_STORE_GET_STATE ? window?.FX_APP_STORE_GET_STATE() : () => {};
};

export const getWidgetConfigRedux = () => {
    const reduxState = getReduxState();
    if (reduxState) {
        return reduxState?.config?.widgetConfig;
    } else {
        return getInitialWidgetConfig(window.FLEETX_LOGGED_IN_USER?.scopes);
    }
};

export const getTableConfig = () => {
    const reduxState = getReduxState();
    if (reduxState) {
        return reduxState?.local?.tableConfig;
    }
    return null; // do not change it to {} several places have OR check
};

export function getFlagValueFromAccountConfig(key) {
    let config = getAccountConfig();
    const flag = get(config, `${key}`, getConfigDefaultValue(key));
    return flag;
}

export function last2DaysAnalyticsOnRealtime(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RSAI:
        case FLEETX_ACCOUNTS.RCI:
        case FLEETX_ACCOUNTS.AUTO_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function showVehicleExpensesInRoute() {
    return getFlagValueFromAccountConfig('isVehicleExpensesInRouteEnabled');
}

export function isExpressJobFormOpenToUser(loggedInUser) {
    const expressJobCreatorIds = [6344, 7002, 7415, 9858, 12672, 24920, 25641, 35955, 107576, 247727, 247735];
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return loggedInUser && includes(expressJobCreatorIds, get(loggedInUser, 'id'));
        case 5887:
            return true;
        default:
            return false;
    }
}

export function isRouteNameShownOnJob(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.START_CEMENT:
        case 14361:
            return true;
        default:
            return false;
    }
}

export const showDeviceIdOnShare = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
};
export const capitalizeFleetType = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.RMC:
            return false;
        default:
            return true;
    }
};
export const isVehicleTypeEnabledOnShare = (accountId) => {
    switch (accountId) {
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
};
export const isQuadTreeSearchOpenForAccount = (loggedInUser) => {
    return !isBulkNearestAddressAPIEnabled() && !includes([FLEETX_ACCOUNTS.ULPH], getAccountId());
};

export const getNearestAddressRadiusRangeForAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 50;
        case FLEETX_ACCOUNTS.VARUN_BEVERAGES:
            return 1;
    }
};

export const isBulkNearestAddressAPIEnabled = () => {
    return getFlagValueFromAccountConfig('isBulkNearestAddressAPIEnabled');
};

export const getVehicleListSizeForAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ADITYA_CEMENT:
        case FLEETX_ACCOUNTS.MP_BIRLA:
        case FLEETX_ACCOUNTS.LOAD_SHARE:
            return 4000;
        default:
            return 2000;
    }
};
export const getNearestAddressRangeForAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.VARUN_BEVERAGES:
            return 0.01;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 1;
        default:
            return 1;
    }
};

export const getReportUIName = (loggedInUser, scopes) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return REPORT_ON_UI_NAMES(scopes);
        case FLEETX_ACCOUNTS.SRV_LOGISTICS:
        case FLEETX_ACCOUNTS.MS_FREIGHT:
        case FLEETX_ACCOUNTS.JFK:
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return REPORT_ON_UI_NAMES_CASTROL;
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER:
            return REPORT_ON_UI_NAMES_BHARATHI;
        case FLEETX_ACCOUNTS.NSL:
            return REPORT_ON_UI_NAMES_NSL;
        default:
            return [];
    }
};
export const isBVCTransactionReportOpened = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return true;
        default:
            return false;
    }
};

export const isHappayEnableForAccount = (loggedInUser) => {
    if (
        get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.SPEEDFOX &&
        (get(loggedInUser, 'role.id', 0) == 45477 ||
            get(loggedInUser, 'role.id', 0) == 45472 ||
            get(loggedInUser, 'role.id', 0) == 45473)
    ) {
        return true;
    }
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
};

export const isBpclEnableForAccount = (loggedInUser) => {
    return getFlagValueFromAccountConfig('isBpclEnableForAccount');
};

export const fuelPageV1orV2 = (loggedInUser) => {
    return getFlagValueFromAccountConfig('fuelPageV1orV2');
};

export const showEvOrFuelOrBoth = (loggedInUser) => {
    return getFlagValueFromAccountConfig('showEvOrFuelOrBoth');
};

export const getDailyUtilisationKMLimit = () => {
    return getFlagValueFromAccountConfig('dailyUtilisationKMLimit');
};

export const showPartStockV1orV2 = (loggedInUser) => {
    // if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) > 10000) {
    //     return 'new';
    // }
    return 'new';
    return getFlagValueFromAccountConfig('showPartStockV1orV2');
};

export const showLockWithoutDevice = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.HGR:
            return true;
        default:
            return false;
    }
};

export const showInternalOganisationIssuesToggleForAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
};

export const showGearAndRPMForAccount = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);

    switch (accountId) {
        case FLEETX_ACCOUNTS.VARUNA:
            return true;
    }
};

export const isJobStoppageReportOpenToAccount = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
        case FLEETX_ACCOUNTS.ULTRATECH_ANDHRA:
        case FLEETX_ACCOUNTS.SHREE_BAJRANG:
        case FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS:
        case FLEETX_ACCOUNTS.ADITYA_CEMENT:
        case FLEETX_ACCOUNTS.VIKRAM_CEMENT:
        case FLEETX_ACCOUNTS.GRASIM_INBOUND:
        case FLEETX_ACCOUNTS.GRASIM_INDUSTRY:
        case FLEETX_ACCOUNTS.GRASIM_INDUSTRY_2:
        case FLEETX_ACCOUNTS.SIDHI_CEMENT:
        case FLEETX_ACCOUNTS.BELA_CEMENT:
        case FLEETX_ACCOUNTS.MAIHAR_CEMENT:
        case FLEETX_ACCOUNTS.DALLA_CEMENT:
        case FLEETX_ACCOUNTS.BALA_JI_CEMENT:
        case FLEETX_ACCOUNTS.JK_CEMENT:
        case FLEETX_ACCOUNTS.BELA_TO_SIDHI:
        case FLEETX_ACCOUNTS.DALMIA:
            return true;
        default:
            return getFlagValueFromAccountConfig('isJobStoppageReportEnabled');
    }
};

export function jobCustomFieldsForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SUNFLAG:
            return {
                'SHIP_TO_PARTY_CODE': 447,
                'ZONE': 448,
                'SOLD_TO_PARTY_CODE': 449,
                'SOLD_TO_PARTY_NAME': 450,
                'SOLD_TO_PARTY_EMAIL': 451,
                'SHIP_TO_PARTY_EMAIL': 452,
                'PHONE_TYPE': 747,
            };
        default:
            return {};
    }
}

export function showCustomiseJobTableOption(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SUNFLAG:
            return false;
        default:
            return true;
    }
}

export function getJobReportColMappingForAccount(loggedInUser) {
    return includes(
        [
            // FLEETX_ACCOUNTS.KOTPUTLI,
            FLEETX_ACCOUNTS.JK_CEMENT,
            FLEETX_ACCOUNTS.JKLC,
            FLEETX_ACCOUNTS.KALS,
            FLEETX_ACCOUNTS.SUNFLAG,
        ],
        get(loggedInUser, 'accountId', 0)
    );
}

export function isCustomGroupConfigEnabledForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return true;
        default:
            return false;
    }
}

export function getGroupBasedJobReportItems(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return {
                'arrivalTimeOrigin': {
                    label: 'Plant Entry Time',
                },
                'departureTimeOrigin': {
                    label: 'Plant Exit Time',
                },
                'startDate': {
                    label: 'Job Start Time',
                },
                'plantTAT': {
                    label: 'Plant TAT',
                },
                'transporter': {
                    label: 'Transporter',
                },
                'arrivalTimeDestination': {
                    label: 'Destination entry time',
                },
                'departureTimeDestination': {
                    label: 'Destination exit time (Delivery time)',
                },
                'tatDestination': {
                    label: 'Destination TAT',
                },
                'returnTripTAT': {
                    label: 'Return trip TAT',
                },
                'roundTripTAT': {
                    label: 'Round trip TAT',
                },
            };
        default:
            return null;
    }
}

export const ACCOUNT_FOR_CHANDERIA_LIKE = [FLEETX_ACCOUNTS.CHANDERIA, 7571, 7572, 7573, 7575, 8199, 914, 15017];

export function accountsForChanderia1505LikeJobSummaryReport() {
    return includes(ACCOUNT_FOR_CHANDERIA_LIKE, get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showJobBookingInConsignment() {
    return getFlagValueFromAccountConfig('showJobBookingInConsignment');
}

export function jobSummaryExtraColsAllowed(loggedInUser) {
    return (
        accountsForChanderia1505LikeJobSummaryReport() ||
        includes(isShreeCementsAccounts, get(loggedInUser, 'accountId')) ||
        get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.BIRLA_DURGAPUR ||
        get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.MP_BIRLA_BUTIBORI
    );
}

export function isShreeCementOrBirla(loggedInUser) {
    return (
        includes(isShreeCementsAccounts, get(loggedInUser, 'accountId')) ||
        get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.BIRLA_DURGAPUR
    );
}

export function isJobReportSelectionEnabled(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return true;
        default:
            return false;
    }
}

export function showLogoInReport() {
    return getFlagValueFromAccountConfig('showLogoInReport');
}

export function showReportMetadata() {
    return getFlagValueFromAccountConfig('showMetadataInReport');
}

export function isAutoGenerateJobNameEnabled() {
    return getFlagValueFromAccountConfig('autoGenerateJobNameEnabled');
}

export function isAutoGenerateJobBookingNameEnabled() {
    return getFlagValueFromAccountConfig('autoGenerateJobBookingNameEnabled');
}

export function isBarcodeReportEnabled() {
    return getFlagValueFromAccountConfig('isBarcodeReportEnabled');
}

export function isLossKpiReportEnable() {
    return getFlagValueFromAccountConfig('isLossKpiReportEnable');
}

export function isJobTollReportEnable() {
    return getFlagValueFromAccountConfig('isJobTollReportEnable');
}

export function isMilkRunReportEnabled() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GROFERS:
            return true;
        default:
            return false;
    }
}

export function isEmptyJobKMReportEnabled() {
    return getFlagValueFromAccountConfig('isEmptyJobKMReportEnabled');
}

export function isShareRealtimeVehicleLocationByUploaderEnabled() {
    return getFlagValueFromAccountConfig('isShareRealtimeVehicleLocationByUploaderEnabled');
}

export function getTimeZoneForAccount(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.AERIS:
        case FLEETX_ACCOUNTS.AERIS2:
        case FLEETX_ACCOUNTS.AERIS3:
        case FLEETX_ACCOUNTS.AERIS4:
        case FLEETX_ACCOUNTS.AERIS5:
        case FLEETX_ACCOUNTS.CHAKRA_JAWARA:
        case FLEETX_ACCOUNTS.PT_USI:
        case FLEETX_ACCOUNTS.APS_DIGITAL:
        case FLEETX_ACCOUNTS.HINO:
        case FLEETX_ACCOUNTS.WARESIX:
        case 10605:
            return get(countryToDetailsMap[COUNTRIES.INDONESIA], 'timeZone');
        case FLEETX_ACCOUNTS.TECHNOPATH:
            return get(countryToDetailsMap[COUNTRIES.PHILIPPINES], 'timeZone');
        case FLEETX_ACCOUNTS.HELLO_TRACTOR_KENYA:
        case FLEETX_ACCOUNTS.SAFERON_KENYA:
            return get(countryToDetailsMap[COUNTRIES.KENYA], 'timeZone');
        case FLEETX_ACCOUNTS.SHARJAH_TRANPSORT:
        case FLEETX_ACCOUNTS.ATS_LOGISTICS:
        case FLEETX_ACCOUNTS.PROTOCOL_SYSTEM:
        case FLEETX_ACCOUNTS.RIGHT_CHOICE:
        case FLEETX_ACCOUNTS.ATS_UAE:
            return get(countryToDetailsMap[COUNTRIES.UAE], 'timeZone');
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
        case FLEETX_ACCOUNTS.GIA_TANZANIA:
        case FLEETX_ACCOUNTS.FLYING_CARGO:
        case FLEETX_ACCOUNTS.BEST_OCEAN:
            return get(countryToDetailsMap[COUNTRIES.TANZANIA], 'timeZone');
        case FLEETX_ACCOUNTS.FABHOUSE_BOTSWANA_AFRICA:
        case FLEETX_ACCOUNTS.TIGER_AUTO:
            return get(countryToDetailsMap[COUNTRIES.BOTSWANA], 'timeZone');
        case FLEETX_ACCOUNTS.EURIST:
        case FLEETX_ACCOUNTS.FABIO_BIKES:
            return get(countryToDetailsMap[COUNTRIES.UGANDA], 'timeZone');
        case FLEETX_ACCOUNTS.JOVITRONIX:
            return get(countryToDetailsMap[COUNTRIES.EGYPT], 'timeZone');
        case FLEETX_ACCOUNTS.CITY_FRONTIERS:
        case FLEETX_ACCOUNTS.GFS_ZAMBIA:
            return get(countryToDetailsMap[COUNTRIES.ZAMBIA], 'timeZone');
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
            return get(countryToDetailsMap[COUNTRIES.BANGLADESH], 'timeZone');
        default:
            return defaultTimeZone;
    }
}

export function isReportLiveViewEnabled() {
    return getFlagValueFromAccountConfig('isReportLiveViewEnabled') || isJindalCustomJobReportEnabledFlag();
}

export function isUploadRfidOrBarcodeEnabledForUser(loggedInUser) {
    return includes([26728, 13288, 24620, 30169, 153, 135], get(loggedInUser, 'id'));
}

export function getVehicleListSize() {
    return getFlagValueFromAccountConfig('vehicleListSize');
}

export function getVehicleListSizeForDayWiseReport() {
    return getFlagValueFromAccountConfig('vehicleListSizeForDayWiseReport');
}

export function isBulkTripBWSitesEnabled() {
    return getFlagValueFromAccountConfig('isBulkTripBWSitesEnabled');
}

export function checkForNearestAddressMismatch() {
    return getFlagValueFromAccountConfig('checkForNearestAddressMismatch');
}

export function isUnrestrictedCalendar() {
    return true;
    //return getFlagValueFromAccountConfig('unrestrictedCalendar');
}

export function loginAllowedToRole(roleName) {
    switch (roleName) {
        case ROLE_MAP.ROLE_DRIVER.name:
        case ROLE_MAP.ROLE_TECHNICIAN_MASTER.name:
            return true;
        default:
            return false;
    }
}

export function getLrReportFromCarbone(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAHADEV_CARRIER:
        case FLEETX_ACCOUNTS.DAULAT:
            return false;
        default:
            return true;
    }
}

export function getCustomInvoiceReportFromCarbone(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ALGOR:
        case FLEETX_ACCOUNTS.MANVILLE:
            return true;
        default:
            return false;
    }
}

export function getTemplateType(loggedInUser, invoiceType = '') {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BLT_LOGISTICS:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return TEMPLATE_TYPE.LR;
        default:
            return TEMPLATE_TYPE.LR;
    }
}

export function getInvoiceTemplateType(loggedInUser, invoiceType = '') {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ALGOR:
            return TEMPLATE_TYPE.INVOICE;
        case FLEETX_ACCOUNTS.MANVILLE:
            if (invoiceType === 'FREIGHT_INVOICE') {
                return TEMPLATE_TYPE.AUTO_INVOICE_FORMAT;
            } else if (invoiceType === 'DETENTION_INVOICE') {
                return TEMPLATE_TYPE.DETENTION_INVOICE_FORMAT;
            } else {
                return TEMPLATE_TYPE.REIMBURSEMENT_INVOICE_FORMAT;
            }
        default:
            return null;
    }
}

export function isInifarmsAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.INIFARMS;
}

export function isVehicleDetailEnableForAccount(loggedInUser) {
    return includes(
        [
            FLEETX_ACCOUNTS.MAERSK,
            FLEETX_ACCOUNTS.MAERSK_BANGLADESH,
            FLEETX_ACCOUNTS.MAERSK_SRI_LANKA,
            FLEETX_ACCOUNTS.FLEETX,
        ],
        get(loggedInUser, 'accountId')
    );
}

export function isShowSimTrackingInfoInRealtimeEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('showSimTrackingInfoInRealtime');
    //return loggedInUser && includes([FLEETX_ACCOUNTS.FENESTA], loggedInUser.accountId);
}

export function isOnDemandAddressFetchForMarkerEnabled() {
    return getFlagValueFromAccountConfig('onDemandAddressFetchForMarker');
}

export function isShowReturnPolylineInDifferentColorWhenABAJob() {
    return getFlagValueFromAccountConfig('showReturnPolylineInDifferentColorWhenABAJob');
}

export function isJobNameNotShowingToUser(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return false;
        default:
            return true;
    }
}

export function isShowIgoneVehicleSection(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return false;
        default:
            return true;
    }
}

export function isFenestaAccount(loggedInUser) {
    return loggedInUser && loggedInUser.accountId === FLEETX_ACCOUNTS.FENESTA;
}

export function isShowSecondaryEngineAvailable(loggedInUser) {
    return getFlagValueFromAccountConfig('showConstructionMachineryDetails');
}

export function isJobSourceColumnShown(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return true;
        default:
            return false;
    }
}

export function showVehicleRunTimeSlotWiseToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.TATA_STEEL_BSL:
            return true;
        default:
            return false;
    }
}

export function showTimeInGeofence(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ADANI_REPORT:
        case FLEETX_ACCOUNTS.ADANI_TUMB:
        case FLEETX_ACCOUNTS.SHREEMAN_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function showVehicleTagsInAlarmReport(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ADANI_REPORT:
        case FLEETX_ACCOUNTS.DALMIA:
            return true;
        default:
            return false;
    }
}

export function showCurrentLocationCategoryFilter(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ADANI_REPORT:
            return true;
        default:
            return getFlagValueFromAccountConfig('showCurrAddressBookCategoriesFilterOnRealtime');
    }
}

export function isJobDistanceColumnShown(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.START_CEMENT:
            return true;
        default:
            return false;
    }
}

export function isLatitudeColumnShown(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CASBY_LOGISTIC:
        case FLEETX_ACCOUNTS.KM_TRANS:
            return true;
        default:
            return false;
    }
}

export function isLongitudeColumnShown(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CASBY_LOGISTIC:
        case FLEETX_ACCOUNTS.KM_TRANS:
            return true;
        default:
            return false;
    }
}

export function iscurrentTimeWithDateColumnShown(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SRINIVASA:
            return true;
        default:
            return false;
    }
}

export function isBillNumberColumnShownToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.BRAHAMPUTRA:
            return true;
        default:
            return false;
    }
}

export function showPDBatteryToAcccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function getCustomRealtimeReportName(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return 'Realtime Report RMC';
        case FLEETX_ACCOUNTS.SOMDUTT:
            return 'Somdutt Realtime Report';
        default:
            return 'Custom Realtime Report';
    }
}

export function showVehicleIdInSetting(loggedInUser, alerts) {
    const alertName = get(alerts, 'name');
    if (alertName !== 'Parked') {
        return false;
    }
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CLASSIC_TRANS:
        case FLEETX_ACCOUNTS.VINSUM:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function sendManualEntryTrueInJobForm(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.BIGBASKET:
        case FLEETX_ACCOUNTS.FAMBO_INNOVATION:
        case FLEETX_ACCOUNTS.ENTERPRISE_DEMO_ACCOUNT:
            return true;
        default:
            return false;
    }
}

export function showAltitudeForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CEAT:
            return true;
        default:
            return false;
    }
}

export function getMinTripDateForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.ENERSHELL:
            const roleName = get(loggedInUser, 'role.name', 0);
            if (roleName !== 'ROLE_OWNER') {
                return getMomentTime().subtract(15, 'days').startOf('day');
            }
            return null;
        case 10365:
            return getMomentTime('2023-03-25T00:00:00');
        case 9654:
            return getMomentTime('2023-07-18T00:00:00');
        default:
            return null;
    }
}

export function showLastStopAddress() {
    return getFlagValueFromAccountConfig('showLastStopAddress');
}

export function openJobDetailPageInNewTab() {
    return getFlagValueFromAccountConfig('openJobDetailPageInNewTab');
}

export function checkDeleteJobAllowedForUser(isEnterprise) {
    if (!isEnterprise) {
        return true;
    }
    if (
        includes(
            [
                'arun.sardana@fleetx.io',
                'arun.sardana+1@fleetx.io',
                'pankaj.goyal@fleetx.io',
                'tabish@fleetx.io',
                'udbhav@fleetx.io',
                'abhay@fleetx.io',
                'vineet@fleetx.io',
                'shantanu@fleetx.io',
                'anshu.pm@fleetx.io',
                'dhruv.kumar@fleetx.io',
                'abhimanyu.rathore@fleetx.io',
                'purva.rai@fleetx.io',
                'ashutosh.jain@fleetx.io',
                'rahul.mahida@fleetx.io',
                'abhishek.anand@fleetx.io',
                'rupam.sharma@fleetx.io',
                'nimesh.sharma@fleetx.io',
                'sagar.chawla+5@fleetx.io',
                'devesh.sharma@fleetx.io',
                'prahlad.bordoloi@fleetx.io',
                'tej.ram+123@fleetx.io',
                'puran.gurjar+123@fleetx.io',
                'prahlad.singh@fleetx.io',
                'rishabh.ratnakar@fleetx.io',
                'anish.jaswal+6@fleetx.io',
            ],
            toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''))
        )
    ) {
        return true;
    }
    return false;
}

export function salesDemoUser() {
    if (
        includes(
            [
                'rituraj@fleetx.io',
                'minhaj@fleetx.io',
                'venkatesh@fleetx.io',
                'amit.priyadarshi@fleetx.io',
                'manish.dutta@fleetx.io',
                'dheeraj@fleetx.io',
                'ramesh.sattu@fleetx.io',
                'mohit.pandey@fleetx.io',
                'ketav.shah@fleetx.io',
                'shaikh.faraaz@fleetx.io',
                'vikram.badugu@fleetx.io',
                'shibashis.b@fleetx.io',
                'ashish.gautam@fleetx.io',
                'arpit.srivastava@fleetx.io',
                'abhishek.zala@fleetx.io',
                'kapish.soni@fleetx.io',
                'nitesh.singh@fleetx.io',
                'abhishek.nayak@fleetx.io',
                'patapanchala.vinod@fleetx.io',
                'durga.senapati@fleetx.io',
                'shaik.arshad@fleetx.io',
                'abhilash.senapati@fleetx.io',
                'ravindra.tomar@fleetx.io',
                'dharamjeet.singh@fleetx.io',
                'akshat.chaudhari@fleetx.io',
                'vikas.joshi@fleetx.io',
                'saurav.chakraborty@fleetx.io',
                'anand.prabhu@fleetx.io',
                'satendra.dhukoria@fleetx.io',
                'aniket.singh@fleetx.io',
                'vikas.soni@fleetx.io',
                'parag.chauhan@fleetx.io',
                'jatin.kansal@fleetx.io',
                'prasanth.nithyananthan@fleetx.io',
                'sunny.roy@fleetx.io',
                'suresh.sangam@fleetx.io',
                'ritwiz.srivastava@fleetx.io',
                'atul.galande@fleetx.io',
                'sumit.goyal@fleetx.io',
                'vidyadharan.p@fleetx.io',
                'sushanta.kumar@fleetx.io',
                'shubham.pandey@fleetx.io',
                'karthick.subramani@fleetx.io',
                'aseem.borgohain@fleetx.io',
                'vishwas.ks@fleetx.io',
                'siddharth.thakar@fleetx.io',
                'biswajit.sahoo@fleetx.io',
                'raja.shekhar@fleetx.io',
                'kishore.kumar@fleetx.io',
                'indrajith.g@fleetx.io',
                'manikandan.k@fleetx.io',
                'jeyaprakash.sundaram@fleetx.io',
                'mohammed.lateef@fleetx.io',
                'vinoth.k@fleetx.io',
                'anirban.mitra@fleetx.io',
                'mangesh.khandagale@fleetx.io',
                'krunal.galchar@fleetx.io',
                'rishabh.katiyar@fleetx.io',
                'raahul.bn@fleetx.io',
                'ganesh.negi@fleetx.io',
                'arun.jondhale@fleetx.io',
                'raunak.roy@fleetx.io',
                'manoj.patil@fleetx.io',
                'rabi.shaw@fleetx.io',
                'ramesh.r@fleetx.io',
                'dileep.kandimalla@fleetx.io',
                'anil.keshavan@fleetx.io',
                'palash.rai@fleetx.io',
                'anurenj.kulangaroth@fleetx.io',
                'manas.balsora@fleetx.io',
                'r.gurusamy@fleetx.io',
                'naresh.kantamaneni@fleetx.io',
                'ashok.k@fleetx.io',
                'kanneboina.nagaraju@fleetx.io',
                'subhodeep.bhattacharya@fleetx.io',
                'ankit.kumar@fleetx.io',
                'divyansh.arora@fleetx.io',
                'irshad.K@fleetx.io',
                'karthikeya.ud@fleetx.io',
                'akash.sisodia@fleetx.io',
                'abhishek.sahani@fleetx.io',
                'nilesh.singh@fleetx.io',
                'chandra.pal@fleetx.io',
                'abhishek.gowda@fleetx.io',
                'ravinder.singh@fleetx.io',
                'indradeep.wasu@fleetx.io',
                'vishal.kanani@fleetx.io',
                'Aaulya.cally@fleetx.io',
                'rahul.mehra@fleetx.io',
                'sumit.kumar@fleetx.i',
            ],
            toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''))
        )
    ) {
        return true;
    }
    return false;
}

export function checkVehicleMakeModelEditAllowedForUser() {
    if (
        includes(
            [
                'tejveer@fleetx.io',
                'arun.patiyal@fleetx.io',
                'akhilendra.singh@fleetx.io',
                'vijay.mishra@fleetx.io',
                'lokesh@fleetx.io',
                'atulya.cally@fleetx.io',
                'akash.aggarwal@fleetx.io',
                'archit.vyas@fleetx.io',
                'tabish@fleetx.io',
                'aman@fleetx.io',
            ],
            toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''))
        )
    ) {
        return true;
    }
    return false;
}

export function checkIfUserIsAllowedToUploadImage() {
    if (
        includes(
            [
                'alkesh.raj@fleetx.io',
                'annu.singh@fleetx.io',
                'devender@fleetx.io',
                'pawan@fleetx.io',
                'vince@fleetx.io',
                'abhishek.anand@fleetx.io',
                'pawan.tanwar@fleetx.io',
                'tabish@fleetx.io',
                'himanshu.gupta@fleetx.io',
                'amit.kumar1@fleetx.io',
                'lokesh.kumar@fleetx.io',
                'akash.aggarwal@fleetx.io',
                'saket.verma@fleetx.io',
                'archit.vyas@fleetx.io',
                'aman.thapliyal@fleetx.io',
                'charan.bhavisetti@fleetx.io',
                'satish@fleetx.io',
                'satish.kumar@fleetx.io',
                'lokesh@fleetx.io',
                'sakir.khan@fleetx.io',
                'kishan.singh@fleetx.io',
                'faiyaz.alam@fleetx.io',
            ],
            toLower(get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId', ''))
        )
    ) {
        return true;
    }
    return false;
}

export const getValidatorFromReferrer = (validator) => {
    switch (validator) {
        case ALLOWED_REFERRAL_DATA.SHELL:
            return 'SHELL';
        default:
            return 'FXTOKEN';
    }
};

export function showJobUnloadingPointsTab(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.PRISM_JOHNSON:
            return true;
        default:
            return false;
    }
}

export function showTimeIn24FormatForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHRI_RAMJANYA:
            return true;
        default:
            return false;
    }
}

export const isCSBoxTrackingEnabled = () => {
    return getFlagValueFromAccountConfig('openCSBoxTracking');
};
export const showChatBtnForAccount = () => {
    return getFlagValueFromAccountConfig('showChatBtnForAccount');
};

// export const showNewOverView = () => {
//     return true;
//     // return getFlagValueFromAccountConfig('showNewOverview');
// };

// export const showOldOverView = () => {
//     return true;
//     // return getFlagValueFromAccountConfig('showOldOverView');
// };
export const showUploadKilometersModalForAccount = () => {
    return getFlagValueFromAccountConfig('showUploadKilometersModal');
};

export function showBillNumbersInJobDetailsForAccount() {
    return getFlagValueFromAccountConfig('showBillNumbersInJobDetailsForAccount');
}

export function isJobUserCheckOpenToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return true;
        default:
            return false;
    }
}

export function manualInvoiceFormEditableForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JAY_AMBE_ROADLINES:
            return true;
        default:
            return false;
    }
}

export function isBulkMovementReportAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KALBURGI:
        case FLEETX_ACCOUNTS.KPML:
            return true;
        default:
            return false;
    }
}

export function isCommodityOpenToAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BIGBASKET:
        case FLEETX_ACCOUNTS.SCOOTSY_LOGISTICS:
        case FLEETX_ACCOUNTS.ADANI_COAL:
        case 11866:
            return true;
        default:
            return false;
    }
}

export function shouldGenerateDhanukaLockOtp(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SPEEDFOX:
        case FLEETX_ACCOUNTS.MEDALLION_TRANSLINKS:
            return true;
        default:
            return false;
    }
}

export function showDeviceSrNoInHorizontalJobReport(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GRASIM:
            return get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
        case FLEETX_ACCOUNTS.GROFERS:
        case FLEETX_ACCOUNTS.JK_CEMENT:
        case FLEETX_ACCOUNTS.MANGALAM:
        case FLEETX_ACCOUNTS.DHAR:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
            return true;
        default:
            return false;
    }
}

export function getHistoryReportColMappingForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SMART_GREEN:
            return smartGreenColHistoryReportMapping;
        default:
            return smartGreenColHistoryReportMapping;
    }
}

export function getJobReportConfigName(loggedInUser) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KALS:
            return 'Planned vs Actual KALS';
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return 'Consolidated Job Report';
        case FLEETX_ACCOUNTS.DAULAT:
            return 'Completed Job Report';
        default:
            return 'Job Report (Config)';
    }
}

export function getJobReportSkipConfigForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KALS:
            return true;
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        case FLEETX_ACCOUNTS.DAULAT:
            return true;
        default:
            return false;
    }
}

export function showAdditionalAlarmMarkerDetails() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KM_TRANS:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function getMaxExpriryDateForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.LNTECC:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.DBSCHENKER:
            return 30;
        case FLEETX_ACCOUNTS.VINSUM_1:
        case FLEETX_ACCOUNTS.VINSUM_AXPRESS:
        case FLEETX_ACCOUNTS.KEI_INDUSTRIES:
            return 15;
        case FLEETX_ACCOUNTS.MAHALAXMI:
            return 6;
        case FLEETX_ACCOUNTS.TRANSWAY_CARGO_LIFTERS:
            return 6 * 31; // 6 months
        default:
            return 10;
    }
}

export function addressNotToUsedForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SRINIVASA:
            return [81727];
        case FLEETX_ACCOUNTS.TATA_STEEL_KALINGNAGAR:
            return [204470];
        default:
            return [];
    }
}

export const checkTechnicianAccountRole = (loggedInUser, isSwitchedUser, switchedUserOriginalRoleId) => {
    if (
        includes([FLEETX_ACCOUNTS.TECHNICIAN, FLEETX_ACCOUNTS.FLEETX], get(loggedInUser, 'accountId')) &&
        isSwitchedUser &&
        switchedUserOriginalRoleId !== 7
    ) {
        return false;
    }
    return true;
};

export function showOvertimeInDayWiseReport(loggedInUser) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BLR_LOGISTICS:
        case FLEETX_ACCOUNTS.TAZ_TRANSPORT:
            return true;
        default:
            return false;
    }
}

export function isScheduledDepartureDestinationOpenForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CAVIN_CARE:
        case FLEETX_ACCOUNTS.OM_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function isRouteNameToggleOpenForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CAVIN_CARE:
        case FLEETX_ACCOUNTS.INTERCITY_CARRIERS:
            return true;
        default:
            return false;
    }
}

export function isByDefaultShowTrueForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SYNERGY:
            return true;
        default:
            return false;
    }
}

export function isByDefaultShowFalseForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GRASIM:
            return false;
        default:
            return true;
    }
}

export function isScheduledArrivalDestinationOpenForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.CAVIN_CARE:
        case FLEETX_ACCOUNTS.OM_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function looseCheckPhoneLength() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FABHOUSE_BOTSWANA_AFRICA:
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
        case FLEETX_ACCOUNTS.GIA_TANZANIA:
        case FLEETX_ACCOUNTS.TIGER_AUTO:
        case FLEETX_ACCOUNTS.ATS_UAE:
        case FLEETX_ACCOUNTS.PORTMAN:
            return true;
        default:
            return !!isInternationalAccount();
    }
}

export function isMultipleDeviceAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isMontlhySummaryReportOpen() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.IRC_1:
            return true;
        default:
            return false;
    }
}

export function isDownloadAllVehicleFuelReportOpen() {
    return true;
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KMR_ROADLINES:
            return true;
        default:
            return false;
    }
}

export function isFuelReportEnableForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.LALITHA_ENTERPISE:
        case FLEETX_ACCOUNTS.RAJ_CORPORATION:
        case FLEETX_ACCOUNTS.RMC:
        case FLEETX_ACCOUNTS.E2E_SUPPLY_CHAIN:
        case FLEETX_ACCOUNTS.SATVA_HITECH:
        case FLEETX_ACCOUNTS.DTC:
            return true;
        default:
            return false;
    }
}

export function isAdvanceFuelReportEnableForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.LOGMAN:
            return true;
        default:
            return false;
    }
}

export function isTagReportOpen() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
}

export function isTagTabOpen() {
    return true;
    // switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
    //     case FLEETX_ACCOUNTS.LTC_LOGISTICS:
    //     case FLEETX_ACCOUNTS.RCCPL:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function isOrderVehicleBySelectedTagHistoryInRealtimeEnabled() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
}

export function isMISReportEnabled() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.AGP:
            return true;
        default:
            return false;
    }
}

export function isShowOdometerColumn() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.RASHMI:
            return false;
        default:
            return true;
    }
}

export function isFuelVendorEnableForTheAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.DEMO_ACCOUNT:
        case FLEETX_ACCOUNTS.GOOD_WILL_TRANS:
        case FLEETX_ACCOUNTS.SRINIVASA:
        case FLEETX_ACCOUNTS.BISLERI:
        case FLEETX_ACCOUNTS.MEDALLION_TRANSLINKS:
            return true;
        default:
            return false;
    }
}

export function isDriverLicenseFieldMandatory() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.LAKE_TRANS:
            return true;
        default:
            return false;
    }
}

export function isShowGroup() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return true;
        default:
            return false;
    }
}

export function getMinWorkOrderStartDate() {
    if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.SHAH_COAL) {
        return getMomentTime();
    }
}

export function dontShowTripListIfNoVehicleSelected() {
    return getFlagValueFromAccountConfig('skipTripListIfNoVehicleSelected');
}

export function isTotalKmMapOpenToClient() {
    return getFlagValueFromAccountConfig('isTotalKMMapOpenToClient');
}

export function isEPODListingEnabledForAccount() {
    return getFlagValueFromAccountConfig('isEPODListingEnabledForAccount');
}

export function isOriginEPODAccount() {
    return getFlagValueFromAccountConfig('isOriginEPODAccount');
}

export function dontShowParkedSinceInShareUrlForAccount(accountId, isShared) {
    return isShared && includes([FLEETX_ACCOUNTS.MAHALAXMI], accountId);
}

export function showParkedVehicleListInDescByDefault() {
    return includes(
        [FLEETX_ACCOUNTS.KAPOOR_DIESELS, FLEETX_ACCOUNTS.EXPRESS_ROADWAYS],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function showVehicleNameByDefault() {
    return includes(
        [FLEETX_ACCOUNTS.MPATEL, FLEETX_ACCOUNTS.RD_ROADLINES, FLEETX_ACCOUNTS.BD_TRANSPORT],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function showCurrentLatLngInRealtimeReport() {
    return includes(
        [FLEETX_ACCOUNTS.KAPOOR_DIESELS, FLEETX_ACCOUNTS.SUMIT_ROADLINES],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function isDeviceTypeShownInRealtimeReport() {
    return isMaerskAccount();
}

export function isTodaysKmOpenForAcccount() {
    return getFlagValueFromAccountConfig('isTodaysKmOpenForAcccount');
}

export function isYesterdaysKmOpenForAcccount() {
    return getFlagValueFromAccountConfig('isYesterdaysKmOpenForAcccount');
}

export function useLastStatusTimeForIdleTime() {
    return getFlagValueFromAccountConfig('useLastStatusTimeForIdleTime');
}

export function showVehicleLevelAlarm() {
    return getFlagValueFromAccountConfig('showVehicleLevelAlarm');
}

export function isSIMRequestLocationOpen() {
    return getFlagValueFromAccountConfig('isSIMRequestLocationOpen');
}

export function showGoogleMapsLinkOfJobTrips() {
    return getFlagValueFromAccountConfig('showGoogleMapsLinkOfJobTrips');
}

export function isVehicleSIMHistoryReportOpenForAccount() {
    return getFlagValueFromAccountConfig('isVehicleSIMHistoryReportOpen');
}

export function showOdometerReadingInReport() {
    return getFlagValueFromAccountConfig('showOdometerReadingInRealTimeReport');
}

export function isOverallTransitTime() {
    return getFlagValueFromAccountConfig('isOverallTransitTime');
}

export function showJobStoppageType() {
    return getFlagValueFromAccountConfig('showJobStoppageType');
}

export function filterAddressBasedOnUserTag() {
    return getFlagValueFromAccountConfig('filterAddressBasedOnUserTag');
}

export function showBookingCreatedByInForm() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return false;
        default:
            return true;
    }
}

export function isTransporterRequiredForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isSenderReceiverRequiredForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function showOnlyCustomerTypeInJobBookingForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isMoveTypeEnabledForAccountinJob() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SYNERGY_CROP:
        case FLEETX_ACCOUNTS.ORIENT_CEMENT:
            return true;
        default:
            return false;
    }
}

export function fillBookingNumWithTimeStamp() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function createIndentFromJobBooking() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isUnloadingCompleteOpen() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function getJobBookingCommodityLabelName() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Product Type';
        default:
            return 'Commodity';
    }
}

export function getIndentOrderQuantityLabelName(accountId) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Cargo Weight(Ton)';
        default:
            return 'Order Quantity(Metric Ton)';
    }
}

export function getIndentPONumberLabelName(accountId, isPtlIndent = false) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (isPtlIndent) {
        return 'Goods Tax Invoice No.';
    }
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Booking Number (SO)';
        default:
            return 'SO Number';
    }
}

export function getJobBookingContainerQuantityLabelName(accountId) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return 'Vehicle Quantity';
        default:
            return 'Container Quantity';
    }
}

export function getJobBookingNumberLabelName(accountId) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Booking Number /Reference';
        default:
            return 'Booking Number';
    }
}

export function getThresholdForGPSDeviceMandatory() {
    return getFlagValueFromAccountConfig('thresholdForGPSDeviceMandatory');
}

export function isPaymentOpen() {
    return true;
    if (+get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) > 3825) {
        return true;
    }

    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHREE_SHYAM:
        case FLEETX_ACCOUNTS.POOJA_TOUR:
        case FLEETX_ACCOUNTS.IRFAN_ENTPR:
        case FLEETX_ACCOUNTS.RAISHA_TRANSPORT:
        case FLEETX_ACCOUNTS.SHIV_KRIPA:
        case FLEETX_ACCOUNTS.GODARA:
        case FLEETX_ACCOUNTS.SPEED_CARGO:
        case FLEETX_ACCOUNTS.CHAKRAVARTHY:
        case FLEETX_ACCOUNTS.SUPER_SPEED:
        case FLEETX_ACCOUNTS.NIPUN_TEMPO:
        case FLEETX_ACCOUNTS.DHARIWAL:
        case FLEETX_ACCOUNTS.VARAHI:
        case FLEETX_ACCOUNTS.AB_LOGISTIC:
        case FLEETX_ACCOUNTS.MADHAV_REDDY:
        case FLEETX_ACCOUNTS.ASHOK_ROADLINES:
        case FLEETX_ACCOUNTS.MADDI_SRINIVAS:
        case FLEETX_ACCOUNTS.MFS_BHORER:
        case FLEETX_ACCOUNTS.ASHOK_TRANSPORT:
        case FLEETX_ACCOUNTS.SRI_VINDHWASNI:
        case FLEETX_ACCOUNTS.DATAR_TRAILER:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

/**
 * @param {number} accountId helps in account based checks inside.
 * @param {string} name is unique keyword to identify the property inside initial job form state(add_edit_job_component_v2.tsx)
 * @return {any} returned value will be set to the property inside initial job form state.
 */
export function setInitialFieldValueBasedOnAccount(accountId, name) {
    if (name === 'addressBookAlert') {
        if (accountId === FLEETX_ACCOUNTS.PEARL_TRANSPORT) {
            return true;
        } else {
            return false;
        }
    }
}

export function getManualJobFormConfig(accountId, name) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.NSL:
            return NSL_CONFIG;
        case FLEETX_ACCOUNTS.VIJAY_ROADLINES:
            return VIJAY_ROADLINES_CONFIG;
        default:
            return NSL_CONFIG;
    }
}

export function isDeviceHealthEnabled() {
    return getFlagValueFromAccountConfig('showDeviceHealthTab');
}

export function isThirdPartyApiSummaryEnabled() {
    return getFlagValueFromAccountConfig('isThirdPartyApiSummaryEnabled');
}

export function getRadioNameForInternational() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Cross Border';
        default:
            return 'International';
    }
}

export function getDispatchDateLabellName() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Placement Date';
        default:
            return 'Dispatch Date';
    }
}

export function getInvoiceDatelabelName(accountId) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Booking Date';
        default:
            return 'Invoice Date';
    }
}

export function getLoadingDateLabelName(accountId) {
    const account = accountId ? accountId : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
            return 'Placement Date';
        default:
            return 'Loading Time';
    }
}

export function showVendorInJobBooking() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            return false;
        default:
            return true;
    }
}

export function showCustomFieldsInJobSummaryReport(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
            return true;
        default:
            return false;
    }
}

export function forceBoundJobMapForAccount(isDefaultVehicleViewOnMapEnabledOverWritten) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
        case FLEETX_ACCOUNTS.FLEETX:
            return !isDefaultVehicleViewOnMapEnabledOverWritten;
        default:
            return false;
    }
}

export function setIncludePastRunningDefaultFalse(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SULT:
            return false;
        default:
            return true;
    }
}

export function setByCreateDateDefaultTrue(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SULT:
            return true;
        case FLEETX_ACCOUNTS.GODREJ_JERSEY:
            return true;
        default:
            return false;
    }
}

export function getSuggestedPolylineColor() {
    return getFlagValueFromAccountConfig('suggestedPolylineColor');
}

export function getTripPolylineColor() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.PARTHA_TRANS:
            return '#009933';
        default:
            return '#40A2FF';
    }
}

export function isVivalaBeveragesAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.VIVALA_BEVERAGES;
}

export function getSIMPolylineColor() {
    return getFlagValueFromAccountConfig('SIMPolylineColor');
}

export function isCompletedJob100PercentInGraph() {
    return getFlagValueFromAccountConfig('isCompletedJob100PercentInGraph');
}

export function isInvoiceNumberShownForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.JKLC:
            return true;
        default:
            return false;
    }
}

export function enableVehicleListingV2(loggedInUser) {
    return true;
    const accountId = get(loggedInUser, 'accountId', 0);
    if (toSafeInteger(accountId) > 3000) {
        return true;
    }
    return getFlagValueFromAccountConfig('enableVehicleListNew');
}

export function enableTyreModuleV2(loggedInUser) {
    return getFlagValueFromAccountConfig('enableTyreModuleNew');
}

export function showShiftWiseReplacementDriver() {
    return getFlagValueFromAccountConfig('showShiftWiseReplacementDriver');
}

export function getJobUsersInitialValues(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.BVC:
            return [{ label: 'Gunman' }, { label: 'Escorter' }];
        default:
            return [];
    }
}

export function showConfigFlagToUser(flagConfig) {
    const { switchedUserOriginalRoleId, switchedUserOriginalId } = window.SWITCHED_USER_ORIGINAL_DATA || {};
    const { allowedForOriginalUserRoleIds, isSwitchedUserOnly } = flagConfig || {};

    const isSwitchedUser = !!switchedUserOriginalRoleId && !!switchedUserOriginalId;

    // for switched user or internal user
    if (isSwitchedUser) {
        if (isArray(allowedForOriginalUserRoleIds) && allowedForOriginalUserRoleIds.length > 0) {
            return includes(allowedForOriginalUserRoleIds, switchedUserOriginalRoleId);
        } else {
            return true;
        }
    } else {
        // for client
        return !isSwitchedUserOnly;
    }
}

export function showInspectionCheckListForAccount() {
    return getFlagValueFromAccountConfig('showInspectionCheckListForAccount');
}

export function isjobBookingOpenToAccount() {
    return getFlagValueFromAccountConfig('isjobBookingOpenToAccount');
}

export function showRoutesForAccount() {
    return getFlagValueFromAccountConfig('showRoutesForAccount');
}

export function showReferAndEarnForAccount() {
    return getFlagValueFromAccountConfig('showReferAndEarnForAccount');
}

export function showClientsForAccount() {
    return getFlagValueFromAccountConfig('showClientsForAccount');
}

export function showJobsPerformanceForAccount() {
    return includes(
        [FLEETX_ACCOUNTS.SHIVANI, FLEETX_ACCOUNTS.FLEETX],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
    return getFlagValueFromAccountConfig('showJobsPerformanceForAccount');
}

export function getEVBatteryUnit() {
    return getFlagValueFromAccountConfig('EVBatteryUnit');
}

export function showAlarmSubFiltersForAccount() {
    return true;
    return getFlagValueFromAccountConfig('showAlarmSubFiltersForAccount');
}

export function showAlarmDriverSMSForAccount() {
    return getFlagValueFromAccountConfig('showAlarmDriverSMSForAccount');
}

export function getOpenSimStopsAndTrail() {
    return getFlagValueFromAccountConfig('openSimStopsAndTrail');
}

export function showPlannedFuelQuantityForAccount() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.RRC:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function showTimelineMapLink() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.RKMPowergen:
            return true;
        default:
            return false;
    }
}

export function showIssueEntityIdForAccount() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.RRC:
        case FLEETX_ACCOUNTS.INTERCITY_CARRIERS:
            return true;
        default:
            return false;
    }
}

export function fetchDetailedAlarmForAcccount(scope) {
    if (scope && isViewVideoTelematicsAllowedForUser(scope)) {
        return true;
    }
    return getFlagValueFromAccountConfig('detailedAlarmForAcccount');
}

export function fetchIssuesDTOForAccount() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (account) {
        case FLEETX_ACCOUNTS.KM_TRANS:
        case FLEETX_ACCOUNTS.ARPL:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return null;
    }
}

export function isAlarmReportColCustomOrderingForAccount() {
    return getFlagValueFromAccountConfig('alarmReportColCustomOrderingForAccount');
}

export function useRouteNameApiInJobBooking() {
    return getFlagValueFromAccountConfig('useRouteNameApiInJobBooking');
}

export function useProductTypeApiInJobBooking() {
    return getFlagValueFromAccountConfig('useProductTypeApiInJobBooking');
}

export function forceNotificationFlowWithoutFirebase() {
    const email = toLower(get(window.FLEETX_LOGGED_IN_USER, 'email', ''));
    if (
        email == 'tabish+srinivas@fleetx.io' ||
        email == 'papul.borah@srinivasatours.com' ||
        email == 'papulborah.1@gmail.com' ||
        email == 'finance@suryatravels.net'
    ) {
        return true;
    }
    return false;
}

export function isMacIdUpdateAllowed() {
    const email = toLower(get(window.FLEETX_LOGGED_IN_USER, 'email', ''));
    if (
        email == 'alkesh.raj@fleetx.io' ||
        email == 'annu.singh@fleetx.io' ||
        email == 'devender@fleetx.io' ||
        email == 'pawan@fleetx.io' ||
        email == 'vince@fleetx.io' ||
        email == 'amit.kumar1@fleetx.io' ||
        email == 'himanshu.gupta@fleetx.io' ||
        email == 'archit.vyas@fleetx.io' ||
        email == 'aman.thapliyal@fleetx.io' ||
        email == 'inventory@fleetx.io'
    ) {
        return true;
    }
    return showIot();
}

export function useJobEndDateForMergedTripsForAccount() {
    return getFlagValueFromAccountConfig('useJobEndDateForMergedTripsForAccount');
    // const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    // return account === FLEETX_ACCOUNTS.JKLC;
}

export function jobStartDateKeyForMergedTripsForAccount() {
    return getFlagValueFromAccountConfig('jobStartDateKeyForMergedTripsForAccount');
}

export function categoryForOverview() {
    return getFlagValueFromAccountConfig('categoryForOverview');
}

export function excludePortableDevices() {
    return getFlagValueFromAccountConfig('excludePortableDevices');
}

export function useJobCreatedTimeForFetchedMergedTrip() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return account === FLEETX_ACCOUNTS.JKLC;
}

export function isHastiAccount() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return account === FLEETX_ACCOUNTS.HASTI_PETRO;
}

export function showAddOdometerEntryOpenForAccount() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.BOM_GIM_LOGISTIC, FLEETX_ACCOUNTS.FLEETX], account);
}

export function setLastPointActualArrivalOnUpdateJob() {
    const account = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.SHRI_RAMJANYA, 11746], account);
}

export function isVehicleSummaryColCustomOrderingForAccount() {
    return getFlagValueFromAccountConfig('isVehicleSummaryColCustomOrdering');
}

export function isViewGroupAPISummaryVisible() {
    return getFlagValueFromAccountConfig('isViewGroupAPISummary');
}

export function isViewTransporterAPISummaryVisible() {
    return getFlagValueFromAccountConfig('isViewTransporterAPISummaryVisible');
}

export function isHistoryReportColCustomOrderingForAccount() {
    return getFlagValueFromAccountConfig('isHistoryReportColCustomOrderingForAccount');
}

export function showOldJobBookingForAccount() {
    return getFlagValueFromAccountConfig('showOldJobBookingForAccount');
}

export function setJobBookingEndDate() {
    return getFlagValueFromAccountConfig('setJobBookingEndDate');
}

export function showOldServiceEntryForAccount() {
    return false;
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) < 5000;
}

export function showOnJobNotOnJobKmsInVehicleListing() {
    return getFlagValueFromAccountConfig('showOnJobNotOnJobKmsInVehicleListing');
}

export function showCloseJobModalForAccount() {
    return getFlagValueFromAccountConfig('showCloseJobModal');
}

export function odometerCompulsorywhenClosingJob() {
    return getFlagValueFromAccountConfig('odometerCompulsorywhenClosingJob');
}

export function showUpdateJobStatusModalForAccount() {
    return getFlagValueFromAccountConfig('showUpdateJobStatusModal');
}

export function fetchVehicleLastOdoForJob() {
    return getFlagValueFromAccountConfig('fetchVehicleLastOdoForJob');
}

export function isVehicleScoreRatingScaleForAccount() {
    return getFlagValueFromAccountConfig('isVehicleScoreRatingScale');
}

export function showUpdateKMInJobModalForAccount() {
    return getFlagValueFromAccountConfig('showUpdateKMInJobModal');
}

export function isZHPOPPELTDAccount(loggedInUser) {
    return get(loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.ZHPOPPELTD;
}

export function isDefaultOpenJobSharePageToDetails() {
    return getFlagValueFromAccountConfig('isDefaultOpenJobSharePageToDetails');
}

export function checkStopDurationForAccount() {
    return getFlagValueFromAccountConfig('checkStopDurationForAccount');
}

export function includeExcludeUnloadingRadius() {
    return getFlagValueFromAccountConfig('includeExcludeUnloadingRadius');
}

export function getVehicleSummaryColMappingForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
            return mangalamColVehicleSummaryMapping;
        case FLEETX_ACCOUNTS.SMART_GREEN:
            return smartGreenColVehicleSummaryMapping;
    }
}

export const showWorkingTimeColForAccount = () => {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SMART_GREEN:
            return true;
        default:
            return false;
    }
};

export function getDefaultVehicleSummaryIncludesConfig(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
            return defaultIncludesMangalamCement;
        default:
            return defaultIncludes;
    }
}

export function isjklcAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.JKLC;
}

export function isShreeCement8000() {
    return includes([...isShreeCementsAccounts, 1505], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}
export function isAllowedToShowSpecifiedAccount() {
    return includes(
        [...isShreeCementsAccounts, 1505, 914, 8199, 7573, 8493],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function isGanpatiRoadCarriers() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.GANPATI_ROAD_CARRIERS;
}

export function getAlarmReportColMappingForAccount(loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KM_TRANS:
            return kmColAlarmReportMapping;
        default:
            return kmColAlarmReportMapping;
    }
}

export function showExtraFieldsOfTreadDepthForAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        default:
            return false;
    }
}

export function showDiffIconForUnloadingStop() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHRI_RAMJANYA:
            return true;
        default:
            return false;
    }
}

export function showExtraAlarmsInAllSummaryReport(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.SIDDHI:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
            return true;
        default:
            return false;
    }
}

export function showDailyFuelConsumptionInAllSummaryReport(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.SIDDHI:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
            return true;
        default:
            return false;
    }
}

export function showServiceMaintenanceTimeAcnt(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.SIDDHI:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
            return true;
        default:
            return false;
    }
}

export function showFuelLogEntriesDataAcnt(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANGALAM_CEMENT:
        case FLEETX_ACCOUNTS.SIDDHI:
        case FLEETX_ACCOUNTS.RASHMI:
        case FLEETX_ACCOUNTS.HERO_MOTOCORP:
        case FLEETX_ACCOUNTS.RASHMI_METALICS:
        case FLEETX_ACCOUNTS.JK_CEMENT_FUEL_SENSOR:
        case FLEETX_ACCOUNTS.INFRA_MARKET:
            return true;
        default:
            return false;
    }
}

export function showExpectedKMColumnInFuelLog() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SRV_LOGISTICS:
        case FLEETX_ACCOUNTS.FLEETX:
            return true;
        default:
            return false;
    }
}

export function isOperatorOpenToAccount(operator, localTransporterAccount = null) {
    const finalAccountId = localTransporterAccount
        ? localTransporterAccount
        : get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (finalAccountId) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
        case FLEETX_ACCOUNTS.CAP_FRESH:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.SUNFLAG:
        case FLEETX_ACCOUNTS.JK_CEMENT2:
        case FLEETX_ACCOUNTS.XIAOMI:
        case FLEETX_ACCOUNTS.JKLC:
        case FLEETX_ACCOUNTS.ASHIRVAD_TRAILER_SERVICE:
        case FLEETX_ACCOUNTS.KALS:
        case FLEETX_ACCOUNTS.ORIENT_CEMENT:
        case 11629:
        case 10577:
        case 14584:
            return true;
        default:
            if (operator !== SIM_PROVIDER.BSNL) return true;
    }
}

export function dontDisableFinanceFieldsInJobs() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GRASIM:
        case FLEETX_ACCOUNTS.MANVILLE:
        case FLEETX_ACCOUNTS.CHOUBEY_TRANSPORT_SERVICE:
        case FLEETX_ACCOUNTS.OM_SAI_ROADLINES:
        case FLEETX_ACCOUNTS.SRAVAN_SHIPPING:
            return false;
        default:
            return true;
    }
}

export function allowFreightRateMultiplication() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SULT:
        case FLEETX_ACCOUNTS.DAULAT:
        case FLEETX_ACCOUNTS.MANVILLE:
        case FLEETX_ACCOUNTS.RAJESH_AND_BROTHER:
            return true;
        default:
            return false;
    }
}

export function showEPODUpdateOptionOnJobTimeline(localTransporterAccount) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', localTransporterAccount)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.ERP_TEST_ACCOUNT:
        case FLEETX_ACCOUNTS.SPEEDFOX:
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function weightNameForMultiplication() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.DAULAT:
            return 'unloadedWeight';
        default:
            return 'weight';
    }
}

export function vendorSummaryLabel(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return VENDOR_MAERSK_STATUS_LABEL;
        default:
            return STATUS;
    }
}

export function getSystemTagIdForFiltering() {
    if (get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false)) {
        return [];
    }
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GROFERS:
            return [7460];
        case FLEETX_ACCOUNTS.FLEETX:
            return [7460];
        default:
            return [];
    }
}

export function getDummyVehicleTagId() {
    if (get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false)) {
        return '';
    }
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GROFERS:
            return 7460;
        default:
            return '';
    }
}

export function userIdSpecificLogo() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'id', 0)) {
        //smart green infra
        case 257846:
            return true;
        default:
            return false;
    }
}

export function getPDBatteryCheckValue() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.XIAOMI:
            return 5;
        default:
            return null;
    }
}

export function getWhiteLabelLogoNumber(hostName) {
    const url = 'https://' + hostName;
    switch (url) {
        case ALL_WHITE_LABEL_URLS.FLEETX_INTERNAL:
            return 113;
        case ALL_WHITE_LABEL_URLS.ICICI_LOMBARD:
            return 6056;
        case ALL_WHITE_LABEL_URLS.RIGHT_CHOICE:
            return 5530;
        case ALL_WHITE_LABEL_URLS.ACCEX:
            return 9630;
        case ALL_WHITE_LABEL_URLS.SHELL:
            return 8806;
        // case ALL_WHITE_LABEL_URLS.SHOORA:
        //     return 8407;
    }

    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.POONA_ICICI:
        case FLEETX_ACCOUNTS.VINAYAK_ICICI:
        case FLEETX_ACCOUNTS.PARIVAHAN_ICICI:
        case FLEETX_ACCOUNTS.RAJU_ICICI:
        case FLEETX_ACCOUNTS.RAJENDRA_ICICI:
        case FLEETX_ACCOUNTS.JAYASHREE_ICICI:
            return 6056;
        case FLEETX_ACCOUNTS.RIGHT_CHOICE:
            return 5530;
        // case FLEETX_ACCOUNTS.SHOORA:
        // case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
        //     return 8407;
        default:
            return null;
    }
}

export const getWhiteLabelLogo = (hostName) => {
    const url = 'https://' + hostName;
    switch (url) {
        case ALL_WHITE_LABEL_URLS.FLEETX_INTERNAL:
            return `/public/img/logos/fleetx/fleetx-logo-final-full-header-icon.png`;
        case ALL_WHITE_LABEL_URLS.ICICI_LOMBARD:
            return `/public/img/logos/clients/${6056}.png`;
        case ALL_WHITE_LABEL_URLS.RIGHT_CHOICE:
            return `/public/img/logos/clients/${5530}.png`;
        case ALL_WHITE_LABEL_URLS.ACCEX:
            return `/public/img/logos/clients/${9630}.png`;
        case ALL_WHITE_LABEL_URLS.SHELL:
            return `/public/img/logos/clients/${8806}.png`;
        // case ALL_WHITE_LABEL_URLS.SHOORA:
        //     return 8407;
    }
};

export function showGenerateLRReport(loggedInUser, job, INDENT_TYPE) {
    if (!isEmpty(job) && !isMaerskAccount()) {
        return true;
    } else if (!isEmpty(job) && isMaerskAccount() && get(job, 'jobBooking.indentType') === INDENT_TYPE.LEASE) {
        return true;
    } else {
        return false;
    }
}

export function allowJobDetailsReportPrintFroAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SYNERGY:
            return true;
        default:
            return false;
    }
}

export function isSynergyAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.SYNERGY;
}

export function isMangalamCement() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.MANGALAM_CEMENT;
}

export function isAlgorAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.ALGOR;
}

export function skipLargeJobReportCheck() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function showAddIndentInAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return false;
        default:
            return true;
    }
}

export function enableTyreRFIDFlow() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
        case FLEETX_ACCOUNTS.JYOTI_STRIPS:
        case FLEETX_ACCOUNTS.GIA_TANZANIA:
        case FLEETX_ACCOUNTS.ZAMM_IMPORTS:
        case FLEETX_ACCOUNTS.SAHU_MILLS:
            return true;
        default:
            return false;
    }
}

export function disableSingleVehicleManualInvoice() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANVILLE:
            return true;
        default:
            return false;
    }
}

export function showJobBooking() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANVILLE:
            return true;
        default:
            return false;
    }
}

export function enableCustomInvoiceFlow() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MANVILLE:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.SLC_NEWACO:
        case FLEETX_ACCOUNTS.TEAM_PARTNER:
            return true;
        default:
            return false;
    }
}

export function getAdditionalVehicleStateForAccount(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.SATVA_HITECH:
            return { id: 'EMPTY_TRAILER', name: 'Empty Trailer' };
        default:
            return [];
    }
}

export function allowAutoFillVehicle() {
    return getFlagValueFromAccountConfig('allowAutoFillVehicle');
}

export function isStoreMandatoryForTyre() {
    return getFlagValueFromAccountConfig('isStoreMandatoryForTyre');
}

export function satelliteTypeMapDefault() {
    return getFlagValueFromAccountConfig('satelliteTypeMapDefault');
}

export function satelliteTypeMapDefaultRest() {
    return getFlagValueFromAccountConfig('satelliteTypeMapDefaultRest');
}

export function showGeoFenceInAlarmMarker() {
    return getFlagValueFromAccountConfig('showGeoFenceInAlarmMarker');
}

export function isIdleMarkerOpenOnTimeline() {
    return getFlagValueFromAccountConfig('isIdleMarkerOpenOnTimeline');
}

export function splitRunningStopTimelineWithIdlePoints() {
    return getFlagValueFromAccountConfig('splitRunningStopTimelineWithIdlePoints');
}

export function isVehicleDocumentDifferentChargesEnabled() {
    return getFlagValueFromAccountConfig('isVehicleDocumentDifferentChargesEnabled');
}

export function optimizeIdlePoints() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.SNN:
            return 0;
        default:
            return getFlagValueFromAccountConfig('optimizeIdlePoints');
    }
}

export function showSpeedViolationsInHistory() {
    return getFlagValueFromAccountConfig('showSpeedViolationsInHistory');
}

export function showVideoTelematics() {
    return getFlagValueFromAccountConfig('showVideoTelematics');
}

export function showMultiSelectWithCheckBoxForAccount() {
    return getFlagValueFromAccountConfig('showMultiSelectWithCheckBoxForAccount');
}

export function disableVehicleTypeInVehicleFormForAcnt() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INFRA_MARKET:
        case FLEETX_ACCOUNTS.SATANPLUS_TECHNOLOGY:
            return false;
        default:
            return true;
    }
}

export function getCountryNameFromAccountId(accountId) {
    if (
        accountId == FLEETX_ACCOUNTS.AERIS ||
        accountId == FLEETX_ACCOUNTS.AERIS2 ||
        accountId == FLEETX_ACCOUNTS.AERIS3 ||
        accountId == FLEETX_ACCOUNTS.AERIS4 ||
        accountId == FLEETX_ACCOUNTS.AERIS5 ||
        accountId == FLEETX_ACCOUNTS.CHAKRA_JAWARA ||
        accountId == FLEETX_ACCOUNTS.PT_USI ||
        accountId == FLEETX_ACCOUNTS.APS_DIGITAL ||
        accountId == FLEETX_ACCOUNTS.HINO ||
        accountId == FLEETX_ACCOUNTS.WARESIX ||
        accountId === 10605
    ) {
        return COUNTRIES.INDONESIA;
    }
    if (accountId == FLEETX_ACCOUNTS.TECHNOPATH) {
        return COUNTRIES.PHILIPPINES;
    }
    if (accountId == FLEETX_ACCOUNTS.FABHOUSE_BOTSWANA_AFRICA || accountId == FLEETX_ACCOUNTS.TIGER_AUTO) {
        return COUNTRIES.BOTSWANA;
    }
    if (accountId == FLEETX_ACCOUNTS.HELLO_TRACTOR_KENYA || accountId == FLEETX_ACCOUNTS.SAFERON_KENYA) {
        return COUNTRIES.KENYA;
    }

    if (
        accountId == FLEETX_ACCOUNTS.SHARJAH_TRANPSORT ||
        accountId == FLEETX_ACCOUNTS.ATS_LOGISTICS ||
        accountId == FLEETX_ACCOUNTS.PROTOCOL_SYSTEM ||
        accountId == FLEETX_ACCOUNTS.RIGHT_CHOICE ||
        accountId == FLEETX_ACCOUNTS.ATS_UAE
    ) {
        return COUNTRIES.UAE;
    }
    if (
        accountId == FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA ||
        accountId == FLEETX_ACCOUNTS.GIA_TANZANIA ||
        accountId == FLEETX_ACCOUNTS.FLYING_CARGO ||
        accountId == FLEETX_ACCOUNTS.BEST_OCEAN ||
        accountId == FLEETX_ACCOUNTS.AB_GILMANS ||
        accountId == FLEETX_ACCOUNTS.LAKE_TRANS ||
        accountId === 11320 ||
        accountId === 10959
    ) {
        return COUNTRIES.TANZANIA;
    }
    if (accountId == FLEETX_ACCOUNTS.EURIST || accountId == FLEETX_ACCOUNTS.FABIO_BIKES) {
        return COUNTRIES.UGANDA;
    }
    if (
        accountId == FLEETX_ACCOUNTS.ZAMM_IMPORTS ||
        accountId == FLEETX_ACCOUNTS.GFS_ZAMBIA ||
        accountId == FLEETX_ACCOUNTS.CITY_FRONTIERS
    ) {
        return COUNTRIES.ZAMBIA;
    }
    if (accountId == FLEETX_ACCOUNTS.ZAMM_IMPORTS) {
        return COUNTRIES.ZAMBIA;
    }
    if (accountId == FLEETX_ACCOUNTS.JOVITRONIX) {
        return COUNTRIES.EGYPT;
    }
    if (accountId == FLEETX_ACCOUNTS.MAERSK_BANGLADESH) {
        return COUNTRIES.BANGLADESH;
    }
    if (accountId == FLEETX_ACCOUNTS.MAERSK_SRI_LANKA) {
        return COUNTRIES.SRILANKA;
    }
    if (accountId == FLEETX_ACCOUNTS.NILE_LOGIX) {
        return COUNTRIES.GABON;
    }

    if (accountId == FLEETX_ACCOUNTS.SLC_NEWACO) {
        return COUNTRIES.ANGOLA;
    }
    return COUNTRIES.INDIA;
}

export const isDeviceTypeOpenToAccount = (device, country, accountId) => {
    if (device.value === 'SIM' && country !== 'INDIA') {
        return false;
    }
    return true;
};

export const getRealtimeBulkUploader = () => {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.IGT:
            return '/public/xlsx/vehicles/shareVehicleLocationUploaderCustomised.xlsx';
        default:
            return '/public/xlsx/vehicles/shareVehicleLocationUploader.xlsx';
    }
};

export const showCommentSectionInHistory = () => {
    return getFlagValueFromAccountConfig('showCommentInStoppage');
};

export const showNearbyVehicleForSelectedVehicle = () => {
    return getFlagValueFromAccountConfig('showMultipleVehicleInMap');
};

export const getRealtimeBulkUploaderEndPoint = () => {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.IGT:
            return 'uploaders/igt/vehicle/location';
        default:
            return 'uploaders/vehicle/location';
    }
};
export const setOldTripUpdateFalseByDefaultInEditJob = () => {
    return (
        includes(
            [
                FLEETX_ACCOUNTS.KALBURGI_CEMENT,
                FLEETX_ACCOUNTS.DELHI_VAPI,
                FLEETX_ACCOUNTS.INTERCITY_CARRIERS,
                FLEETX_ACCOUNTS.SRAVAN_SHIPPING,
                FLEETX_ACCOUNTS.INDIAN_ROAD_CARRIER,
                11582,
                FLEETX_ACCOUNTS.JKLC,
                FLEETX_ACCOUNTS.MAHALAKSHMI,
                2034,
                12407,
                681,
                2051,
                2050,
                13325,
                936,
                13278,
                12985,
            ],
            get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
        ) || isOldTripUpdateFalseByDefaultInEditJobFlag()
    );
};

export const getNotOnJobDayRangeForAccount = (isNewApi = false) => {
    if (isNewApi) {
        return [
            {
                value: 'ZERO_TO_ONE',
                label: '0 - 1 Day',
            },
            {
                value: 'ONE_TO_FIFTEEN',
                label: '1 - 15 Days',
            },
            {
                value: 'FIFTEEN_TO_THIRTY',
                label: '15 - 30 Days',
            },
            {
                value: 'THIRTY_TO_SIXTY',
                label: '30 - 60 Days',
            },
            {
                value: 'GREATER_THAN_SIXTY',
                label: 'Greater than 60 Days',
            },
        ];
    }
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return [
                {
                    value: '0-1',
                    label: '0 - 1 Day',
                },
                {
                    value: '1-2',
                    label: '1 - 2 Days',
                },
                {
                    value: '2-5',
                    label: '2 - 5 Days',
                },
                {
                    value: '5-15',
                    label: '5 - 15 Days',
                },
                {
                    value: '15-30',
                    label: '15 - 30 Days',
                },
                {
                    value: '30-60',
                    label: '30 - 60 Days',
                },
                {
                    value: '>60',
                    label: 'Greater than 60 Days',
                },
            ];
        default:
            return [
                {
                    value: '0-1',
                    label: '0 - 1 Day',
                },
                {
                    value: '1-15',
                    label: '1 - 15 Days',
                },
                {
                    value: '15-30',
                    label: '15 - 30 Days',
                },
                {
                    value: '30-60',
                    label: '30 - 60 Days',
                },
                {
                    value: '>60',
                    label: 'Greater than 60 Days',
                },
            ];
    }
};

export function hideVehicleCurrentStatusInRealtime() {
    return getFlagValueFromAccountConfig('hideVehicleCurrentStatusInRealtime');
}

export function defaultHideInfoWindowRealtimeForAccount() {
    return getFlagValueFromAccountConfig('defaultHideInfoWindowRealtimeForAccount');
}

export function isJobNameFieldNotMandatory() {
    return getFlagValueFromAccountConfig('isJobNameFieldNotMandatory');
}

export function workOrderStationaryMandatory() {
    return getFlagValueFromAccountConfig('workOrderStationaryMandatory');
}

export function isVehicleWiseParkedThresholdAllowed() {
    return getFlagValueFromAccountConfig('isVehicleWiseParkedThresholdAllowed');
}

export function showCommentHistoryInVehicleDetailForAccount() {
    return getFlagValueFromAccountConfig('showCommentHistoryInVehicleDetail');
}

export function isMaerskAccount(accountId = 0) {
    return includes(
        [FLEETX_ACCOUNTS.MAERSK, FLEETX_ACCOUNTS.MAERSK_BANGLADESH, FLEETX_ACCOUNTS.MAERSK_SRI_LANKA],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)
    );
}

export function showFuelWastageForAccount() {
    return includes([FLEETX_ACCOUNTS.EXPRESS_ROADWAYS], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showRealtimeWeeklyOffForAccount() {
    return includes(
        [FLEETX_ACCOUNTS.BLR_LOGISTICS, FLEETX_ACCOUNTS.TAZ_TRANSPORT],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function showFxIdInRealtimeReportForAccount() {
    return includes([FLEETX_ACCOUNTS.GRASIM], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function isDispatchPlanningBulkCreateAllowed() {
    return true;
    return getFlagValueFromAccountConfig('isDispatchPlanningBulkCreateAllowed');
}

export function editEPODChargesAllowed() {
    return getFlagValueFromAccountConfig('editEPODCharges');
}

export function isJobsDisabledForNonTrackInFreightSettlement() {
    return getFlagValueFromAccountConfig('disableJobsInFreightSettlementForNonTrack');
}

export function isSignatureUIEnabled() {
    return getFlagValueFromAccountConfig('showSignatureOptionOnEPODShare');
}

export function maskContractAndJobBookingRate() {
    return getFlagValueFromAccountConfig('maskContractAndJobBookingRate');
}

export function ignoreMinDispatchDateForAccount() {
    return includes([FLEETX_ACCOUNTS.OM_SAI_ROADLINES], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function fetchChildVehiclesOnJob() {
    return getFlagValueFromAccountConfig('fetchChildVehiclesOnJob');
}

export function showOrderNumberInJob() {
    return includes([FLEETX_ACCOUNTS.GRASIM], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function isShowTimeLineOnCardClick() {
    const roleId = get(window, 'FLEETX_LOGGED_IN_USER.role.id', '');
    return getFlagValueFromAccountConfig('isShowTimeLineOnCardClick') || includes([111689], roleId);
    // switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
    //     case FLEETX_ACCOUNTS.VENTURE_SUPPLY:
    //     case FLEETX_ACCOUNTS.FLIPKART:
    //     case FLEETX_ACCOUNTS.SPEEDFOX:
    //         return true;
    //     default:
    //         return false;
    // }
}

export function dontShowNotOnlineAndRemovedVehicle() {
    return getFlagValueFromAccountConfig('dontShowNotOnlineAndRemovedVehicle');
}

export function showTimelineInRealtimeForAccount() {
    return getFlagValueFromAccountConfig('showTimelineInRealtime');
}

export function showRealtimeAddressCopyButton() {
    return getFlagValueFromAccountConfig('showRealtimeAddressCopyButton');
}

export function hideDelayAtFirstPointInJobTimeLine() {
    return getFlagValueFromAccountConfig('hideDelayAtFirstPointInJobTimeLine');
}

export function fetchJobUtilisationQueryForAccount() {
    return getFlagValueFromAccountConfig('fetchJobUtilisationQueryForAccount');
}

export function showContainerNumbersInInvoiceListingForAccount() {
    return includes([FLEETX_ACCOUNTS.MANVILLE], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showJobSettlementInJobDetils() {
    return includes(
        [FLEETX_ACCOUNTS.MANVILLE, FLEETX_ACCOUNTS.SRV_LOGISTICS],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function showBackAddressInInvoiceListingForAccount() {
    return includes([FLEETX_ACCOUNTS.MANVILLE], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showActualTimeInParking() {
    return getFlagValueFromAccountConfig('showActualTimeInParking');
}

export function isLoadedInTransitAccount() {
    return getFlagValueFromAccountConfig('isLoadedInTransitAccount');
}

export function freightSettlementAutofillDisabled() {
    return getFlagValueFromAccountConfig('freightSettlementAutofillDisabled');
}

export function checkFittedInByNumberApi(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return getFlagValueFromAccountConfig('checkFittedInByNumberApi');
    }
}

export function hideAllHistoryReportsForUserId() {
    const userId = get(window.FLEETX_LOGGED_IN_USER, 'id', 0);
    return includes([790613, 1845], userId);
}

/**
 *
 * @param minDate {*}
 * @returns {null|undefined|moment.Moment}
 */
export function accountLevelMinDateSelection(minDate = null) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (accountId === FLEETX_ACCOUNTS.DENEB) {
        return getMomentTime().subtract(180, 'days');
    } else {
        return minDate ? minDate : undefined;
    }
}

export function overwriteCurrentStatusJobReport() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.DAULAT:
            return true;
        default:
            return false;
    }
}

export function getAlwaysCurrentStatus() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.DAULAT:
            return 'COMPLETED';
        default:
            return '';
    }
}

export function isEndDateRequiredForAccount(jobStatus) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
            return jobStatus === 'LOADING';
        default:
            return false;
    }
}

export function getWaitingToLoadLabelForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
            return 'NOMINATION';
        default:
            return 'WAITING_TO_LOAD';
    }
}

export function getUnloadLabelForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.MOUNT_MERU_TANZANIA:
            return 'REACH DESTINATION';
        default:
            return 'UNLOADING';
    }
}

export function getLabelAndPlaceholdersForIndentAndJobBooking(type) {
    const indentConsigneeDefault = {
        label: 'Consignee Details',
        placeholder: 'Select Consignee',
    };
    const jobBookingCustomerDefault = {
        label: 'Customer',
        placeholder: 'Select Customer',
        error: 'Please select a customer.',
    };
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.AMRIT_CEMENTS:
            if (type == 'indent_consignee') {
                return {
                    label: 'Plant',
                    placeholder: 'Select Plant',
                };
            } else if (type == 'job_booking_customer') {
                return {
                    label: 'Plant',
                    placeholder: 'Select Plant',
                    error: 'Please select a plant.',
                };
            }
            break;
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            if (type === 'indent_consignee') {
                return indentConsigneeDefault;
            } else if (type === 'job_booking_customer') {
                return {
                    label: 'Guest',
                    placeholder: 'Select Guest',
                    error: 'Please select a Guest.',
                };
            }
            break;
        default:
            if (type === 'indent_consignee') {
                return indentConsigneeDefault;
            } else if (type === 'job_booking_customer') {
                return jobBookingCustomerDefault;
            }
    }
}

export function showActualDistanceKmInJobTimeline() {
    return getFlagValueFromAccountConfig('showActualDistanceKmInJobTimeline');
}

export function showStationaryNumberInRealtimeJobDetails() {
    return getFlagValueFromAccountConfig('showStationaryNumberInRealtimeJobDetails');
}

export function showStationaryNumberWithJobNameJobListing() {
    return getFlagValueFromAccountConfig('showStationaryNumberWithJobNameJobListing');
}

export function isOdometerNonMandatoryAndHiddenInFuelLog() {
    return getFlagValueFromAccountConfig('odometerNonMandatoryAndHiddenInFuelLog');
}

export function isOdometerNonMandatoryAndHiddenInServiceEntries() {
    return getFlagValueFromAccountConfig('odometerNonMandatoryAndHiddenInServiceEntries');
}

export function getWeighmentUnit() {
    return getFlagValueFromAccountConfig('weighbridgeWeightUnit');
}

export function isTollOpenToAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.PCL_TRANSPORT:
            return true;
        default:
            return false;
    }
}

export function showLVReport() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.DTDC:
        case FLEETX_ACCOUNTS.KUSH:
            return true;
        default:
            return false;
    }
}

export function showLVSummaryReport() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.DTDC:
        case FLEETX_ACCOUNTS.KUSH:
            return true;
        default:
            return false;
    }
}

export function showTollsForAccount() {
    return getFlagValueFromAccountConfig('showTollInTimeline');
}

export function checkForDupilcateDriver() {
    return getFlagValueFromAccountConfig('checkDuplicateDriver');
}

export function isInitialAnimationForAcnt() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.TIGER:
            return false;
        default:
            return true;
    }
}

export function isHighlightTimelineCardOnPolylineClickEnabled() {
    return getFlagValueFromAccountConfig('highlightTimelineCardOnPolylineClick');
}

export function nonMandatoryPickDeliveryInConsignment() {
    return getFlagValueFromAccountConfig('nonMandatoryPickDeliveryInConsignment');
}

export function enableUnitPartCostInServiceHistory() {
    return getFlagValueFromAccountConfig('enableUnitPartCostInServiceHistory');
}

export function shareJobOnRealtimeVehicle() {
    return getFlagValueFromAccountConfig('shareJobOnRealtimeVehicle');
}

export function collapseOtherChargesInConsignmentForm() {
    return getFlagValueFromAccountConfig('collapseOtherChargesInConsignmentForm');
}

export function isClientContractChartEnabledForAccount() {
    return (
        includes(['ECOMMERCE', 'BOTH'], getBusinessType()) && getFlagValueFromAccountConfig('enableClientContractChart')
    );
}

export function isAutoBillEnabledForCN() {
    return getFlagValueFromAccountConfig('autoBillForBilledCN');
}

export function isBranchBasedRateCharge() {
    return getFlagValueFromAccountConfig('branchBasedRateCharge');
}

export function enableVehicleSelectionForPTLInConsignment() {
    return getFlagValueFromAccountConfig('enableVehicleSelectionForPTLInConsignment');
}

export function mandatoryGSTNumberInBranchWithClientMapping() {
    return getFlagValueFromAccountConfig('mandatoryGSTNumberInBranchWithClientMapping');
}

export function isTodaysKmRealtimeReportOpenForAccount() {
    return getFlagValueFromAccountConfig('isTodaysKmRealtimeReportOpenForAccount');
}

export function isLiveRequestProtectedPassword() {
    return getFlagValueFromAccountConfig('isLiveRequestProtectedPassword');
}

export function allowCreateJobFromConsignment() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.ERP_TEST_ACCOUNT:
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.HRA_LOGISTICS:
        case FLEETX_ACCOUNTS.SHIVAAY:
        case FLEETX_ACCOUNTS.UNITED_MOVERS:
        case FLEETX_ACCOUNTS.SHRI_RAMJANYA:
        case FLEETX_ACCOUNTS.GUARD_LOGISTICS:
        case FLEETX_ACCOUNTS.NATIONAL_TRANSPORT:
        case FLEETX_ACCOUNTS.MEDALLION_TRANSLINKS:
            return true;
        default:
            return getAutoChallanType() == 'FTL' || getAutoChallanType() == 'FTL_PTL';
    }
}

export function showMovementTypeAndTripNatureInJob() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.SHIVAAY:
        case FLEETX_ACCOUNTS.TANISHK:
        case FLEETX_ACCOUNTS.ROSHAN_LAL_CHEMICALS:
        case FLEETX_ACCOUNTS.MAHALAKSHMI:
        case FLEETX_ACCOUNTS.UNITED_MOVERS:
        case FLEETX_ACCOUNTS.GUARD_LOGISTICS:
        case FLEETX_ACCOUNTS.NATIONAL_TRANSPORT:
        case FLEETX_ACCOUNTS.MEDALLION_TRANSLINKS:
            return true;
        default:
            return false;
    }
}

export function isShellThemeOpenToAccount() {
    const hostName = window.location.hostname;
    const url = 'https://' + hostName;
    if (url === ALL_WHITE_LABEL_URLS.SHELL) {
        return true;
    }
    return includes([...getAllShellAccountIds()], get(window.FLEETX_LOGGED_IN_USER, 'accountId'));
}

export function getJobFormEwayBillExpiryMinDate() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.JYOTI_INDUSTRIES:
            return getMomentTime().subtract(2, 'y');
        default:
            return getMomentTime();
    }
}

export function extraSkipForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.SPEEDFOX:
            return true;
        default:
            return false;
    }
}

export function shouldSplitForDayWise() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.RAJ_STEEL_AND_TUBES:
            return false;
        default:
            return true;
    }
}

export function isJobStatisticsReportEnabled() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.FLEETX, FLEETX_ACCOUNTS.MANCHUKONDA_PRAKASHAM_INDUSTRIES], accountId);
}

export function isVehicleNumberEditAllowed() {
    const userId = get(window.FLEETX_LOGGED_IN_USER, 'id', 0);
    const isSwitchedUser = get(window.FLEETX_LOGGED_IN_DATA, 'isSwitchedUser', false);
    return isSwitchedUser || includes([909623, 1399396, 1851999], userId); // 909623(accountId:6034)
}

export function isERPProfileEnabled(scope) {
    return isViewERPConfigurationAllowedForUser(scope);
}

export function isInternationalAccount() {
    const country = get(window.FLEETX_LOGGED_IN_USER, 'country');
    const timezone = get(window.FLEETX_LOGGED_IN_USER, 'timezone');
    return country != 'INDIA' || timezone !== defaultTimeZone;
}

export function showServiceReminderDataOnRealtimeMapWindowForAccount() {
    return getFlagValueFromAccountConfig('showServiceReminderDataOnRealtimeMapWindow');
}

export function showVehicleColorAccordingToServiceReminderForAccount() {
    return getFlagValueFromAccountConfig('showVehicleColorAccordingToServiceReminder');
}

export function excludeOverlappingTripsByBackendFlagsForAccount() {
    return getFlagValueFromAccountConfig('excludeOverlappingTripsByBackendFlags');
}

export function isViewTimelineOnJobDetailsPageEnabled() {
    return getFlagValueFromAccountConfig('isViewTimelineOnJobDetailsPageEnabled');
}

export function isOptimizeMergeTripAPIForTimelineInRealtimeEnabled() {
    return getFlagValueFromAccountConfig('optimizeMergeTripAPIForTimelineInRealtime');
}

export function showIMEIInRealtimeForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.JSW_2], accountId);
}

export function hideTamperedTripsForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.RUSHABH, 9619], accountId);
}

export function isCancelJobCommentAllowedForAccount() {
    return true;
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.EXPRESS_ROADWAYS], accountId);
}

export function filterUnloadingStopForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes([FLEETX_ACCOUNTS.RMC], accountId);
}

export function getAllowedIndentDeviceTypeOptions() {
    return getFlagValueFromAccountConfig('indentDeviceOptions');
}

export function showScheduledAndActualEndsInJobTimelineForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return includes(
        [
            FLEETX_ACCOUNTS.ADANI_COAL,
            FLEETX_ACCOUNTS.GLENMARK_PHARMA,
            FLEETX_ACCOUNTS.ADANI_REPORT,
            FLEETX_ACCOUNTS.PLENTEOUS_PHARMA,
            FLEETX_ACCOUNTS.ENTERPRISE_DEMO_ACCOUNT,
            11719,
        ],
        accountId
    );
}

export function showNextRoutePointCategoryTypeFilterInRealtime() {
    return getFlagValueFromAccountConfig('showNextRoutePointCategoryTypeFilterInRealtime');
}

export function allowedAssetData() {
    const userId = get(window.FLEETX_LOGGED_IN_USER, 'id', 0);
    return includes([1010902, 910295, 1208436, 1247514], userId); //1010902(7894)
}

export function getAssetDataTableMaxDays() {
    const userId = get(window.FLEETX_LOGGED_IN_USER, 'id', 0);
    if (userId === 1010902) {
        return 15;
    }
}

export function isShellUpdatePasswordRedirectEnabled() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return accountId === FLEETX_ACCOUNTS.SHELL || isShellWhiteLabelUrl();
}

export function isRemoteVehiclePaginationDisabled() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    const enterprise = get(window.FLEETX_LOGGED_IN_USER, 'enterprise', false);
    return !getFlagValueFromAccountConfig('enableRemoteVehicleListPagination') && accountId < 5000 && !enterprise;
}

export const getVehicleDepreciationFormula = () => {
    return getFlagValueFromAccountConfig('costDepreciationModel');
};

export function getAllShellAccountIds() {
    return [
        FLEETX_ACCOUNTS.SHELL,
        FLEETX_ACCOUNTS.SHELL_KHANDOBA,
        FLEETX_ACCOUNTS.ATHENA_CARS,
        FLEETX_ACCOUNTS.RAJ_TRANSPORT,
        FLEETX_ACCOUNTS.GGG_LOGISTICS,
        FLEETX_ACCOUNTS.EAGLE_TRADELINKS,
        FLEETX_ACCOUNTS.SMRT_LOGISTICS,
        FLEETX_ACCOUNTS.SASHIKALA_ENTERPRISE,
        FLEETX_ACCOUNTS.SIDDHESHWARA,
        FLEETX_ACCOUNTS.LABDHI_ASPHALTECH,
        10738,
        10818,
        10946,
        10977,
        11146,
        11153,
        11107,
        11319,
        11336,
        11430,
        11586,
        11573,
        11722,
    ];
}

export function getAccountIdForLogo() {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER;
    const accountId = get(loggedInUser, 'accountId');
    if (includes(getAllShellAccountIds(), accountId)) {
        return FLEETX_ACCOUNTS.SHELL;
    }
    return accountId;
}

export function showJobRoutPointLevelCustomerInJobMoreInfo() {
    return includes([isShreeCementsAccounts], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showOnlyNearestAddressWithCategories() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    // if (accountId === FLEETX_ACCOUNTS.TATA_STEEL_KALINGNAGAR) {
    //     return ['WAY_POINT'];
    // }
    return [];
}

export function getAccountSpecificAlarms() {
    return getFlagValueFromAccountConfig('accountSpecificAlarms');
}

export function overviewEnableTabs() {
    return getFlagValueFromAccountConfig('overviewEnableTabs');
}

export function showDriverBehaviourForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return (
        getFlagValueFromAccountConfig('showDriverBehaviour') ||
        includes([7653, 6866, 6748, 6185, 8842, 4895, 2857], accountId)
    );
}

export function dontClubStoppagesForAccount() {
    return (
        getFlagValueFromAccountConfig('dontClubStoppageForAccount') ||
        includes(
            [FLEETX_ACCOUNTS.JSW_CEMENT, FLEETX_ACCOUNTS.GRASIM_INDUSTRY],
            get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
        )
    );
}

export function useFieldsParamInMergedTripAPi() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return (
        includes([FLEETX_ACCOUNTS.SASHIKALA_ENTERPRISE], accountId) ||
        getFlagValueFromAccountConfig('useMergeTripAPIFieldsParam')
    );
}

export function showACDataInHistoryReport() {
    return includes(
        [FLEETX_ACCOUNTS.AMANBUS, 2690, 10744, 10726, 11005, 7269],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function ignoreLatLngDiffInMotionPointCalculation() {
    return true;
    return includes([FLEETX_ACCOUNTS.DALMIA], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function getControlRoomJobPageDefaultSize() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.TEJAS_CARGO:
            return 50;
        default:
            return 10;
    }
}

export function byPassEPODConditionOnJobTimeline() {
    return getFlagValueFromAccountConfig('byPassEPODConditionOnJobTimeline');
}

export function enableTripContinuity() {
    return getFlagValueFromAccountConfig('enableTripContinuity');
}

export function skipDurationCheckForIdlePoints() {
    return includes([8195], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function showGearPredictionDetailsForAccount() {
    return getFlagValueFromAccountConfig('showGearPredictionDetails') && showOBDLinkedModuleDataForAccountEnabled();
}

// export function isPointBasedConsignmentCreation() {
//     return getFlagValueFromAccountConfig('pointBasedConsignmentCreation');
// }

export function DisablePickupAndDeliveryFromFTLConsignment() {
    return getFlagValueFromAccountConfig('DisablePickupAndDeliveryFromFTLConsignment');
}

export function fetchJobNotMappedToTripsheetInServiceHistory() {
    return getFlagValueFromAccountConfig('fetchJobNotMappedToTripsheetInServiceHistory');
}

export const USER_TYPE_FOR_KPI_REPORT = [
    //  'TM Vendor',
    'Plant Head',
    'Regional Incharge Operation',
    'TSM',
    'Production Supervisor',
    'Regional Head Commercial',
    'Zonal Head',
    'RMC Business HO ',
    'City Head ',
];

export function getDefaultSortByInRealtime() {
    const value = getFlagValueFromAccountConfig('defaultSortInRealtime');
    if (value) {
        const [sortVehicleFilter, sortVehicleDirection] = split(value, '::');
        return {
            sortVehicleFilter,
            sortVehicleDirection,
            sortVehicleFilterKey: value,
        };
    }
    return null;
}

export function showDefaultJobReportForAccount() {
    return getFlagValueFromAccountConfig('showDefaultJobReport');
}

export function isDisablePTLFirstMileTracking() {
    return getFlagValueFromAccountConfig('isDisablePTLFirstMileTracking');
}

export function showConnectDisconnectDevice() {
    return getFlagValueFromAccountConfig('showConnectDisconnectDevice');
}

export function isTSMDayWiseReportEnabled() {
    return includes(
        [FLEETX_ACCOUNTS.TATA_STEEL_LIMITED, FLEETX_ACCOUNTS.GRASIM_INBOUND],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export const closeIconForRealtimeSummary = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.PRAKASH_GROUP:
        case FLEETX_ACCOUNTS.KAPOOR_DIESELS:
            return true;
        default:
            return false;
    }
};

export function showContainerNumberField(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
            return true;
        default:
            return false;
    }
}

export function showTempDetailsInTripListForAccount() {
    return getFlagValueFromAccountConfig('showTempDetailsInTripList');
}

export function showJobUpdateHistoryTab() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.EXPRESS_ROADWAYS:
            return true;
        default:
            return false;
    }
}

export function showScheduledArrivalForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GOLDEN_TEMPLE_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function isShriBankeyBihariAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHRI_BANKEY_BIHARI:
            return true;
        default:
            return false;
    }
}

export function disableVehicleIdSearchInVehicleDropdown(loggedInUser) {
    return getFlagValueFromAccountConfig('disableVehicleIdSearchInVehicleDropdown');
}

export function isEnableStationarySupportInFuelLog() {
    return getFlagValueFromAccountConfig('enableStationarySupportInFuelLog');
}

export function preferredOdometerTripRateValue() {
    return getFlagValueFromAccountConfig('preferredOdometerTripRateValue');
}

export function getChecklistOptions() {
    return getFlagValueFromAccountConfig('checklistConfig');
}

export function viewWorkOrderOnRealtimeForAccount() {
    return getFlagValueFromAccountConfig('viewWorkOrderOnRealtime');
}

export function isAccountHUL() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.HUL:
            return true;
        default:
            return false;
    }
}

export function getFreightCalculationPriority1() {
    return getFlagValueFromAccountConfig('freightCalculationPriority1');
}

export function getFreightCalculationPriority2() {
    return getFlagValueFromAccountConfig('freightCalculationPriority2');
}

export function getFreightCalculationPriority3() {
    return getFlagValueFromAccountConfig('freightCalculationPriority3');
}

export function getFreightCalculationPriority4() {
    return getFlagValueFromAccountConfig('freightCalculationPriority4');
}

export function isEventsBookDashboardEnabled() {
    return includes([FLEETX_ACCOUNTS.VARUNA], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function getRemoveColumnMappingForAccount() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId')) {
        case FLEETX_ACCOUNTS.OM_JAY_FACILITY:
            return omJayFacilityRemoveColumnsMapping;
        default:
            return [];
    }
}

export function getDisconnectName() {
    if (getFlagValueFromAccountConfig('disconnectStatusNamePowerDisconnect')) {
        return 'powerDisconnect';
    }
    return 'disconnect';
}

export function getDisconnectColor() {
    if (getFlagValueFromAccountConfig('disconnectStatusColorIsOrange')) {
        return 'text-warning';
    }
    return 'text-danger';
}

export function isMiaharRakeAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.MAIHAR_RAKE:
            return true;
        default:
            return false;
    }
}

export function isIntercityCareersAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.INTERCITY_CARRIERS:
            return true;
        default:
            return false;
    }
}

export function isCurrentTimestampAllowedForBillingDate(loggedInUser) {
    return getFlagValueFromAccountConfig('isCurrentTimestampAllowedForBillingDate');
}

export function showOdometerReadingInHistoryReport() {
    return getFlagValueFromAccountConfig('showOdometerReadingInHistoryReport');
}

export function showAddDataInIndents(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function showCustomerFilterIndents(accountId) {
    switch (accountId) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function isShreemanLogisticsAccount(loggedInUser) {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.SHREEMAN_LOGISTICS:
            return true;
        default:
            return false;
    }
}

export function showEwayBillAsArrayInConsignment() {
    return getFlagValueFromAccountConfig('showEwayBillAsArrayInConsignment');
}

export function showManifestIndents(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return false;
        default:
            return true;
    }
}

export function showJobCheckListDashboard() {
    return getFlagValueFromAccountConfig('showJobCheckListDashboard');
}

export function includeWithoutDriverTransactionInTripsheet() {
    return getFlagValueFromAccountConfig('includeWithoutDriverTransactionInTripsheet');
}

export function isShowAllVehicleRemindersEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('realtimeReminderConfig');
    //return loggedInUser && includes([FLEETX_ACCOUNTS.FENESTA], loggedInUser.accountId);
}

export function isJobTimelineChangesForCustomersEnabled(loggedInUser) {
    return getFlagValueFromAccountConfig('showTimelineChangesForCustomerLogin');
}

export function showImeiDetailsInSensorAndTrackingTab() {
    return getFlagValueFromAccountConfig('showImeiDetailsInSensorAndTracking');
}

export function isManualTaxCalculationForTyre() {
    return getFlagValueFromAccountConfig('manualTaxCalculationForTyre');
}

export function isDriverWiseLedgerEntry() {
    return getFlagValueFromAccountConfig('enableDriverWiseLedgerEntry');
}

export function shouldShowAssociateVendorInCN() {
    return getFlagValueFromAccountConfig('showAssociateVendorInCN');
}

export function showEPODCompletedCNInPaymentReceipt() {
    return getFlagValueFromAccountConfig('epodCompletedCnInPaymentReceipt');
}

export function isLedgerbasedtripsheetDriverBalance() {
    return getFlagValueFromAccountConfig('enableLedgerBasedtripsheetDriverBalance');
}

export function showIndentReportToggle(accountId) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.MAERSK:
        case FLEETX_ACCOUNTS.MAERSK_BANGLADESH:
        case FLEETX_ACCOUNTS.MAERSK_SRI_LANKA:
            return true;
        default:
            return false;
    }
}

export function showOpenBiddingInAuction() {
    return getFlagValueFromAccountConfig('showOpenBiddingInAuction');
}

export function multipleFreightBillForCN() {
    return getFlagValueFromAccountConfig('multipleFreightBillForCN');
}

export function enableCustomerDetentionReport() {
    return getFlagValueFromAccountConfig('enableCustomerDetentionReport');
}

export function isTransactionApprovalOldFlowEnabled() {
    return getFlagValueFromAccountConfig('enableTransactionOldApprovalFlow');
}

export function shouldDownloadPrintFromRapp() {
    return true;
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    return isViewConsignmentsAllowedForUser(scope) || isViewIssuesAllowedForUser(scope);
}

export function isCNOrMaterialBasedEInvoicing() {
    return getFlagValueFromAccountConfig('CNOrMaterialBasedEInvoicing');
}

export function showPointOfContact(accountId = 0) {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', accountId)) {
        case FLEETX_ACCOUNTS.RMC:
            return true;
        default:
            return false;
    }
}

export function isEpodBulkUploaderEnable() {
    return getFlagValueFromAccountConfig('showBulkEpodConsignment');
}

export function isRouteBudgetMandatoryInJobForm() {
    return getFlagValueFromAccountConfig('routeBudgetMandatoryInJobForm');
}

export function isJobMandatoryInTransactionForm() {
    return getFlagValueFromAccountConfig('jobMandatoryInTransactionForm');
}

export function showFleetxEntityId(loggedInUser) {
    switch (get(loggedInUser, 'accountId', get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0))) {
        case FLEETX_ACCOUNTS.SGRG_INFRA_SOLUTIONS:
        case FLEETX_ACCOUNTS.YADAV_GOLDEN_CARRIER:
            return true;
        default:
            return false;
    }
}

export function showVehicleReconciliationReportButtonEnbled() {
    return getFlagValueFromAccountConfig('showVehicleReconciliationReportButton');
}

export const showAccidentTagToAccount = () => {
    return getFlagValueFromAccountConfig('showAccidentTagToAccount');
};

export function isPopUpHiddenForUser(loggedInUser) {
    return (
        loggedInUser &&
        (includes(
            [
                795104, // dhruve automation
                965866, // Abhi
                839158, // Karan
                1945558, // nawab
            ],
            get(loggedInUser, 'id', 0)
        ) ||
            includes([14454], get(loggedInUser, 'accountId', 0)))
    );
}

export function issueFuelFromTransaction() {
    return getFlagValueFromAccountConfig('issueFuelFromTransaction');
}

export function isEmailVisibleToUser(loggedInUser) {
    switch (get(loggedInUser, 'accountId', get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0))) {
        case FLEETX_ACCOUNTS.CHANDERIA:
            return true;
        default:
            return false;
    }
}

export function showDetentionAnalytics() {
    return getFlagValueFromAccountConfig('showDetentionAnalytics');
}
export function getMappedVehicleDocumentTypes() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KEY_SIM_LOGISTICS:
        case FLEETX_ACCOUNTS.FLEETX:
            return mappedAdditionalVehicleDocumentTypes();
        default:
            return mappedVehicleDocumentTypes();
    }
}

export function vehicleTypeOptions() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.KEY_SIM_LOGISTICS:
        case FLEETX_ACCOUNTS.FLEETX:
            return VEHICLE_DOCUMENT_TYPES__ADDITIONAL_OPTIONS;
        default:
            return VEHICLE_DOCUMENT_TYPES_OPTIONS;
    }
}

export function showDtcOnRealtime() {
    return getFlagValueFromAccountConfig('showDtcOnRealtime');
}

export function isSuperIndia(loggedInUser) {
    return loggedInUser && get(loggedInUser, 'accountId', 0) === 11719;
}

export function showJobRefreshButton() {
    return getFlagValueFromAccountConfig('showJobRefreshButton');
}

export function isChargeHeadBasedCalculationInVehicleDocuments() {
    return getFlagValueFromAccountConfig('chargeHeadBasedCalculationInVehicleDocuments');
}

export function payAsYouGoReportVisible() {
    return getFlagValueFromAccountConfig('showPayAsYouGoReport');
}

export function isApprovedTransactionInTripsheet() {
    return getFlagValueFromAccountConfig('approvedTransactionInTripsheet');
}

export function acTemperatureReportVisible() {
    return getFlagValueFromAccountConfig('showACTemperatureReport');
}

export function routeSequenceReportVisible() {
    return getFlagValueFromAccountConfig('showRouteSequenceReport');
}

export function showNearestAddressOnHover() {
    return getFlagValueFromAccountConfig('showNearestAddressOnHover');
}

export function incomingAlertsOnJobCreationFromCN() {
    return getFlagValueFromAccountConfig('incomingAlertsOnJobCreationFromCN');
}

export function isShowDriverTagsVisible() {
    return getFlagValueFromAccountConfig('showDriverTagsVisible');
}

export function isFuelV3VehicleFuelSensorOnlyEnabled() {
    return getFlagValueFromAccountConfig('fuelV3VehicleFuelSensorOnly');
}

export const isOrderModuleFieldsChanged = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case 13518:
            return true;
        default:
            return false;
    }
};

export function isShreeCementRMCAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.SHREE_CEMNET_RMC;
}

export const getDownloadDuration = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case 13179:
        case 813:
            return 90;
        default:
            return 20;
    }
};

export const showLocationOfLastGeofenceExitAlarm = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case 13730:
            return true;
        default:
            return false;
    }
};

export function isJindalPloyFilmAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.JINDAL_POLYFLIM;
}

export function getAccountId() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    const { role } = window.FLEETX_LOGGED_IN_DATA || {};
    if (role === ROLE_MAP.ROLE_TRANSPORTER.name) {
        const reduxState = getReduxState();
        return get(reduxState, 'local.localTransporterAccount');
    }
    return accountId;
}

export function isOLAElectricAccount() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.OLA_ELECTRIC;
}

export function isShowProjectedFuelEnabled() {
    return getFlagValueFromAccountConfig('ShowProjectedFuel');
}

export function isRecordingLinkAllowedforAccount(loggedInUser) {
    return includes(
        [
            13806,
            681,
            1423,
            9232,
            1975,
            1954,
            2037,
            1971,
            2052,
            2818,
            2051,
            2050,
            12407,
            4264,
            7637,
            2217,
            2026,
            2027,
            14750,
        ],
        get(loggedInUser, 'accountId', 0)
    );
}

export function isMahindraLogistics() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === FLEETX_ACCOUNTS.MAHINDRA_LOGISTICS;
}

export function showVehiclePageAtTransporterSide() {
    return getFlagValueFromAccountConfig('allowAddVehiclesAtTransporterSide');
}

export function showTransactionSecondaryFuel() {
    return getFlagValueFromAccountConfig('transactionSecondaryFuel');
}

export function isJobBasedVehicleInEwayBill() {
    return getFlagValueFromAccountConfig('jobBasedVehicleInEwayBill');
}

export function makeStationaryNumberMandatoryInJobForm() {
    return getFlagValueFromAccountConfig('stationaryNumberMandatoryInJobForm');
}

export function getDefaultTDSRateInTransporterMaster() {
    return getFlagValueFromAccountConfig('defaultTdsRateInTransaporterMaster');
}

export function getDefaultFormsMandatoryForms() {
    return getFlagValueFromAccountConfig('defaultTemplateMandatoryForms');
}

export const showPresentDriversOnly = () => {
    return getFlagValueFromAccountConfig('showPresentDriversOnly');
};

export function isShiRamadasMotorTransport() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) === 13708;
}

export const showTagsUploadModal = (loggedInUser) => {
    switch (get(loggedInUser, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.RMC:
            return false;
        default:
            return true;
    }
};

export const showEpodConsignmentInFreightBill = () => {
    return getFlagValueFromAccountConfig('showEPODConsignmentInFreightBill');
};

export function includeEODForAccounts() {
    switch (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)) {
        case FLEETX_ACCOUNTS.GOLDEN_GLOBES:
        case FLEETX_ACCOUNTS.STRIKE:
            return true;
        default:
            return false;
    }
}
export const shouldUpdateStatusAndExpiryInJobBooking = () => {
    return getFlagValueFromAccountConfig('allowUpdateStatusAndExpiryDateInJobBooking');
};
