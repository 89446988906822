import { getFlagValueFromAccountConfig } from 'utils/account_utils';
export function isChallanApprovalConfigEnabled() {
    return getFlagValueFromAccountConfig('challanApprovalConfigEnabled');
}
export function isConsolidatedEwayBillMandatory() {
    return getFlagValueFromAccountConfig('consolidatedEwayBillMandatory');
}
export function isEPODAutoFillMaterialsEnabled() {
    return getFlagValueFromAccountConfig('autoFillMaterialsFromConsignmentsEpod');
}
export function isIncludeCNDataInTransaction() {
    return getFlagValueFromAccountConfig('includeCNDataInTransaction');
}
export function isShowDriverSalaryInTripSheet() {
    return getFlagValueFromAccountConfig('notIncludeDriverSalaryInTripSheet');
}
export function isNewCollectionsModuleEnable() {
    return getFlagValueFromAccountConfig('enableNewCollectionsModule');
}
