import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual, get, toSafeInteger, find, map, forEach, has, set, filter } from 'lodash';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { handleError } from 'constant';
import { fetchAllMeasurementUnits, fetchPartById, createPart, updatePart, } from 'actions/dashboard/parts_inventory/parts_action';
import { fetchPartCategories } from 'actions/dashboard/parts_inventory/part_category_action';
import { fetchPartMakers } from 'actions/dashboard/parts_inventory/part_maker_action';
import { fetchPartModels } from 'actions/dashboard/parts_inventory/part_model_action';
import { FxTextFieldStackedLabel, FxSelectField, FxTexToNumricStackedLabel } from '../../../../shared';
import * as Yup from 'yup';
import { fetchStores } from 'actions/dashboard/client_stores_actions';
import { mappedClientStores, mappedGstRates, mappedObjectToOptions, mappedOptionsFromArray, mappedPartCategories, mappedPartMakers, mappedPartMeasurementOptions, mappedPartModels, } from '../../../utils/mapping_utils';
import { fetchVendors } from 'actions/dashboard/vendors_action';
import { mappedVendors } from 'utils/form_utils';
import { getPageTitle } from '../../../../../white_label_constants';
import { UNIT_TYPE_TO_UNITS_MAP, UNIT_TYPES, UNIT_TYPES_LABELS } from 'utils/rateChartsUtils';
import { fetchGstRates } from 'actions/dashboard/consignments/gstRatesActions';
import { isViewGstRatesAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { withRoute } from 'components/dashboard/customHocs/withRoute';
import { Button } from 'reactstrap';
import { isInternationalAccount } from 'utils/account_utils';
const addEditSchema = Yup.object().shape({
    name: Yup.string().required('Model Name Required'),
    partMeasurementUnitId: Yup.number().required('Measurement Unit Required').nullable(),
    partCategoryId: Yup.number().required('Category Required').nullable(),
    basicUnitCost: Yup.number().required('Unit Cost Required').nullable(),
    partAge: Yup.number().required('Part Age Required'),
    codeNumber: Yup.string().required('Code Number Required'),
    partStoreThresholdList: Yup.array().of(Yup.object().shape({
        storeId: Yup.number().required('Store Required').nullable(),
        thresholdValue: Yup.number()
            .required('Threshold Required')
            .min(0, 'Threshold should be greater than 0')
            .nullable(),
    })),
});
class AddEditPartModelComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            error: '',
            storesOptions: [],
            vendorOptions: [],
            partCategoriesOptions: [],
            partMakerOptions: [],
            partModelOptions: [],
            partMeasurementUnitOptions: [],
            isEditMode: false,
            id: 0,
            initialValues: {
                name: '',
                description: '',
                partMeasurementUnitId: '',
                basicUnitCost: '',
                codeNumber: '',
                partAge: '',
                partCategoryId: '',
                unitType: '',
                partStoreThresholdList: [],
            },
        };
    }
    renderFormBody = (props) => {
        const { values, isSubmitting, setFieldValue, errors } = props;
        const { partCategoriesOptions, partMakerOptions, partModelOptions } = this.state;
        const partThresholdList = get(values, 'partStoreThresholdList', []);
        return (_jsxs(Form, { children: [_jsxs("div", { className: "form-group", children: [_jsx("div", { className: "row", children: _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "name", type: "text", isRequired: "true", component: FxTextFieldStackedLabel, label: "Name" }) }) }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "unitType", options: mappedObjectToOptions(UNIT_TYPES, UNIT_TYPES_LABELS), component: FxSelectField, label: "Unit Type", onChange: () => {
                                            setFieldValue('partMeasurementUnitId', '');
                                        } }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "partMeasurementUnitId", options: this.getUnitOptions(get(values, 'unitType', '')), component: FxSelectField, label: "Measurement Unit", isRequired: true }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "partCategoryId", options: partCategoriesOptions, component: FxSelectField, label: "Category", isRequired: true }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "partModelId", options: partModelOptions, component: FxSelectField, label: "Model" }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "partMakerId", options: partMakerOptions, component: FxSelectField, label: "Maker" }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "basicUnitCost", 
                                        // type="number"
                                        step: "any", component: FxTexToNumricStackedLabel, label: "Enter Unit Cost", isRequired: true }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "codeNumber", type: "text", component: FxTextFieldStackedLabel, label: "Enter Code Number", isRequired: true }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "description", type: "text", component: FxTextFieldStackedLabel, label: "Description" }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "partAge", 
                                        // type="number"
                                        min: 0, component: FxTexToNumricStackedLabel, label: "Enter Part Age(Days)", isRequired: true }) }), isViewGstRatesAllowedForUser(this.props.scope) && (_jsx("div", { className: "col-md-6", children: _jsx(Field, { name: "gstRateId", options: mappedGstRates(this.props.gstRates), component: FxSelectField, label: `${isInternationalAccount() ? 'Tax' : 'GST'} Rate` }) }))] }), _jsx("div", { className: "mt-4", children: _jsx(FieldArray, { name: "partStoreThresholdList", children: ({ push, remove }) => {
                                    return (_jsxs(_Fragment, { children: [_jsx("div", { children: map(partThresholdList, (store, index) => {
                                                    const partThresholdList = get(values, 'partStoreThresholdList', []);
                                                    const storeOptions = filter(this.state.storesOptions, (store) => {
                                                        let res = true;
                                                        for (let i = 0; i < partThresholdList.length; i++) {
                                                            if (i === index)
                                                                continue;
                                                            if (store.value === partThresholdList[i].storeId)
                                                                res = false;
                                                        }
                                                        return res;
                                                    });
                                                    return (_jsxs(_Fragment, { children: [_jsxs("h6", { children: ["Store Threshold ", index + 1] }), _jsx("hr", {}), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `partStoreThresholdList[${index}].storeId`, component: FxSelectField, label: "Store", options: storeOptions, isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `partStoreThresholdList[${index}].thresholdValue`, component: FxTextFieldStackedLabel, label: "Threshold", type: "number", isRequired: true }) }), _jsx("div", { className: "col-md-1", style: { marginTop: '1.8rem' }, children: _jsx("button", { type: "button", className: "btn btn-danger", onClick: () => remove(index), children: _jsx("i", { className: "fa fa-trash" }) }) })] }, `partThreshold-${index}`)] }));
                                                }) }), _jsx("div", { className: "row justify-content-center", children: _jsx(Button, { type: "button", color: "secondary", onClick: () => push({ storeId: null, thresholdValue: null }), children: "Add Store Threshold" }) })] }));
                                } }) })] }), _jsx("hr", {}), this.state.error && (_jsx("div", { className: "my-2 text-danger text-center", children: _jsx("strong", { children: this.state.error }) })), _jsxs("div", { className: "mb-2 float-right", children: [_jsx("button", { disabled: isSubmitting, type: "submit", className: "btn btn-primary mr-2", children: "Submit" }), _jsx("button", { type: "button", className: "btn btn-secondary", onClick: this.goBack, children: "Cancel" })] })] }));
    };
    getUnitTypeFromUnit = (name) => {
        let unitKey;
        forEach(UNIT_TYPE_TO_UNITS_MAP, (units, parentKey) => {
            if (has(units, name)) {
                unitKey = parentKey;
            }
        });
        return unitKey;
    };
    getUnitOptions = (unitType) => {
        const units = get(UNIT_TYPE_TO_UNITS_MAP, unitType, {});
        return mappedOptionsFromArray(map(units, (u) => {
            return find(this.state.partMeasurementUnitOptions, (unit) => {
                return u === unit.label;
            });
        }), 'label', 'value');
    };
    onSubmit = (values, action) => {
        const { setSubmitting } = action;
        if (this.state.isEditMode) {
            this.props
                .updatePart(this.props.accesstoken, values)
                .then((result) => {
                setSubmitting(false);
                if (!result.error) {
                    this.props.navigate('/dashboard/settings/parts');
                }
                else {
                    throw new Error(handleError(result.response));
                }
            })
                .catch((err) => {
                setSubmitting(false);
                this.setState({
                    error: handleError(err),
                });
            });
        }
        else {
            this.props
                .createPart(this.props.accesstoken, values)
                .then((result) => {
                setSubmitting(false);
                if (!result.error) {
                    if (this.props.onSuccessfulPartCreation) {
                        this.props.onSuccessfulPartCreation();
                    }
                    else {
                        this.props.navigate('/dashboard/settings/parts');
                    }
                }
                else {
                    throw new Error(handleError(result.payload.response));
                }
            })
                .catch((err) => {
                setSubmitting(false);
                this.setState({
                    error: handleError(err),
                });
            });
        }
    };
    render() {
        return (_jsx("div", { className: "card-group mt-4 mb-0", children: _jsx("div", { className: "card p-1", children: _jsxs("div", { className: "card-block", children: [_jsx(Helmet, { title: getPageTitle(`${this.state.isEditMode ? 'Edit' : 'Add'} Part`) }), _jsxs("h4", { children: [this.state.isEditMode ? 'Edit' : 'Add', " Part"] }), _jsx("hr", {}), _jsx(Formik, { initialValues: this.state.initialValues, onSubmit: this.onSubmit, component: this.renderFormBody, validationSchema: addEditSchema, enableReinitialize: true })] }) }) }));
    }
    goBack = () => {
        this.props.navigate(-1);
    };
    componentDidMount() {
        const { id } = this.props.params;
        const { accesstoken } = this.props;
        if (id) {
            this.setState({
                isEditMode: true,
                id: toSafeInteger(id),
            });
            this.props.fetchPartById(accesstoken, id).then((result) => {
                if (!result.error) {
                    const data = result.payload.data;
                    let unitType = this.getUnitTypeFromUnit(get(data, 'partMeasurementUnit.name'));
                    set(data, 'unitType', unitType);
                    this.setState({
                        initialValues: data,
                    });
                }
            });
        }
        this.props.fetchAllMeasurementUnits(accesstoken).then((result) => {
            if (!result.error) {
                this.setState({
                    partMeasurementUnitOptions: mappedPartMeasurementOptions(get(result, 'payload.data', [])),
                });
            }
        });
        this.props.fetchPartCategories(accesstoken).then((result) => {
            if (!result.error) {
                this.setState({ partCategoriesOptions: mappedPartCategories(get(result, 'payload.data', [])) });
            }
        });
        this.props.fetchPartMakers(accesstoken).then((result) => {
            if (!result.error) {
                this.setState({ partMakerOptions: mappedPartMakers(get(result, 'payload.data', [])) });
            }
        });
        this.props.fetchPartModels(accesstoken).then((result) => {
            if (!result.error) {
                this.setState({ partModelOptions: mappedPartModels(get(result, 'payload.data', [])) });
            }
        });
        this.props.fetchStores(accesstoken).then((result) => {
            if (!result.error) {
                this.setState({ storesOptions: mappedClientStores(get(result, 'payload.data.content', [])) });
            }
        });
        this.props.fetchVendors(accesstoken).then((result) => {
            this.setState({ vendorOptions: mappedVendors(get(result, 'payload.data.content', [])) });
        });
        if (isViewGstRatesAllowedForUser(this.props.scope)) {
            this.props.fetchGstRates(accesstoken);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        return !isEqual(nextState, this.state);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createPart,
        updatePart,
        fetchPartById,
        fetchAllMeasurementUnits,
        fetchPartCategories,
        fetchPartMakers,
        fetchPartModels,
        fetchStores,
        fetchVendors,
        fetchGstRates,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        part: state.partsInventory.part,
        isLoading: state.partsInventory.isPartListLoading,
        login: state.login.data,
        scope: get(state, 'login.data.scope'),
        gstRates: state.gstRate.gstRates,
    };
}
export default withRoute(connect(mapStateToProps, mapDispatchToProps)(AddEditPartModelComponent));
