import axios, { CancelToken } from 'axios';
import { setJobBookingEndDate } from 'utils/account_utils';
import { parseQueryParams, ROOT_API_ALFRED, ROOT_API_URL } from '../../../constant';

export const ADD_JOB_BOOKING = 'add_job_booking';
export const UPDATE_JOB_BOOKING = 'update_job_booking';
export const FETCH_JOB_BOOKING = 'fetch_job_booking';
export const FETCH_JOB_BOOKING_WITH_SUMMARY = 'fetch_job_booking_with_summary';
export const FETCH_JOB_BOOKING_SUMMARY = 'fetch_job_booking_summary';
export const FETCH_JOB_BOOKING_LISTING = 'fetch_job_booking_listing';
export const FETCH_VENDOR_BY_TYPE = 'fetch_vendor_by_type';
export const DELETE_JOB_BOOKING = 'delete_job_booking';
export const UPDATE_JOB_BOOKING_STATUS = 'update_job_booking_status';
export const UPDATE_VENDOR = 'update_vendor';
export const FETCH_DISPTACH_PLANNING = 'fetch_dispatch_planning';
export const FETCH_MINI_JOB_BOOKING_LISTING = 'fetch_mini_dispatch_planning';
export const FETCH_JOB_BOOKING_AUTOCOMPLETE = 'fetch_job_booking_autocomplete';
export const UPDATE_JOB_BOOKING_END_DATE = 'update_job_booking_end_date';
export const UPDATE_JOB_BOOKING_STATUS_V2 = 'update_job_booking_end_status-v2';

export function addJobBooking(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}booking/`, values, config);
    return {
        type: ADD_JOB_BOOKING,
        promise: request,
    };
}

export function updateJobBooking(accesstoken, id, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}booking/${id}`, values, config);
    return {
        type: UPDATE_JOB_BOOKING,
        promise: request,
    };
}

export function fetchJobBooking(accesstoken, id, accountId = null) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}booking/${id}`, config);
    return {
        type: FETCH_JOB_BOOKING,
        promise: request,
    };
}

export function fetchDisptachPlanning(accesstoken, routeName, vehicleSizeId, noOfVehicles, customerId, cargoType) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            routeName,
            vehicleSizeId,
            noOfVehicles,
            customerId,
            cargoType,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/customer/contract/dispatch/planning`, config);
    return {
        type: FETCH_DISPTACH_PLANNING,
        promise: request,
    };
}

export function fetchVendor(accesstoken, type) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        // params: parseQueryParams({
        //     type,
        // }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/vendor`, config);
    return {
        type: FETCH_VENDOR_BY_TYPE,
        promise: request,
    };
}

export function updateVendor(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}dispatch/vendor`, values, config);
    return {
        type: UPDATE_VENDOR,
        promise: request,
    };
}

export function fetchJobBookingWithSummary(accesstoken, from, to, customerId, transporterId, createdBy, status) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            customerId,
            transporterId,
            createdBy,
            status,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}booking/`, config);
    return {
        type: FETCH_JOB_BOOKING_WITH_SUMMARY,
        promise: request,
    };
}

export function fetchJobBookingAutoComplete(accesstoken, from, to, excludeCompletedJobBookingInJobCreate = null) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            excludeCompletedJobBooking: excludeCompletedJobBookingInJobCreate ? true : null,
            excludeJobBookingByEndDate: setJobBookingEndDate() ? true : null,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}booking/autocomplete`, config);
    return {
        type: FETCH_JOB_BOOKING_AUTOCOMPLETE,
        promise: request,
    };
}

export function deleteJobBooking(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}booking/${id}`, config);
    return {
        type: DELETE_JOB_BOOKING,
        promise: request,
    };
}
export function updateJobBookingStatus(accesstoken, id, isRefused, reason) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            isRefused,
            reason,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}booking/${id}/refusal`, null, config);
    return {
        type: UPDATE_JOB_BOOKING_STATUS,
        promise: request,
    };
}

export function fetchJobBookingSummary(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}booking/summary`, config);
    return {
        type: FETCH_JOB_BOOKING_SUMMARY,
        promise: request,
    };
}

export function fetchJobBookingList(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}booking/search`, config);
    return {
        type: FETCH_JOB_BOOKING_LISTING,
        promise: request,
    };
}

export function fetchMiniJobBookingList(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}booking/autocomplete`, config);
    return {
        type: FETCH_MINI_JOB_BOOKING_LISTING,
        promise: request,
    };
}
export function updateJobBookingEndDate(id, params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.patch(`${ROOT_API_URL}booking/update-end-date/${id}`, {}, config);
    return {
        type: UPDATE_JOB_BOOKING_END_DATE,
        promise: request,
    };
}
export function updateJobBookingStatusv2(id, params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.patch(`${ROOT_API_URL}booking/update-status/${id}`, {}, config);
    return {
        type: UPDATE_JOB_BOOKING_STATUS_V2,
        promise: request,
    };
}
