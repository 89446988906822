import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { refreshVideoURLForHowen } from 'actions/dashboard/telematics_action';
import { Detail, formatYAxis } from 'components/dashboard/report_section/fuel_level_v3/Utils';
import { parseAddress, parseQueryParams, pushUrl } from 'constant';
import { capitalize, find, forEach, get, isEmpty, isUndefined, map, round, set, toLower, toNumber } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { FxButton, FxCol, FxFlex, FxSelect, FxTable, FxTypography } from 'sharedV2';
import FxTooltip from 'sharedV2/FxTooltip';
import styled from 'styled-components';
import { showVideoTelematics } from 'utils/account_utils';
import { getAlarmDetail } from 'utils/alarm_utils';
import { DATE_FORMAT_UNIX_TIMESTAMP, getHumanizeTime, getMomentTime, HOURS_1, MINUTES_1 } from 'utils/date_utils';
import { prepareVtResourceDownloadDataAndSaveInBigquery } from 'utils/logging_utils';
import { GEOCODER, getGeoCodedLocation, getNearestAddressFromAddressbook, isInsideNearestAddress, } from 'utils/map/geocode_utils';
import { getGoogleMapLink } from 'utils/map/map_utils';
import { COLOR_ERROR, COLOR_PRIMARY, COLOR_SUCCESS, COLOR_WARNING } from '../../../../theme/colors';
import { showVtDownloadButton } from 'utils/accountUtils/vtUtils';
import { themeMode } from '../../../../theme';
import { getCurrentThemeMode } from '../../../../theme/utils';
export const TABS = {
    ANALYTICS: 'ANALYTICS',
    LISTING: 'LISTING',
};
export const FREQUENCY_GRAPH_TYPES = {
    VEHICLES: 'VEHICLE',
    DRIVERS: 'DRIVER',
    GROUPS: 'GROUP',
    TRANSPORTERS: 'TRANSPORTER',
};
export const HealthSeverity = {
    NEUTRAL: 'NEUTRAL',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
};
export const HealthSeverityColor = {
    NEUTRAL: '#FAF9F8',
    LOW: '#DFF6DD',
    MEDIUM: '#FFF4CE',
    HIGH: '#FDE7E9',
};
export const SAFETY_ALARMS = 'Pedestrian/Small Vehicle Collision Warning, Lane Departure Warning, Calling, Camera Covered, Fatigue Driving(Video), Driver Abnormal, Hard Acceleration(Video), Hard Deceleration(Video), Cellphone Distracted Driving, Forward Collision Warning, Obstacle Alarm, Cornering, Smoking, Distraction, No Visual, Seat Belt, No Driver, Hands Off Steering, Camera Blocked or Blurred, Cargo Movement, Collision Rollover, Lane Deviation, Multiple Person Detected';
export const ShowLessMoreIcon = styled(FxButton) `
    position: absolute;
    bottom: -0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2rem !important;
    padding: 5px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;
export const Table = styled(FxTable) `
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    overflow: hidden;

    .ant-pagination-mini {
        padding-right: 0.5rem;
    }
`;
export const FxColFlex = styled(FxCol) `
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    gap: ${(props) => (props.gap ? props.gap + 'px' : '')};
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : '')};
    justify-content: ${(props) => (props.justify ? props.justify : '')};
    align-items: ${(props) => (props.align ? props.align : '')};
`;
export const TimeAxis = (rangeType) => {
    const isDarkMode = getCurrentThemeMode() === themeMode.dark;
    return (_jsx(XAxis, { stroke: "#999", tick: { fill: isDarkMode ? undefined : '#333' }, dataKey: "time", tickFormatter: (time) => timeFormatter(time, rangeType), height: 20, interval: "preserveStart", padding: { left: 5, right: 10 }, style: {
            fontSize: '10px',
            color: '#333',
        } }));
};
const timeFormatter = (time, rangeType) => getMomentTime(time).format(rangeType === 'HOURS' ? 'HH:ss' : 'DD-MM');
export const DataAxis = (width, yAxisId, tickFormatter) => {
    const isDarkMode = getCurrentThemeMode() === themeMode.dark;
    return (_jsx(YAxis, { stroke: "#999", tick: { fill: isDarkMode ? undefined : '#333' }, tickFormatter: tickFormatter || formatYAxis, width: width || 30, yAxisId: yAxisId, orientation: yAxisId === 'right' ? 'right' : undefined, style: {
            fontSize: '10px',
        } }));
};
export const CustomTooltip = ({ suffix, active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0]?.payload;
        const time = get(data, 'time', '');
        const onJob = round(get(data, 'onJob', 0), 2);
        const withoutJob = round(get(data, 'withoutJob', 0), 2);
        const totalCount = round(get(data, 'totalCount', 0), 2);
        return (_jsxs("div", { style: {
                padding: '3px',
                background: '#333333',
                fontSize: '10px',
                color: '#fff',
                borderRadius: '3px',
            }, children: [!!time && _jsxs(Detail, { children: ["Time: ", getHumanizeTime(time)] }), !isUndefined(onJob) && (_jsxs(Detail, { children: ["On Job: ", onJob, " ", suffix] })), !isUndefined(withoutJob) && (_jsxs(Detail, { children: ["Not on Job: ", withoutJob, " ", suffix] })), !isUndefined(totalCount) && (_jsxs(Detail, { children: ["Total: ", totalCount, " ", suffix] }))] }));
    }
    return null;
};
export const AlarmTooltip = (suffix) => (_jsx(Tooltip, { content: _jsx(CustomTooltip, { suffix: suffix }), cursor: { fill: '#FFFFFF59' } }));
const RECHARTS_CARTESIAN_AXIS_TICK_VALUE_SELECTOR = `.recharts-cartesian-axis-tick-value[orientation="left"],
.recharts-cartesian-axis-tick-value[orientation="right"]`;
export const useDynamicYAxisWidth = (props) => {
    const { yAxisWidthModifier } = props || {};
    const [yAxisWidthState, setYAxisWidthState] = useState(undefined);
    const setChartRef = useCallback((chartRef) => {
        if (chartRef != null && chartRef.container != null) {
            const tickValueElements = chartRef.container.querySelectorAll(RECHARTS_CARTESIAN_AXIS_TICK_VALUE_SELECTOR);
            const highestWidth = [...tickValueElements]
                .filter(Boolean)
                .map((el) => {
                const boundingRect = el.getBoundingClientRect();
                if (boundingRect != null && boundingRect.width != null) {
                    return boundingRect.width;
                }
                return 0;
            })
                .reduce((accumulator, value) => {
                if (accumulator < value) {
                    return value;
                }
                return accumulator;
            }, 0);
            setYAxisWidthState(highestWidth);
        }
    }, [setYAxisWidthState]);
    const yAxisWidth = useMemo(() => {
        if (yAxisWidthModifier != null && yAxisWidthState != null) {
            return yAxisWidthModifier(yAxisWidthState);
        }
        return yAxisWidthState;
    }, [yAxisWidthModifier, yAxisWidthState]);
    return {
        yAxisWidth,
        setChartRef,
    };
};
export function getLat(item) {
    return toNumber(get(item, '[0]'));
}
export function getLng(item) {
    return toNumber(get(item, '[1]'));
}
export function getAlarmCount(item, defaultValue = 1) {
    return toNumber(get(item, '[2]', defaultValue));
}
// tslint:disable:no-bitwise
export const darkenColor = (color, percent) => {
    const num = parseInt(color.slice(1), 16);
    const amt = Math.round(-2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${(0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255))
        .toString(16)
        .slice(1)}`;
};
// tslint:enable:no-bitwise
export const getHealthSeverityTextColor = (severity) => {
    switch (severity) {
        case HealthSeverity.LOW:
            return COLOR_SUCCESS;
        case HealthSeverity.MEDIUM:
            return COLOR_WARNING;
        case HealthSeverity.HIGH:
            return COLOR_ERROR;
        default:
            return '#000';
    }
};
export const getHealthSeverityText = (severity) => {
    switch (severity) {
        case HealthSeverity.LOW:
            return 'Good';
        case HealthSeverity.MEDIUM:
            return 'Average';
        case HealthSeverity.HIGH:
            return 'Poor';
        default:
            return (_jsx(FxTooltip, { title: "Choose a shorter time period OR give us some time to gather more data points.", children: "Neutral" }));
    }
};
export const getAlarmDetails = (currentValue, row, loggedInUser) => {
    const type = toLower(get(row, 'type'));
    if (type === 'unknown stoppage') {
        const jobName = get(row, 'jobName', '');
        const jobId = get(row, 'jobId', '');
        return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("span", { className: "font-weight-bold", children: "Duration:" }), ' ', round(get(row, 'currentValue', '')) + ' minutes'] }), jobName && (_jsxs("div", { children: [_jsx("span", { className: "font-weight-bold", children: "Job:" }), " ", jobName, _jsx("a", { href: `/dashboard/routes/jobs/${jobId}`, className: "fa fa-link ml-1", target: "_blank" })] }))] }));
    }
    if (toLower(type) === 'geofence') {
        const geofences = get(row, 'geoFenceNames', '')?.split(/[:-]/);
        const subType = get(row, 'subType', '');
        return (_jsx(_Fragment, { children: _jsxs("div", { style: {
                    maxWidth: '300px',
                }, children: [_jsxs("div", { className: "font-sm", children: ["Geofence", subType ? `: ${capitalize(subType)}` : ''] }), geofences[geofences.length - 1]] }) }));
    }
    const gear = get(row, 'gear', null);
    const rpm = get(row, 'rpm', null);
    const detail = getAlarmDetail(type, currentValue, get(row, 'thresholdValue'), get(row, 'subType'), false, null, row, undefined, loggedInUser);
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: detail }), gear && _jsxs("div", { children: ["Gear: ", gear] }), rpm && _jsxs("div", { children: ["RPM: ", rpm] })] }));
};
export const getMapButton = (timeStamp, row) => {
    const url = [];
    const { type, latitude, longitude } = row;
    pushUrl(url, '/dashboard/trips/', row.vehicleId, getMomentTime(timeStamp).valueOf(), getMomentTime(timeStamp).add(1, 'minute').valueOf(), `&ZOOM_TO_TIME=${getMomentTime(get(row, 'createDate', '')).format(DATE_FORMAT_UNIX_TIMESTAMP)}&REDIRECT_TO_TRIP=true`);
    return (_jsx(FxTypography.Link, { href: "#", onClick: (event) => {
            event.stopPropagation();
            if (['Misuse', 'EV Low Battery'].includes(type)) {
                window.open(getGoogleMapLink(latitude, longitude), '_blank');
            }
            else {
                window.open(`${url[0]}`, '_blank');
            }
        }, children: "View Details" }));
};
const fillSet = new Set(['Fuel Fills', 'numberOfFills', 'Fill']);
const theftSet = new Set(['Fuel Thefts', 'numberOfThefts', 'Theft']);
const pilferageSet = new Set(['Pilferage', 'numberOfPilferage']);
export const getModifiedAlertType = (alarmType, alarmSubType, selectedAlarmType) => {
    let alertTypeModified = alarmType;
    let alertSubTypeModified = alarmSubType;
    if (fillSet.has(alarmType) || fillSet.has(alarmSubType)) {
        if (selectedAlarmType) {
            alertTypeModified = selectedAlarmType;
        }
        else {
            alertTypeModified = 'FuelLevel';
        }
        alertSubTypeModified = 'Fill';
    }
    if (theftSet.has(alarmType) || theftSet.has(alarmSubType)) {
        if (selectedAlarmType) {
            alertTypeModified = selectedAlarmType;
        }
        else {
            alertTypeModified = 'FuelLevel';
        }
        alertSubTypeModified = 'Theft';
    }
    if (pilferageSet.has(alarmType) || pilferageSet.has(alarmSubType)) {
        if (selectedAlarmType) {
            alertTypeModified = selectedAlarmType;
        }
        else {
            alertTypeModified = 'FuelLevel';
        }
        alertSubTypeModified = 'Pilferage';
    }
    if (alarmType === 'Night Driving') {
        alertTypeModified = 'Misuse';
        alertSubTypeModified = 'Night Driving';
    }
    if (alarmType === 'InFlow') {
        alertTypeModified = 'FuelLevel';
        alertSubTypeModified = 'Inflow';
    }
    if (alarmType === 'OutFlow') {
        alertTypeModified = 'FuelLevel';
        alertSubTypeModified = 'Outflow';
    }
    return [alertTypeModified, alertSubTypeModified];
};
const maxAlarms = (districtVsAlarmCountMap) => {
    let maxAlarms = -1;
    forEach(districtVsAlarmCountMap, (count) => {
        if (count > maxAlarms) {
            maxAlarms = count;
        }
    });
    return maxAlarms;
};
export const getPolygonColors = (count, districtVsAlarmCountMap) => {
    const maxCount = maxAlarms(districtVsAlarmCountMap);
    const percentage = (count / maxCount) * 100;
    if (Object.keys(districtVsAlarmCountMap).length === 1) {
        return '#333333';
    }
    if (percentage <= 16.67) {
        return '#107C1080'; // Green
    }
    else if (percentage <= 33.33) {
        return '#85941280'; // Light Green
    }
    else if (percentage <= 50) {
        return '#FAAD1480'; // Yellow
    }
    else if (percentage <= 66.67) {
        return '#F1840F80'; // Orange
    }
    else if (percentage <= 83.33) {
        return '#E4420880'; // Light Red
    }
    else {
        return '#D6000080'; // Red
    }
};
export const initialAlertsDataState = {
    alertsSummary: {
        data: {
            aggregations: [],
            alarms: [],
            insights: undefined,
            severityMap: new Map(),
        },
        loading: false,
        cache: {},
    },
    alarmsGraphCount: {
        data: {
            alarmCountGraph: [],
            heatMap: [],
            districtVsAlarmCountMap: {},
        },
        loading: false,
        cache: {},
    },
    alarmsGraphFrequency: {
        data: {
            alarmFrequencyGraphTypeListMap: {
                [FREQUENCY_GRAPH_TYPES.VEHICLES]: [],
                [FREQUENCY_GRAPH_TYPES.DRIVERS]: [],
                [FREQUENCY_GRAPH_TYPES.GROUPS]: [],
                [FREQUENCY_GRAPH_TYPES.TRANSPORTERS]: [],
            },
            countOfDriversAlarmsOverMinValue: null,
            countOfGroupsAlarmsOverMinValue: null,
            countOfTransportersAlarmsOverMinValue: null,
            countOfVehiclesAlarmsOverMinValue: null,
        },
        loading: false,
        cache: {},
    },
};
export const generateCacheKey = (params) => {
    return JSON.stringify(parseQueryParams(params));
};
export const alertsDataReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ALERTS_SUMMARY_LOADING':
            return { ...state, alertsSummary: { ...state.alertsSummary, loading: action.payload } };
        case 'SET_ALERTS_SUMMARY_DATA':
            return {
                ...state,
                alertsSummary: {
                    data: {
                        aggregations: get(action.payload, 'data.payload.data.aggregations', []),
                        alarms: get(action.payload, 'data.payload.data.alarms', []),
                        insights: get(action.payload, 'data.payload.data.insights'),
                        severityMap: new Map(map(get(action.payload, 'data.payload.data.aggregations', []), (alarm) => [
                            alarm.type,
                            alarm.healthSeverity,
                        ])),
                    },
                    loading: false,
                    cache: {
                        ...state.alertsSummary.cache,
                        [action.payload.cacheKey]: {
                            data: action.payload.data,
                            params: action.payload.cacheKey,
                        },
                    },
                },
            };
        case 'SET_ALARMS_GRAPH_COUNT_LOADING':
            return { ...state, alarmsGraphCount: { ...state.alarmsGraphCount, loading: action.payload } };
        case 'SET_ALARMS_GRAPH_COUNT_DATA':
            return {
                ...state,
                alarmsGraphCount: {
                    data: {
                        alarmCountGraph: get(action.payload, 'data.payload.data.alarmCountGraph', []),
                        heatMap: get(action.payload, 'data.payload.data.heatMap', []),
                        districtVsAlarmCountMap: get(action.payload, 'data.payload.data.districtVsAlarmCountMap', {}),
                    },
                    loading: false,
                    cache: {
                        ...state.alarmsGraphCount.cache,
                        [action.payload.cacheKey]: {
                            data: action.payload.data,
                            params: action.payload.cacheKey,
                        },
                    },
                },
            };
        case 'SET_ALARMS_GRAPH_FREQUENCY_LOADING':
            return { ...state, alarmsGraphFrequency: { ...state.alarmsGraphFrequency, loading: action.payload } };
        case 'SET_ALARMS_GRAPH_FREQUENCY_DATA':
            return {
                ...state,
                alarmsGraphFrequency: {
                    data: {
                        alarmFrequencyGraphTypeListMap: get(action.payload, 'data.payload.data.alarmFrequencyGraphTypeListMap', {}),
                        countOfDriversAlarmsOverMinValue: get(action.payload, 'data.payload.data.countOfDriversAlarmsOverMinValue', null),
                        countOfGroupsAlarmsOverMinValue: get(action.payload, 'data.payload.data.countOfGroupsAlarmsOverMinValue', null),
                        countOfTransportersAlarmsOverMinValue: get(action.payload, 'data.payload.data.countOfTransportersAlarmsOverMinValue', null),
                        countOfVehiclesAlarmsOverMinValue: get(action.payload, 'data.payload.data.countOfVehiclesAlarmsOverMinValue', null),
                    },
                    loading: false,
                    cache: {
                        ...state.alarmsGraphFrequency.cache,
                        [action.payload.cacheKey]: {
                            data: action.payload.data,
                            params: action.payload.cacheKey,
                        },
                    },
                },
            };
        default:
            return state;
    }
};
/*
 * Show request video column
 * @param {*} alertType
 * @returns {boolean}
 */
export const showRequestVideoColumn = (alertType) => {
    if (!showVideoTelematics())
        return false;
    switch (alertType) {
        case 'Quick Lane Change':
        case 'Speeding':
        case 'Cargo Movement':
        case 'High Rpm':
        case 'Sharp Turn':
        case 'Emergency':
        case 'Unknown Stoppage':
        case 'Tail Gating':
        case 'Traffic Speed Violated':
        case 'Distraction':
        case 'Pedestrian Collision Warning':
        case 'FCW Forward relative velocity collision':
        case 'HMW Forward absolute velocity collision':
        case 'Drowsiness':
        case 'Cornering':
        case 'Drowsy Driving':
        case 'Forward Collision Warning':
        case 'Cellphone Distracted Driving':
        case 'No driver':
        case 'Lane Departure Warning':
        case 'Camera Cover':
        case 'Frequent Lane Change':
        case 'Road Sign Limit Breached':
        case 'Obstacle Alarm':
        case 'Fatigue Driving':
        case 'No Visual':
        case 'Check DMS System':
        case 'Seat Belt Alarm':
        case 'Driver Not Detected':
        case 'Hands Off Steering':
        case 'Driver Abnormal':
        case 'Seat Belt':
        case 'Calling':
        case 'Smoking':
        case 'Pedestrian/Small Vehicle Collision Warning':
        case 'Harsh Acceleration':
        case 'Harsh Deceleration':
        case 'Camera Covered':
        case 'Hard Deceleration(Video)':
        case 'Hard Acceleration(Video)':
        case 'Humanoid Detection':
        case 'Driver Authentication':
        case 'Lane Deviation':
            return true;
        default:
            return false;
    }
};
/*
 * Show video play column
 * @param {*} alertType
 * @returns {boolean}
 */
export const showVideoPlayColumn = (alertType) => {
    if (!alertType)
        return true;
    switch (alertType) {
        case 'Hard Acceleration':
        case 'Hard Deceleration':
        case 'Quick Lane Change':
        case 'Speeding':
        case 'High Rpm':
        case 'Sharp Turn':
        case 'Emergency':
        case 'Unknown Stoppage':
        case 'Smoking':
        case 'Yawning':
        case 'Tail Gating':
        case 'Traffic Speed Violated':
        case 'Distraction':
        case 'Pedestrian Collision Warning':
        case 'FCW Forward relative velocity collision':
        case 'HMW Forward absolute velocity collision':
        case 'Drowsiness':
        case 'Cornering':
        case 'Drowsy Driving':
        case 'Forward Collision Warning':
        case 'Lane Departure Darning':
        case 'Cellphone Distracted Driving':
        case 'Camera Cover':
        case 'Frequent Lane Change':
        case 'Road Sign Limit Breached':
        case 'Obstacle Alarm':
        case 'Fatigue Driving':
        case 'No Visual':
        case 'Check DMS System':
        case 'Seat Belt Alarm':
        case 'Driver Not Detected':
        case 'Hands Off Steering':
        case 'Driver Abnormal':
        case 'Seat Belt':
        case 'Calling':
        case 'Pedestrian/Small Vehicle Collision Warning':
        case 'Camera Covered':
        case 'Hard Acc(video)':
        case 'Hard Dec(Video)':
        case 'Hard Deceleration(Video)':
        case 'Hard Acceleration(Video)':
        case 'Cargo Movement':
        case 'Fatigue Driving(Video)':
        case 'Driver abnormal':
        case 'Lane departure Warning':
        case 'Camera Blocked or Blurred':
        case 'Humanoid Detection':
        case 'Driver Authentication':
        case 'Lane Deviation':
        case 'Multiple Person Detected':
            return true;
        default:
            return false;
    }
};
/*
 * Check if link is expired
 * @param {*} row
 * @param {*} refreshedLinkTime
 */
export const getIsLinkExpired = (row) => {
    return (getMomentTime().valueOf() - getMomentTime(get(row, 'otherAttributes.lastLinkRefreshTime')).valueOf() >
        HOURS_1 * 5);
};
/*
 * Check if row is from howen supplier
 * @param {*} row
 */
export const isHowenSupplier = (row) => {
    const set = new Set(['HOWEN', 'LOTIM', 'TRACKSOLID']);
    return set.has(get(row, 'otherAttributes.assets.0.supplier')) || set.has(get(row, 'otherAttributes.deviceType'));
};
/*
 * Get new link for video with Howen supplier
 * @param {*} accesstoken
 * @param {*} v
 * @param {*} fileName
 */
const getNewLinkHowen = async (accesstoken, v, fileName) => {
    const params = {
        vehicleId: get(v, 'vehicleId'),
        timestamp: getMomentTime(get(v, 'timeStamp')).valueOf(),
        fileName,
    };
    // @ts-ignore
    const newLink = await refreshVideoURLForHowen(params, accesstoken).promise;
    return get(newLink, 'data.link');
};
/*
 * Refresh link howen
 * @param {*} accesstoken
 * @param {*} v
 * @param {*} fileName
 * @returns {Promise<any>}
 */
const refreshLinkHowen = async (accesstoken, v, fileName) => {
    const params = {
        vehicleId: get(v, 'vehicleId'),
        timestamp: getMomentTime(get(v, 'timeStamp')).valueOf(),
        fileName,
    };
    // @ts-ignore
    const newLink = await refreshVideoURLForHowen(params, accesstoken).promise;
    if (get(newLink, 'data.link')) {
        return get(newLink, 'data.link');
    }
};
/*
 * Set sDate and eDate for the row
 * @param {*} row
 */
const setSDates = (row) => {
    const createdDate = get(row, 'timeStamp');
    const sDate = getMomentTime(createdDate).valueOf() - MINUTES_1;
    const eDate = getMomentTime(createdDate).valueOf() + MINUTES_1;
    row = set(row, 'sDate', sDate);
    row = set(row, 'eDate', eDate);
    return row;
};
/*
 * Set dvrTypeId for the row
 * @param {*} row
 */
const setDvrTypeId = (row) => {
    const type = toLower(get(row, 'type')).trim();
    const alarmTimeStamp = getMomentTime(get(row, 'timeStamp')).valueOf();
    const vehicleNumber = get(row, 'vehicleNumber');
    const dvrTypeId = `${type}-${alarmTimeStamp}-${vehicleNumber}`;
    row = set(row, 'dvrTypeId', dvrTypeId);
    return row;
};
/*
 * Set state for new request
 * @param {*} e
 * @param {*} row
 * @param {*} state
 * @param {*} setState
 */
const onNewRequest = (e, row, state, setState) => {
    e.preventDefault();
    e.stopPropagation();
    row = setSDates(row);
    row = setDvrTypeId(row);
    const cameraType = find(get(row, 'otherAttributes.assets', []), { type: 'CAMERA' });
    setState({
        requestNewVideo: !state.requestNewVideo,
        selectedVideoRow: row,
        selectedVideoCameraSupplier: get(cameraType, 'supplier', '-'),
    });
};
/*
 * Set state for available request
 * @param {*} e
 * @param {*} row
 * @param {*} state
 * @param {*} setState
 */
const onAvailableRequest = (e, row, state, setState) => {
    e.preventDefault();
    e.stopPropagation();
    setSDates(row);
    setDvrTypeId(row);
    const cameraType = find(get(row, 'otherAttributes.assets', []), { type: 'CAMERA' });
    setState({
        showAvailableVideos: !state.showAvailableVideos,
        selectedVideoRow: row,
        selectedVideoCameraSupplier: get(cameraType, 'supplier', '-'),
    });
};
/*
 * Download video
 * @param {*} accesstoken
 * @param {*} link
 * @param {*} isLinkExpired
 * @param {*} v
 * @param {*} isHowenSupplier
 * @param {*} fileName
 */
const onVideoDownload = async (accesstoken, link, v, isHowenSupplier, fileName) => {
    const isLinkExpired = getIsLinkExpired(v);
    if (isLinkExpired && isHowenSupplier) {
        const newLink = await getNewLinkHowen(accesstoken, v, fileName);
        if (newLink) {
            window.open(newLink, '_blank');
            prepareVtResourceDownloadDataAndSaveInBigquery(newLink, 'alarmAnalytic');
        }
    }
    else {
        window.open(link, '_blank');
        prepareVtResourceDownloadDataAndSaveInBigquery(link, 'alarmAnalytics');
    }
};
/*
 * Play video
 * @param {*} accesstoken
 * @param {*} link
 * @param {*} isLinkExpired
 * @param {*} token
 * @param {*} v
 * @param {*} fileName
 * @param {*} onPlay
 */
const playVideo = async (accesstoken, link, v, fileName, onPlay) => {
    const isLinkExpired = getIsLinkExpired(v);
    const isHowen = isHowenSupplier(v);
    if (isLinkExpired && isHowen) {
        const newLink = await refreshLinkHowen(accesstoken, v, fileName);
        if (newLink) {
            onPlay(newLink);
        }
    }
    else {
        onPlay(link);
    }
};
/*
 * Set state for image clicked
 * @param {*} accesstoken
 * @param {*} selectedImage
 * @param {*} v
 * @param {*} isHowenSupplier
 * @param {*} evidenceName
 * @param {*} setState
 */
const imageClicked = async (accesstoken, selectedImages, v, setState, fetchAlerts) => {
    if (isHowenSupplier(v)) {
        const isLinkExpired = getIsLinkExpired(v);
        if (isLinkExpired) {
            setState({
                isImageRefreshLoading: true,
            });
            const evidenceFN = get(v, 'otherAttributes.videoLink', [])[0]?.evidenceFileName;
            // @ts-ignore
            const newLink = evidenceFN && (await refreshLinkHowen(accesstoken, v, evidenceFN));
            if (newLink) {
                setState({
                    selectedImages: [newLink],
                    isVideoOpen: true,
                    isImageRefreshLoading: false,
                });
            }
            setTimeout(() => {
                fetchAlerts();
            }, 3000);
        }
        else {
            setState({
                selectedImages,
                isVideoOpen: true,
            });
        }
    }
};
/*
 * Show request video button
 * @param {*} row
 * @param {*} state
 * @param {*} setState
 */
export const showRequestVideoButton = (row, state, setState) => {
    return (_jsxs(FxFlex, { vertical: true, children: [_jsx(FxButton, { type: "link", onClick: (e) => onNewRequest(e, row, state, setState), children: "New Request" }), _jsx(FxButton, { type: "link", onClick: (e) => onAvailableRequest(e, row, state, setState), children: "Available Videos" })] }));
};
const getAlarmData = (row) => {
    const videoLinks = get(row, 'otherAttributes.videoLink', []);
    let firstImage = '';
    const images = [];
    let video = '';
    forEach(videoLinks, (v, index) => {
        const evidenceName = get(v, 'evidenceFileName', '');
        const evidenceLink = get(v, 'evidenceLink', '');
        if (evidenceName.includes('.jpeg') || evidenceName.includes('.JPEG')) {
            if (index === 0) {
                firstImage = evidenceLink;
            }
            images.push(evidenceLink);
        }
        else if (evidenceName.includes('.mp4') || evidenceName.includes('.MP4')) {
            video = v;
        }
    });
    return {
        firstImage,
        images,
        video,
    };
};
/*
 * Show alarm video button
 * @param {*} accesstoken
 * @param {*} row
 * @param {*} setState
 */
export const showAlarmVideoButton = (accesstoken, row, setState, fetchAlerts, state) => {
    const evidenceName = get(row, 'otherAttributes.videoLink', [])[0]?.evidenceFileName;
    const evidenceLink = get(row, 'otherAttributes.videoLink', [])[0]?.evidenceLink;
    if (!isHowenSupplier(row) && evidenceLink) {
        return (_jsx(FxFlex, { align: 'center', vertical: true, children: _jsx("i", { style: { color: COLOR_PRIMARY }, className: "fa fa-download cursor-pointer", onClick: () => {
                    onVideoDownload(accesstoken, evidenceLink, row, true, 'Third');
                } }) }));
    }
    if (isHowenSupplier(row)) {
        const { firstImage, images, video } = getAlarmData(row);
        const isLinkExpired = getIsLinkExpired(row);
        return (_jsx(_Fragment, { children: _jsxs(FxFlex, { align: "center", vertical: true, children: [!isEmpty(firstImage) && !isLinkExpired && (_jsxs(_Fragment, { children: [' ', _jsx("img", { alt: "First image", src: firstImage, className: "mb-3 cursor-pointer", style: {
                                    maxHeight: '75px',
                                    maxWidth: '120px',
                                    border: '1px red solid',
                                    margin: '6px',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                }, onClick: () => imageClicked(accesstoken, images, row, setState, null) }), _jsx("div", { className: "d-flex justify-content-center", style: { width: '100%', marginTop: -10 }, children: _jsx("span", { children: `Images Count : ${images.length}` }) })] })), !isEmpty(video) && (_jsxs(FxFlex, { justify: "center", align: "center", gap: 16, children: [_jsx("i", { style: { color: COLOR_PRIMARY }, className: "fa fa-play cursor-pointer", onClick: () => playVideo(accesstoken, get(video, 'evidenceLink'), row, get(video, 'evidenceFileName', ''), (videoLink) => setState({
                                    showVideo: true,
                                    videoLink,
                                })) }), showVtDownloadButton() && (_jsx("i", { style: { color: COLOR_PRIMARY }, className: "fa fa-download cursor-pointer", onClick: () => {
                                    onVideoDownload(accesstoken, get(video, 'evidenceLink'), row, true, get(video, 'evidenceFileName', ''));
                                } })), _jsx("i", { style: { color: COLOR_PRIMARY }, className: "fa fa-share-alt cursor-pointer", onClick: () => setState({
                                    openShareModal: true,
                                    shareLinkData: row,
                                    shareAlarmName: get(row, 'type'),
                                }) })] })), isLinkExpired && !isEmpty(firstImage) && isEmpty(video) && (_jsx(FxFlex, { justify: "center", align: "center", onClick: () => {
                            if (state?.isImageRefreshLoading)
                                return;
                            imageClicked(accesstoken, images, row, setState, fetchAlerts);
                        }, className: "cursor-pointer", children: _jsx("i", { style: { color: '#4dbd74' }, className: 'fa fa-refresh fa-2x cursor-pointer ' }) }))] }) }));
    }
    // Non-Howen supplier logic
    const link = get(row, 'otherAttributes.videoLink');
    if (!link)
        return _jsx(_Fragment, {});
    return (_jsx(FxFlex, { justify: "center", align: "center", onClick: () => playVideo(accesstoken, link, row, evidenceName, (videoLink) => {
            setState({
                showVideo: true,
                videoLink,
            });
        }), className: "cursor-pointer", children: _jsx("i", { style: { color: '#4dbd74' }, className: "fa fa-play" }) }));
};
export const showAlarmVerifiedStatus = (row, data, props, updateStateData) => {
    const value = get(row, 'alarmVerifiedStatus', 'NOT_MARKED');
    return (_jsx(FxSelect, { value: value, options: [
            { value: 'TRUE', label: 'True' },
            { value: 'FALSE', label: 'False' },
            { value: 'NOT_MARKED', label: 'Not Marked' },
        ], onChange: async (v) => {
            const rowId = get(row, 'createDate');
            const rowIndex = data.findIndex((item) => get(item, 'createDate') === rowId);
            if (rowIndex > -1) {
                const newDataSource = [...data];
                newDataSource[rowIndex] = {
                    ...newDataSource[rowIndex],
                    alarmVerifiedStatus: v,
                };
                updateStateData(newDataSource);
                const params = {
                    createDate: getMomentTime(rowId).valueOf(),
                    alarmStatus: v,
                    accountId: get(row, 'accountId'),
                    groupId: get(row, 'groupId'),
                    type: get(row, 'type'),
                    vehicleId: get(row, 'vehicleId'),
                };
                props.updateVehicleAlarmVerifiedStatus(params).promise.then((res) => {
                    props.fetchAlerts();
                });
            }
        } }));
};
export const showLocation = (accesstoken, row, state, setState, addressBook) => {
    const latitude = get(row, 'latitude', '');
    const longitude = get(row, 'longitude', '');
    const timeStamp = get(row, 'timeStamp', '');
    if (state.alarmLocation[`${latitude},${longitude}`]) {
        const { addressName, addressText, id } = state.alarmLocation[`${latitude},${longitude}`] || {};
        return (_jsxs(FxFlex, { vertical: true, children: [addressName ? (_jsx(Link, { className: "text-on-location", target: '_blank', to: `/dashboard/routes/addressbook/edit/${id}`, children: parseAddress(addressName) })) : (parseAddress(addressText)), getMapButton(timeStamp, row)] }));
    }
    else {
        return (_jsxs(FxFlex, { vertical: true, style: { width: 150 }, children: [_jsx(FxTypography.Link, { href: "#", onClick: async (event) => {
                        event.preventDefault();
                        let addressText = '';
                        let addressName = '';
                        let id = '';
                        if (latitude && longitude) {
                            const nearestAddress = getNearestAddressFromAddressbook(latitude, longitude, addressBook, true);
                            if (isInsideNearestAddress(nearestAddress)) {
                                addressName = nearestAddress?.addressName;
                                id = nearestAddress?.id;
                            }
                            else {
                                const { address } = await getGeoCodedLocation(accesstoken, latitude, longitude, GEOCODER.BACKEND);
                                addressText = address;
                            }
                        }
                        setState({
                            alarmLocation: {
                                ...state.alarmLocation,
                                [`${latitude},${longitude}`]: {
                                    addressText,
                                    addressName,
                                    id,
                                },
                            },
                        });
                    }, children: "Show Location" }), getMapButton(timeStamp, row)] }));
    }
};
